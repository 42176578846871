import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddModemComponent } from './add-modem/add-modem.component';
import { DocumentService } from '../../service/document.service';
import { WorksiteService } from '../../service/worksite.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';
import { SwalService } from '../../service/swal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modems',
  templateUrl: './modems.component.html',  
  styleUrls: ['../../../theme/tab.scss']
})

export class ModemsComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true })

  showTable = true;
  isAdmin = false;
  dataSource!: MatTableDataSource<any>;
  credentials: any;
  idWorksite = JSON.parse(localStorage.getItem('selected worksite') || '[]').cantieri_key
  paginator!: MatPaginator;
  columns: string[] = [
    '#',
    'ssid',
    'pw', 
    'delete'
  ];   
  passwordType = "password"
  pwd = true
  
  constructor(
    private sWorksite: WorksiteService,
    private sDocument: DocumentService,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService,
    private dialogRef: MatDialog
  ) { }

  ngOnDestroy(): void {console.log('Component destroyed'); }


  ngOnInit(): void {
    if (localStorage.getItem('ac_tipo_utente_cod') == '1') {
      this.isAdmin = true;
    }
    this.sWorksite.getWorksite(this.idWorksite).subscribe(res => {
      if(res != null) {
        if(res.data.modems.length.toString() === '0'){
          this.showTable = false
        }
        else {
          this.credentials = res.data.modems
          this.dataSource = new MatTableDataSource(this.credentials);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      }
    },
    (error) => {
      this.error.handleError(error)
    })   
  }

  togglePassword() {
    this.pwd = !this.pwd;
  }

  deleteCredentials(key: any) {   
    let data = {
      modem_cantiere_key: key,
      rel_installatore: ""
    }
    this.swal.creds_warning('Queste credenziali verranno eliminate!').then((result) => {
      if (result.value) {
        this.logger.info('Modem: ', data)
        this.sDocument.deleteCredentials(data).subscribe(res => {
          if (res != null) {
            this.logger.debug(res)
            this.ngOnInit()
            this.swal.creds_delete()
          }
        }, 
        (error) => {
          this.error.handleError(error)
            this.swal.creds_err();
        })
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) {
        this.swal.creds_safe()
        return
      }
    })
  }

  openDialog(id: any) {
    this.dialogRef.open(AddModemComponent, {
      data:{
        id: id
      }
    });
  }

}
