import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { DropdownService } from '../../service/dropdown.service';
import { WorksiteService } from '../../service/worksite.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';
import { SwalService } from '../../service/swal.service';

@Component({
  selector: 'app-add-worksite',
  templateUrl: './add-worksite.component.html',
  styleUrls: ['../../../theme/modal.scss']
})
export class AddWorksiteComponent implements OnInit, OnDestroy {

  form = this.fb.group({
    cn_indirizzo: ['', /*{ validators: [Validators.required] }*/],
    cn_cliente: ['', /*{ validators: [Validators.required] }*/],
    cn_note: ['', /*{ validators: [Validators.required] }*/]
  });

  listaProvince: any;
  selectedProv: any;
  listaCitta: any;
  selectedCity: any;
  listatipologia: any;
  selectedTipo: any;
  errorMessage: string = '';
  lat: number = 0;
  lng: number = 0;

  constructor(
    private fb: FormBuilder, 
    private sDropDown: DropdownService, 
    private sWorksite: WorksiteService, 
    private router: Router,
    public dialog: MatDialogRef<AddWorksiteComponent>,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService
  ) { }

  ngOnDestroy(): void {console.log('Component destroyed'); }

  ngOnInit(): void {
    this.sDropDown.getProvinces().subscribe(res => {
      if (res != null) {
        this.listaProvince = res.data;
      }
    },
    (error) => {
      console.log(error);
    });

    this.sDropDown.getTypeWorksite().subscribe(res => {
      if (res != null) {
        this.listatipologia = res.data;
        console.log('listatipologia', this.listatipologia);
      }
    },
    (error) => {
      console.log(error);
    });
  }

  updateProv(prov: any) {
    this.sDropDown.getCities(prov).subscribe(res => {
      if (res != null) {
        this.listaCitta = res.data;
      }
    },
    (error) => {
      console.log(error);
    });
  }

  updateCity(city: any) {
    this.selectedCity = city;
  }

  updateType(type: any) {
    this.selectedTipo = type;
  }

  saveWorksite() {    
        let credential: any = this.form.value
        credential["cn_latitude"] = 0;
        credential["cn_longitude"] = 0;
        credential["cantieri_key"] = 0;
        credential["cn_istat_cod"] = this.selectedCity;
        credential["cn_tipo_cantiere_cod"] = this.selectedTipo;
        credential["cn_attivo"] = "S";
        console.log('body inviato', credential);        
        this.sWorksite.postWorksite(credential).subscribe(res => {
          if (res != null) {
            let idWorksite = res.data;
            this.sWorksite.getWorksite(idWorksite).subscribe(res => {
              if (res != null) {
                localStorage.setItem('selected worksite', JSON.stringify(res.data));
                this.router.navigate(['/default/modifyWorksite']);
              }
            });
          }
        },
        (error) => {
          console.log(error);
        });      
  }

  close() {
    this.dialog.close();
  }

}
