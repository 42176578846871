import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UsersComponent } from '../users/users.component';
import { ModifyUserComponent } from '../modify-user/modify-user.component';
import { PasswordComponent } from '../password/password.component';
import { AccountsService } from '../../service/accounts.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';
import { SwalService } from '../../service/swal.service';
import { SweetAlertResult } from 'sweetalert2';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-accounts',
  templateUrl: './manage-accounts.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class ManageAccountsComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  value: string | null = '';
  dataL = true;
  filter = localStorage.getItem('filtro');
  dataSource!: MatTableDataSource<any>;
  users!: any;
  totalUsers = this.users?.length
  columns: string[] = [
    'name', 
    'lastname', 
    'mail', 
    'phone', 
    'type', 
    'company', 
    'note', 
    'actions'
  ];  

  constructor(
    private sUser: AccountsService,
    private dialogRef: MatDialog,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService
  ) { }

  ngOnDestroy(): void {
    this.logger.info('Component destroyed!'); 
  }

  ngOnInit(): void {
    this.sUser.get_accounts(this.value!).subscribe(res => {
      if (res != null) {
        this.users = res.data;
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    },
    (error) => {
        this.error.handleError(error)
    })
  }

  filtra(value: string | null) {
    this.value = value
    this.ngOnInit()
  }
  
  saveDataAccount(data: any) {
    localStorage.setItem('selected account', JSON.stringify(data))
  }

  deleteData(account_key: any) {
    let body = {
      "account_key": account_key
    }
    this.swal.user_warning('Questo utente verrà eliminato!').then((result) => {
      if (result.value) {
        this.sUser.delete_account(body).subscribe(res => {
          if (res != null) {
            this.ngOnInit();
            this.swal.user_delete()
          }
        }, 
        (error) => {
            this.error.handleError(error)
            this.swal.user_err2();
        })
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) {
        this.swal.user_safe()
        return
      }
    })
  }  

  deleteAccount(id: any): Observable<any> {
    let data = { account_key: id };
    return new Observable((observer) => {
        this.swal.user_warning('Questo utente verrà eliminato!').then((result: SweetAlertResult<any>) => {
            if (result?.value) {
                this.sUser.delete_account(data).subscribe(
                    (res) => {
                        if (res != null) {
                            this.logger.debug(res);
                            this.ngOnInit();
                            this.swal.user_delete();
                        } 
                        else {
                            this.swal.user_err2();
                        }
                        observer.next(res); // Emit the result
                        observer.complete(); // Complete the observable
                    },
                    (error) => {
                        this.error.handleError(error);
                        observer.error(error); // Emit the error
                    }
                );
            } 
            else if (result?.dismiss === Swal.DismissReason.cancel) {
                this.swal.user_safe();
                observer.complete(); // Complete the observable if user cancels
            } 
            else {
                observer.complete(); // Complete the observable for other cases
            }
        });
    });
  }


  openDialog(id: any) {
    this.dialogRef.open(UsersComponent, {
      data:{
        account_key: id
      }
    });
  }

  openDialog2(id: any) {
    this.dialogRef.open(ModifyUserComponent, {
      data:{
        account_key: id
      }
    });
  }

  openDialog3(id: any) {
    this.dialogRef.open(PasswordComponent, {
      data:{
        account_key: id
      }
    });
  }

}
