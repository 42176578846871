import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})

export class NotFoundComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit(): void { }

  ngOnDestroy(): void {console.log('Component destroyed'); }

}
