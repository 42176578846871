<mat-card>
    <mat-card-title>
        <h2 style="text-align:center">Credenziali Modem Wifi</h2>
        <mat-icon
            (click)="openDialog(modem)"
            routerLinkActive="list-item-active" 
            class="btn-add"
        >
        add_circle</mat-icon>
    </mat-card-title>
    <ng-template id="modem" #modem></ng-template>
    <mat-card-content [hidden]="showTable==false">
        <section class="example-container mat-elevation-z8" tabindex="0">
            <div class="divTable">
            <table mat-table [dataSource]="dataSource" matSort>                
                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef>#</th>
                    <td mat-cell *matCellDef="let index = index">{{ index + 1 }}</td>
                </ng-container>
                <ng-container matColumnDef="ssid">
                    <th mat-header-cell *matHeaderCellDef>SSID</th>
                    <td mat-cell *matCellDef="let row">{{ row.ssid }}</td>
                </ng-container>
                <ng-container matColumnDef="pw">
                    <th mat-header-cell *matHeaderCellDef>PASSWORD
                    </th>
                    <td mat-cell *matCellDef="let row" 
                        class="pw" 
                        type="pwd ? 'password' : 'text'" 
                        value="row.password" 
                        readonly
                    >
                        <input 
                            [type]="pwd ? 'password' : 'text'" 
                            [value]="row.password" 
                            readonly
                            style="
                                width: 12%;
                                border: none;
                                background: transparent
                            "
                        >   
                        <span (click)="togglePassword()">
                            <mat-icon
                                style="
                                    top: 5px;
                                    position: relative
                                "
                            >{{ pwd ? 'visibility' : 'visibility_off' }}</mat-icon>
                        </span>                     
                    </td>
                </ng-container>
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef style="width:5px">ELIMINA</th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon *ngIf="isAdmin" (click)="deleteCredentials(row.key)" style="color: red">delete</mat-icon>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;"></tr>
            </table>
            <mat-paginator [length]="credentials?.length" [pageSize]="5" [pageSizeOptions]="[1, 5, 10, 25, 100]"
                aria-label="Select page">
            </mat-paginator>
        </div>
        </section>
    </mat-card-content>
</mat-card>

