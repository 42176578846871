<div class="containerInserimentoLicord">
    <form [formGroup]="form" id="contact" style="margin-bottom: 8px; width: 100%">
        <h3 style="text-align:center">Modifica Account</h3>
        <h4 style="text-align:center">Modifica le informazioni di questo utente</h4>    
        <div class="containerFormLicord">
            <div>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Nome</mat-label>
                        <input formControlName="ac_nome" matInput>
                    </mat-form-field>  
                </p>  
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Email</mat-label>
                        <input formControlName="ac_email" matInput>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Telefono</mat-label>
                        <input formControlName="ac_telefono" matInput>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Note</mat-label>
                        <input formControlName="ac_note" matInput>
                    </mat-form-field>
                </p>
                <!--<p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Password</mat-label>
                        <input type="password" formControlName="ac_password" matInput>
                    </mat-form-field>
                </p>-->
            </div>
            <div>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Cognome</mat-label>
                        <input formControlName="ac_cognome" matInput>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Società</mat-label>
                        <input formControlName="ac_societa" matInput>
                    </mat-form-field>
                </p>       
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Cellulare</mat-label>
                        <input formControlName="ac_cellulare" matInput>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Tipo Utente</mat-label>
                        <mat-select (selectionChange)="updateType($event.value)" autofocus>
                            <mat-option *ngFor="let i of listatipologia" [value]="i.value">{{ i.tipo }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>   
                <!--<p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Conferma password</mat-label>
                        <input formControlName="ac_rep_password" matInput>
                    </mat-form-field>
                </p>--> 
            </div>
        </div>
        <fieldset
            style="
                margin-top: 20px;
                margin-bottom: -40px
            "
        >
            <!--<button 
                [disabled]="!form.valid" 
                data-submit="...Sending" 
                (click)="openDialog(user)"
                id="contact-submit"
                type="submit" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >lock_open</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >CAMBIA PASSWORD</span>
            </button>-->
            <button 
                [disabled]="!form.valid" 
                data-submit="...Sending" 
                (click)="Modifica()"
                id="contact-submit"
                type="submit" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >save</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >SALVA</span>
            </button>
            <button 
                data-submit="...Sending" 
                (click)="close()"
                class="btn-annulla"
                id="contact-submitt"
                type="submit" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >clear</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >ANNULLA</span>
            </button>
        </fieldset>
    </form>
</div>