import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddWorksiteComponent } from '../add-worksite/add-worksite.component';
import { Router } from '@angular/router';
import { Worksite } from '../../models/worksite.model';
import { WorksiteService } from '../../service/worksite.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';

@Component({
  selector: 'app-manage-worksite',
  templateUrl: './manage-worksite.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class ManageWorksiteComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort2!: MatSort;
  @ViewChild(MatPaginator) paginator2!: MatPaginator;

  value: string | null = null;
  filter: string | null = localStorage.getItem('filtro');
  dataL = true;
  worksites: any;
  dataSource2: MatTableDataSource<Worksite> = new MatTableDataSource<Worksite>();
  columns2: string[] = [
    'cantieri_key', 
    'tab_tipo_cantiere_desc', 
    'cn_indirizzo', 
    'tab_comune_desc', 
    'tab_provincia_cod', 
    'tab_cap_cod', 
    'cn_numero_licord', 
    'cn_cliente', 
    'actions'
  ];  

  constructor(
    private router: Router, 
    private worksiteService: WorksiteService,
    private dialogRef: MatDialog,
    private logger: LoggerService,
    private errorService: ErrorService
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit(): void {
    this.dataSource2.sort = this.sort2;
    this.dataSource2.paginator = this.paginator2;
    this.filterWorksites();
  }
  
  filtra(value: string | null) {
    this.value = value;
    this.filterWorksites();
  }
  
  filterWorksites() {
    this.worksiteService.getWorksites(this.value!).subscribe(
      res => {
        if (res && res.data && res.data.length > 0) {
          this.dataSource2.data = res.data;
          this.dataL = true; // set dataL=true if there're data
        } 
        else {
          this.dataSource2.data = [];
          this.dataL = false; // set dataL=false if there're no data
        }
      },
      error => {
        this.errorService.handleError(error);
        this.dataL = false; // if there's an error, then set dataL=false
      }
    );
  }
  
  
  saveDataWorksite(row: any) {
    this.worksiteService.getWorksite(row.cantieri_key).subscribe(
      res => {
        localStorage.setItem('selected worksite', JSON.stringify(res.data));
        this.router.navigate(['/default/modifyWorksite']);
      },
      error => {
        this.errorService.handleError(error);
      }
    );
  }

  openDialog(id: any) {
    this.dialogRef.open(AddWorksiteComponent, {
      data:{
        dispositivi_key: id
      }
    });
  }  

}