import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { LicordService } from '../../..//service/licord.service';
import { WorksiteService } from '../../../service/worksite.service';

@Component({
  selector: 'app-dev-up',
  templateUrl: './dev-up.component.html',
  styleUrls: ['../../../../theme/modal.scss']
})

export class DevUpComponent implements OnInit, OnDestroy {

  modemList: any;
  modemssid = "Scegli un modem"
  disableSelect = new FormControl(false);
  dispositivi_key!: string;

  public serialNumber: any;
  public progressiveNumber: any;
  public ds_data_installazione: any;
  public serialCella: any;
  public fondoScala: any;
  public sensibilityCella: any;
  public fattore_calibrazione: any;
  public wake_up_1 = "";
  public wake_up_2 = "";
  public wake_up_3 = "";
  public delta_t = 0;
  public ds_note: string ="";
  public ds_modem_key: any;
  public ds_cantiere_key: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private sLicord: LicordService, 
    private sWorksite: WorksiteService,
    public dialog: MatDialogRef<DevUpComponent>
  ) {
    this.dispositivi_key = data.dispositivi_key
    //messo volutamente dentro il costruttore 
    this.sLicord.getDevice(this.dispositivi_key.toString()).subscribe(res => {
      this.serialNumber = res.data!.ds_seriale;
      this.progressiveNumber = res.data!.ds_progressivo;
      this.ds_data_installazione = res.data!.ds_data_installazione
      this.serialCella = res.data!.ds_seriale_cella
      this.fondoScala = res.data!.ds_fondo_scala_cella
      this.sensibilityCella = res.data!.ds_sensibilita_cella
      this.fattore_calibrazione = res.data!.ds_fattore_calibrazione
      this.wake_up_1 = res.data!.ds_wakeup_T1
      this.wake_up_2 = res.data!.ds_wakeup_T2
      this.wake_up_3 = res.data!.ds_wakeup_T3
      this.delta_t = res.data!.ds_wakeup_delta_t
      this.ds_note = res.data!.ds_note
      this.ds_cantiere_key = res.data!.ds_cantiere_key
      this.ds_modem_key = res.data?.ds_modem_key
      this.sWorksite.getWorksite(this.ds_cantiere_key).subscribe(res => {
        this.modemList = res.data.modems
        this.modemList.forEach((element: any) => {
          if (element.key == this.ds_modem_key) {
            this.modemssid = element.ssid
          }
        });
      })
    })
  }

  ngOnDestroy(): void {console.log('Component destroyed'); }


  ngOnInit(): void { }

  close() {
    this.dialog.close();
  }

}

