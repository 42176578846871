<mat-card>
    <mat-card-title>
        <h2 style="text-align:center">Licord</h2>
        <mat-icon 
            (click)="openDialog2(new)"
            routerLinkActive="list-item-active" 
            class="btn-add"
        >
        add_circle</mat-icon>
    </mat-card-title>

    <ng-template id="new-licord" #new></ng-template>


    <mat-card-content [hidden]="showTable==false">
        <section class="example-container mat-elevation-z8" tabindex="0">
            <div class="divTable">  
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="serialNumber">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="!row.isEditable; else editModeId">
                            {{ row.ds_seriale }}
                        </ng-container>
                        <ng-template #editModeId>
                            <input *ngIf="row.isEditable" type="text" value="row.ds_seriale" [(ngModel)]="editedRow.ds_seriale">
                        </ng-template>
                    </td>
                </ng-container>                
                <ng-container matColumnDef="token">
                    <th mat-header-cell *matHeaderCellDef >TOKEN</th>
                    <td mat-cell *matCellDef="let row">
                        <button class="btn-token" style="text-align:left; padding-left:25px; padding-right:0px" mat-button [cdkCopyToClipboard]="row.ds_token">
                            <mat-icon>copy</mat-icon>
                            Copia Token
                        </button>
                    </td>
                </ng-container>  
                <ng-container matColumnDef="progressiveNumber">
                    <th mat-header-cell *matHeaderCellDef >PROGRESSIVO</th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="!row.isEditable; else editModeProgressivo">
                            {{ row.ds_progressivo }}
                        </ng-container>
                        <ng-template #editModeProgressivo>
                            <input *ngIf="row.isEditable" type="text" value="row.ds_progressivo" [(ngModel)]="editedRow.ds_progressivo">
                        </ng-template>
                    </td>
                </ng-container> 
                <ng-container matColumnDef="dataInstallazione">
                    <th mat-header-cell *matHeaderCellDef >DATA INSTALLAZIONE</th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="!row.isEditable; else editModeData">
                            {{ row.ds_data_installazione | date:'dd/MM/yyyy' }}
                        </ng-container>
                        <ng-template #editModeData>
                            <input *ngIf="row.isEditable" type="text" value="row.ds_data_installazione" [(ngModel)]="editedRow.ds_data_installazione">
                        </ng-template>
                    </td>
                </ng-container>
                <ng-container matColumnDef="dataRevisione">
                    <th mat-header-cell *matHeaderCellDef>DATA REVISIONE</th>
                    <td mat-cell *matCellDef="let row; let i = index" [ngClass]="{'scaduto': isScaduto(row.ds_data_installazione)}">
                        <ng-container *ngIf="!row.isEditable; else editModeRevisione">
                            <span>{{ calcolaDataRevisione(row.ds_data_installazione) | date:'dd/MM/yyyy' }}</span>
                            <div 
                                class="progress-bar-container"
                                matTooltip="{{calcolaGiorniMancanti(row.ds_data_installazione)}} giorni rimanenti"
                            >
                                <div 
                                    class="progress-bar" 
                                    [style.width.%]="calcolaProgresso(row.ds_data_installazione)" 
                                    [ngClass]="{'progress-bar-green': isMonthComplete(row.ds_data_installazione) && !isScaduto(row.ds_data_installazione), 'progress-bar-red': isScaduto(row.ds_data_installazione)}"
                                ></div>
                            </div>
                        </ng-container>
                        <ng-template #editModeRevisione>
                            <input *ngIf="row.isEditable" type="text" value="...">
                        </ng-template>
                    </td>
                </ng-container>
                <ng-container matColumnDef="dati">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>GRAFICI</th>
                    <td mat-cell *matCellDef="let row">
                        <img src="/assets/graph.png" alt="grafico" width="20" (click)="openDialog3(row.dispositivi_key)" style="margin-left:5px; cursor:pointer; color: rgb(11, 11, 166)">
                    </td>
                </ng-container>
                <ng-container matColumnDef="configurazione">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>CONFIGURA DA REMOTO</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button (click)="openDialog4(row.dispositivi_key)">
                            <mat-icon>build</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>MODIFICA DATI</th>
                    <th mat-header-cell *ngIf="isEditable">SALVA</th>
                    <td mat-cell *matCellDef="let row" >
                        <button *ngIf="!isEditable" mat-icon-button (click)="openDialog(row.dispositivi_key)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngIf="isEditable" mat-icon-button (click)="save(row)">
                            <mat-icon>save</mat-icon>
                        </button>
                    </td>
                </ng-container>         
                <ng-container matColumnDef="delete" >
                    <th mat-header-cell *matHeaderCellDef style="width: 5px">ELIMINA</th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon *ngIf="isAdmin" (click)="deleteLicord(row.dispositivi_key)" style="color: red">delete</mat-icon>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" (click)="saveDataLicord(row)"></tr>
            </table>
            <mat-paginator [length]="licord?.length" [pageSize]="5" [pageSizeOptions]="[1, 5, 10, 25, 100]"
                aria-label="Select page">
            </mat-paginator>
        </div>
        </section>
    </mat-card-content>

</mat-card>

