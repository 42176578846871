import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DevUpComponent } from './dev-up/dev-up.component';
import { PopsComponent } from '../licord/pops/pops.component';
import { LicordService } from '../../service/licord.service';
import { WorksiteService } from '../../service/worksite.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';

@Component({
  selector: 'app-device-tot',
  templateUrl: './device-tot.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class DeviceTotComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  
  idWorksite = JSON.parse(localStorage.getItem('selected worksite')!).cantieri_key;
  x = JSON.parse(localStorage.getItem('selected worksite') || '[]');  
  y = JSON.parse(localStorage.getItem('selected device') || '[]')


  value: string | null = null
  dataL = true;
  showTable = true;
  filter = localStorage.getItem('filtro');
  dataSource!: MatTableDataSource<any>;
  devices: any;
  licord: any;
  tab_tipo_cantiere_desc: any
  tab_comune_desc: any
  cn_indirizzo: any
  tab_provincia_cod: any
  tab_cap_cod: any
  cn_cliente: any
  columns: string[] = [
    'serialNumber', 
    'progressiveNumber', 
    'tab_comune_desc', 
    'cn_indirizzo', 
    'cliente', 
    'dataInstallazione',
    'dataRevisione',
    'serialCella', 
    'dati',
    'edit'
  ];

  idz: any;
  progrz: any;
  installz: any

  constructor(
    private sLicord: LicordService, 
    private sWorksite: WorksiteService, 
    private logger: LoggerService,
    private error: ErrorService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnDestroy(): void {console.log('Component destroyed'); }


  ngOnInit(): void {
    if (this.filter == null) {
      localStorage.setItem('filtro', '0')
      this.filter = '0'
    }
    this.sWorksite.getWorksite(this.idWorksite).subscribe(res => {
      this.tab_tipo_cantiere_desc = res.data?.tab_tipo_cantiere_desc
      this.tab_comune_desc = res.data?.tab_comune_desc
      this.cn_indirizzo = res.data?.cn_indirizzo
      this.tab_provincia_cod = res.data?.tab_provincia_cod
      this.tab_cap_cod = res.data?.tab_cap_cod
      this.cn_cliente = res.data?.cn_cliente
      this.logger.info('Server response: ', res)
    })
    /*metodo per chiedere dati cantiere (inserito perchè alla cancellazione di
    immangini o documenti il localstorage non viene aggiornato)
    !!!! ORIGINALE MA NON AGGIORNA IL LOCALSTORAGE!!!!!
    this.sWorksite.getWorksite(this.x.cantieri_key).subscribe(
      (res) => {
        if(res != null) {
          this.x = res.data
        }
      }
    );
    this.sLicord.getTotDevices(this.value!).subscribe(res => {
      if (res.error_message!.msg_code === 8) {
        this.dataL = false
      } 
      else {
        this.dataL = true
        this.devices = res.data
        this.logger.info('Devices: ', this.devices)
        this.dataSource = new MatTableDataSource(this.devices);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    });
    this.sLicord.getDevices(this.x.cantieri_key).subscribe(res => {      
      if (res.error_message!.msg_code === 8) {
        this.showTable = false
      } 
      else {
        this.licord = res.data
        this.logger.info('Licord: ', this.licord)
        this.dataSource = new MatTableDataSource(this.licord);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    },
    (error) => {
      this.error.handleError(error)
    });
    this.sWorksite.getWorksite(this.idWorksite.ds_cantiere_key).subscribe(res => {
      if (res != null) {
        localStorage.setItem("selected worksite", JSON.stringify(res.data));
      }
    })*/  




      /************ULTIMA MODIFICA, FUNZIONA*********************/
    this.sWorksite.getWorksite(this.idWorksite.ds_cantiere_key).subscribe(res => {
      if (res != null) {
        localStorage.setItem("selected worksite", JSON.stringify(res.data));
      }
    });
    this.sLicord.getDevices(this.x.cantieri_key).subscribe(res => {
      if (res != null) {
        if (res.error_message!.msg_code === 8) {
          this.dataL = false;
        } 
        else {
          this.devices = res.data;
          this.logger.info('Device: ', res);
          this.dataSource = new MatTableDataSource(this.devices);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          /**********************************/
          // Controlla che i dati nel localStorage siano aggiornati correttamente
          const selectedDevice = JSON.parse(localStorage.getItem('selected device')!);
          if (selectedDevice) {
            this.idz = selectedDevice.ds_seriale;
            this.progrz = selectedDevice.ds_progressivo;
            this.installz = selectedDevice.ds_data_installazione;
          } 
          else {
            console.error('Errore: dispositivo non trovato nel localStorage');
          }
          /**********************************/
        }
      }
      console.log('Devices data: ', res);
    });
    /***********************************************************/

  }

  filtra(value: string | null) {
    if (value != "" || value != null) {
      localStorage.setItem('filtro', '1')
      this.filter = '1'
    } 
    else {
      localStorage.setItem('filtro', '0')
    }
    this.value = value
  }

  /*saveDataWorksite(data: any) {
    this.sWorksite.getWorksite(data.ds_cantiere_key).subscribe(res => {
      if (res != null) {
        localStorage.setItem("selected worksite", JSON.stringify(res.data));
        this.router.navigate(['/default/licordList'])
      }
    })
  }*/

  saveDataLicord(data: any) {
    console.log('Saving data for device:', data);
    localStorage.setItem('selected device', JSON.stringify(data))
  }

  valutaBatteria(data: any) {
    for (let i = 0; i <= data.ds_list_warnings.length - 1; i++) {
      if (data.ds_list_warnings[i].warning_ico_link === '/assets/lowbattery.png') {
        return true
      }
    }
    return false
  }

  openDialog(id: any) {
    this.dialog.open(DevUpComponent, {
      data: {
        dispositivi_key: id
      }
    })
  } 

  openDialog2(id: any) {
    this.dialog.open(PopsComponent, {
      data: {
        dispositivi_key: id
      }
    })
  } 

  goCharts(data: any) {
    localStorage.setItem('dispositivi_key', data.dispositivi_key)
    this.router.navigate(['/default/dashboard'])
  }

  calcolaDataRevisione(ds_data_installazione: Date): Date {
    const dataInstallazione = new Date(ds_data_installazione);
    dataInstallazione.setFullYear(dataInstallazione.getFullYear() + 2);
    return dataInstallazione;
  }

  isScaduto(ds_data_installazione: Date): boolean {
    const dataRevisione = this.calcolaDataRevisione(ds_data_installazione);
    const oggi = new Date();
    return oggi > dataRevisione;
  }  

  calcolaProgresso(ds_data_installazione: Date): number {
    const dataAttuale = new Date();
    const dataInstallazione = new Date(ds_data_installazione);
    const diffInMesi = (dataAttuale.getFullYear() - dataInstallazione.getFullYear()) * 12 + (dataAttuale.getMonth() - dataInstallazione.getMonth());
    const progresso = (diffInMesi / 24) * 100;
    return Math.max(0, Math.min(100, progresso));
  }

  isMonthComplete(ds_data_installazione: Date): boolean {
    const dataInstallazione = new Date(ds_data_installazione);
    const dataAttuale = new Date();
    const mesiTrascorsi = (dataAttuale.getFullYear() - dataInstallazione.getFullYear()) * 12 + dataAttuale.getMonth() - dataInstallazione.getMonth();
    const scadenza = new Date(dataInstallazione.getFullYear() + 2, dataInstallazione.getMonth(), dataInstallazione.getDate());
    const dataLimite = new Date(scadenza.getTime() + (24 * 60 * 60 * 1000));
    return mesiTrascorsi <= 24 || dataAttuale.getTime() <= dataLimite.getTime();
  }

  calcolaGiorniMancanti(ds_data_installazione: Date): number {
    const dataRevisione = this.calcolaDataRevisione(ds_data_installazione);
    const oggi = new Date();
    const giorniMancanti = Math.ceil((dataRevisione.getTime() - oggi.getTime()) / (1000 * 3600 * 24));
    return giorniMancanti > 0 ? giorniMancanti : 0;
  }

}
