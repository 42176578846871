import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Stats } from '../models/stats.model';
import { ErrorService } from './error.service';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class HomepageService {

  qapiGet = "https://licord2024.connecta.ovh/api/services/get_stats"
  apiGet = `${environment.apiUrl}api/services/get_stats`

  constructor(
    private http: HttpClient,
    private error: ErrorService,
    private logger: LoggerService
  ) { }

  getStats() {
    return this.http.get<Stats>(this.apiGet)
    .pipe(
      tap(response => {
        this.logger.debug('Successfully loaded statistics!')
      }),
      catchError(this.error.handleError)
    )
  }

}