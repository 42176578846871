<div class="containerInserimentoLicord">
    <form id="contact" style="margin-bottom:8px; width:100%">
        <h3 style="text-align:center">Licord</h3>   
        <h4 style="text-align:center">Modifica dispositivo</h4>
        <div class="containerFormLicord">
            <div>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>ID dispositivo</mat-label>
                        <input [(ngModel)]="serialNumber" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p>          
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Seriale cella</mat-label>
                        <input [(ngModel)]="serialCella" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p>          
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Sensibilità cella</mat-label>
                        <input type="number" [(ngModel)]="sensibilityCella" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Data installazione</mat-label>
                        <input matInput [(ngModel)]="ds_data_installazione" [matDatepicker]="picker"
                            [ngModelOptions]="{standalone: true}">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </p>          
            </div>
            <div>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Numero progressivo</mat-label>
                        <input [(ngModel)]="progressiveNumber" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Fondo scala</mat-label>
                        <input type="number" [(ngModel)]="fondoScala" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Fattore calibrazione</mat-label>
                        <input type="number" [(ngModel)]="fattore_calibrazione" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p>       
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>{{modemssid}}</mat-label>
                        <mat-select [(ngModel)]="ds_modem_key" [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let item of modemList" value="{{item.key}}">{{item.ssid}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>                
            </div>
        </div>
        <div class="containerFormLicord">
            <div>
                <p>
                    <mat-form-field appearance="outline" class="byclasss">
                        <mat-label>Note</mat-label>
                        <input type="text" [(ngModel)]="ds_note" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p> 
            </div>
        </div>
        <fieldset
            style="
                margin-top: 20px;
                margin-bottom: -40px
            "
        >
            <button 
                (click)="aggiornaLicord()" 
                data-submit="...Sending" 
                class="btn-add"
                id="contact-submit"
                type="submit" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >save</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >SALVA</span>
            </button>
            <button 
                data-submit="...Sending" 
                (click)="close()"
                class="btn-annulla"
                id="contact-submitt"
                type="submit" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >clear</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >ANNULLA</span>
            </button>
        </fieldset>
    </form>
</div>
