import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'thousandSeparator'
})

export class ThousandSeparatorPipe implements PipeTransform {

    transform(value: any | null): string {
        if (value == null || isNaN(value)) 
            return '';
        const separator = "'";
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    }

}
