<div class="not-found parallax">
    <div class="sky-bg"></div>
    <div class="wave-7"></div>
    <div class="wave-6"></div>
    <a class="wave-island" href="#">
            <img src="../../assets/island.svg" alt="Island">
        </a>
    <div class="wave-5"></div>
    <div class="wave-lost wrp">
        <span>4</span>
        <span>0</span>
        <span>4</span>
    </div>
    <div class="wave-4"></div>
    <div class="wave-boat">
        <img class="boat" src="../../assets/boat.svg" alt="Boat">
    </div>
    <div class="wave-3"></div>
    <div class="wave-2"></div>
    <div class="wave-1"></div>
    <div class="wave-message">
        <p>Your're lost</p>
        <p>Click on the island to return</p>
    </div>
</div>