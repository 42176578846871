import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { LoggerService } from '../service/logger.service';
import { ErrorService } from '../service/error.service';
import { SwalService } from '../service/swal.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})

export class ResetComponent implements OnInit, OnDestroy {

  isTokenValid = true
  token: string = ''
  changetypeN: boolean = true;
  changetypeC: boolean = true;
  form: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private route: ActivatedRoute,
    private sReset: AuthService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService
  ) {
    this.form = fb.group({
      passwordNew: new UntypedFormControl('', [Validators.required]),
      passwordConfirm: new UntypedFormControl('', [Validators.required])
    }, 
    {
      validator: this.ConfirmedValidator('passwordNew', 'passwordConfirm')
    });

  }

  ngOnInit(): void {    
    this.route.queryParams.subscribe(params => {
      if (params && params['t']) {
        this.token = params['t'];
        this.logger.info('Token ngOnInit(): ', this.token);
        /*this.sReset.verifyToken(this.token).subscribe(res => {
          if (!res.success) {
            this.isTokenValid = false;
            this.logger.action('Token not verified!');
          }
          this.logger.debug('Token verified: ', this.token)
        });*/
      } 
      else {
        this.logger.error('Parametro "t" non presente nella query URL');
      }
    });
  }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }  

  submitForm() {
    if (this.form.valid) {
      this.logger.info('Form credentials: ', this.form.getRawValue());
      this.swal.form();
      //window.location.href = 'https://app.licord.it';
    } 
    else {
      this.error.handleError
      this.swal.form_err()
    }
  }

  Reset() {
    this.logger.info('Token Reset(): ', this.token);
    let data = {
      old_password: "",
      new_password: this.form.value.passwordNew,
      rep_password: this.form.value.passwordConfirm,
      /*new_password: this.form.get('passwordNew')?.value,
      rep_password: this.form.get('passwordConfirm')?.value*/
    }
    this.logger.info('Data: ', data);
    this.logger.info('Token used: ', this.token);
    if (this.form.valid) {
      this.sReset.resetPw(this.token, data).subscribe(
        res => {
          if (res.success) {
            this.swal.pwd_chg();
            setTimeout(() => {
              window.location.href = 'https://app.licord.it';
            }, 2000);
          } 
          else {
            this.swal.pwd_warning();
          }
        },
        error => {
          this.error.handleError(error);
          this.swal.login_err();
        }
      )
    }    
  }

  f() {
    return this.form.controls;
  }

  viewpass(x: string) {
    if (x == "N") {
      this.changetypeN = !this.changetypeN;
    } 
    else {
      this.changetypeC = !this.changetypeC;
    }
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } 
      else {
        matchingControl.setErrors(null);
      }
    }
  }

}
