import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { User } from '../models/user.model';
import { PasswordChange, PasswordPolicy, ChangePasswordInApp, IForgottenPassword } from '../models/password.model';
import { ErrorService } from './error.service';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class PasswordService {

  qapiUrl = "https://licord2024.connecta.ovh/api/services/"
  apiUrl = `${environment.apiUrl}api/services`
  
  constructor(
    private http: HttpClient,
    private error: ErrorService,
    private logger: LoggerService
  ) { }
  
   // Cambia la password dimenticata
  changeForgotPassword(object: PasswordChange): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/reset_password`, object)
      .pipe(
        catchError(this.error.handleError)
      );
  }

  // Cambia la password all'interno dell'app
  changePassword(object: PasswordChange): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/reset_password`, object)
      .pipe(
        catchError(this.error.handleError)
      );
  }

  // Controlla il reset della password con il token
  checkPasswordReset(token: string): Observable<any> {
    const params = new HttpParams().set('t', token);
    return this.http.get<any>(`${this.apiUrl}/reset_password_`, { params })
      .pipe(
        catchError(this.error.handleError)
      );
  }

  // Esegui il reset della password
  resetPassword(token: string, passwordChange: PasswordChange): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(`${this.apiUrl}/reset_password`, passwordChange, { headers })
      .pipe(
        catchError(this.error.handleError)
      );
  }

  // Richiedi il reset della password
  requestPasswordReset(username: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/new_password_request`, { username })
      .pipe(
        catchError(this.error.handleError)
      );
  }

  // Metodo per verificare la validità della password in base alla politica di sicurezza
  checkPassword(password: string, policy: PasswordPolicy): boolean {
    const patterns: string[] = [];
    switch (policy) {
      case PasswordPolicy.Min8Alfa1Number1:
        patterns.push('.{8,}');
        patterns.push('[a-z]+');
        patterns.push('[0-9]+');
        break;
      case PasswordPolicy.Min8AlfaUpper1Number1:
        patterns.push('.{8,}');
        patterns.push('[a-z]+');
        patterns.push('[0-9]+');
        patterns.push('[A-Z]+');
        break;
      case PasswordPolicy.Min8Alfa1Number1Special1:
        patterns.push('.{8,}');
        patterns.push('[a-z]+');
        patterns.push('[0-9]+');
        patterns.push('[!@#$%^&*()_+=\\[\\]{};:<>|./?,-]+');
        break;
      case PasswordPolicy.Min8AlfaUpper1Number1Special1:
        patterns.push('.{8,}');
        patterns.push('[a-z]+');
        patterns.push('[A-Z]+');
        patterns.push('[0-9]+');
        patterns.push('[!@#$%^&*()_+=\\[\\]{};:<>|./?,-]+');
        break;
      default:
        return false;
    }
    return patterns.every(pattern => new RegExp(pattern).test(password));
  }

}
