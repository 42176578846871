import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddDocuComponent } from './add-docu/add-docu.component';
import { DocumentService } from '../../service/document.service';
import { WorksiteService } from '../../service/worksite.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';
import { SwalService } from '../../service/swal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class DocumentationComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator; 

  showTable = true;
  isAdmin = false;
  dataSource!: MatTableDataSource<any>;
  doc: any;
  idWorksite = JSON.parse(localStorage.getItem('selected worksite') || '[]').cantieri_key;  
  columns: string[] = [
    '#',
    'title', 
    'link', 
    'delete'
  ];

  constructor(
    private sWorksite: WorksiteService, 
    private sDocument: DocumentService,
    private dialog: MatDialog,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService
  ) { }

  ngOnDestroy(): void {console.log('Component destroyed'); }


  ngOnInit(): void {
    if (localStorage.getItem('ac_tipo_utente_cod') == '1') {
      this.isAdmin = true;
    }
    this.sWorksite.getWorksite(this.idWorksite).subscribe(res => {
      if (res != null) {
        if (res.data.documents.length.toString() === "0") {
          this.showTable = false
        } 
        else {          
          this.doc = res.data.documents;
          this.logger.info('Documents: ', this.doc);
          this.dataSource = new MatTableDataSource(this.doc);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator
        }
      }
    },
    (error) => {
      this.error.handleError(error)
    })
  }

  deleteDoc(key: any) {
    let data = {
      cantieri_key: this.idWorksite,
      documenti_cantiere_key: key,
    }
    this.swal.doc_warning('Questo documento verrà eliminato!').then((result) => {
      if (result.value) {
        this.logger.info('Data: ', data)
        this.sDocument.deleteDocument(data).subscribe(res => {
          if (res != null) {
            this.logger.debug(res);
            this.ngOnInit();
            this.swal.doc_delete()
          }
        }, 
        (error) => {
          this.error.handleError(error)
            this.swal.doc_err()
        })
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) {
        this.swal.doc_safe()
        return
      }
    })
  }

  openDialog(id: any) {
    this.dialog.open(AddDocuComponent, {
      data: {
        id: id
      }
    })
  } 

}
