import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Worksites } from '../../models/worksites.model';
import { WorksiteService } from '../../service/worksite.service';
import { HomepageService } from '../../service/homepage.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';

declare var google: any;

@Component({
  selector: 'app-worksite',
  templateUrl: './worksite.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class WorksiteComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;  
  @ViewChild('map') mapElement: any;

  defaultFoto = "/assets/condominio.jpg";
  activeWorksite!: number;
  activeLicord!: number;
  warningtot!: number;
  value: string | null = null
  dataL = true
  filter = localStorage.getItem('filtro');
  map: any;
  lat = 43.66703722671484;
  lng = 10.999165784352293;
  asList = false;
  asTable = true;
  dataSource!: MatTableDataSource<Worksites>;
  worksites: any
  columns: string[] = [
    'type', 
    'city', 
    'street', 
    'prov', 
    'cap', 
    'licord', 
    'client'
  ];  

  constructor(
    private serviceStat: HomepageService, 
    private sWorksite: WorksiteService, 
    private router: Router,
    private logger: LoggerService,
    private error: ErrorService
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit(): void {
    if (this.filter == null) {
      localStorage.setItem('filtro', '0')
      this.filter = '0'
    }
    this.serviceStat.getStats().subscribe(res => {
      if (res != null) {
        this.activeWorksite = res.data!.active_worksites
        this.activeLicord = res.data!.active_licord
        this.warningtot = res.data!.warning_licord
      }
    },
    (error) => {
      this.error.handleError(error)
    })
    this.sWorksite.getWorksites(this.value!).subscribe(res => {
      this.logger.debug(res)
      if (res.error_message!.msg_code === 8) {
        this.dataL = false
      }
      else if (res != null) {
        this.dataL = true
        this.worksites = res.data;
        this.dataSource = new MatTableDataSource(this.worksites);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        const mapProperties = {
          center: new google.maps.LatLng(this.lat, this.lng),
          zoom: 5,
          scrollwheel: false,
          streetViewControl: true,
          gestureHandling: 'cooperative',
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
          }
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
        this.worksites.forEach((location: { cn_latitude: any; cn_longitude: any; }) => {
          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(location.cn_latitude, location.cn_longitude),
            //icon: location.icon,
            map: this.map,
            title: "Cantiere",
            size: new google.maps.Size(5, 5)
          });
          marker.addListener("click", () => {
            localStorage.setItem('selected worksite', JSON.stringify(location));
            this.router.navigate(['default/licordList'])
          });
        });
      }
    },
    (error) => {
        this.error.handleError(error)
    })
  }

  saveWorksiteData(worksiteData: any) {
    this.sWorksite.getWorksite(worksiteData.cantieri_key).subscribe(res => {
      localStorage.setItem('selected worksite', JSON.stringify(res.data))
      this.router.navigate(['/default/licordList'])
    })
  }

  view() {
    this.asList = !this.asList
    this.asTable = !this.asTable
  }

  filtra(value: string | null) {    
    if (value != "" || value != null) {
      localStorage.setItem('filtro', '1')
      this.filter = '1'
    } 
    else {
      localStorage.setItem('filtro', '0')
    }
    this.value = value
    this.ngOnInit()
  }

}
