<h1 *ngIf="dataL==true" style="text-align:center">Licord in warning</h1>
<br>

<div style="display: flex; flex-direction: column">
    <h4 *ngIf="dataL==false &&  filter=='0'" style="text-align: center">Attualmente non ci sono licord in warning</h4>
    <div *ngIf="dataL==false &&  filter=='1'" style="display: flex; flex-direction: column; justify-content: center"> 
        <h4 style="text-align: center">Nessuna corrispondenza trovata</h4>
    <button mat-raised-button 
        style="text-align: center; margin: 20px; font-size: 20px" (click)="filtra(null)">Nuova ricerca</button> 
    </div>    
</div>

<div [hidden]="dataL==false">
    <mat-form-field class="example-form-field">
        <button *ngIf="value" matPrefix mat-icon-button aria-label="Clear">
            <img src="/assets/x.png" alt="" width="10" (click)="filtra(null)">
        </button>
        <mat-label>Filtro</mat-label>
        <form 
            (ngSubmit)="filtra(value)"
            style="
                    padding: 10px;
                    border-radius: 4px;
                    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)
                "
        >
            <input matInput type="text" [(ngModel)]="value" [ngModelOptions]="{standalone: true}">
        </form>
        <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear">
            <img src="/assets/right-arrow.png" alt="" width="20" (click)="filtra(value)">
        </button>
    </mat-form-field>

    <section class="example-container mat-elevation-z8" tabindex="0">
        <div class="divTable">
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="serialnumber">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let row" (click)="saveDataWorksite(row)">{{ row.ds_seriale }}</td>
                </ng-container>

                <ng-container matColumnDef="cliente">
                    <th mat-header-cell *matHeaderCellDef>CLIENTE</th>
                    <td mat-cell *matCellDef="let row" (click)="saveDataWorksite(row)">{{ row.cn_cliente }}</td>
                </ng-container>

                <ng-container matColumnDef="dataInstallazione">
                    <th mat-header-cell *matHeaderCellDef>DATA INSTALLAZIONE</th>
                    <td mat-cell *matCellDef="let row" (click)="saveDataWorksite(row)">
                        {{ row.ds_data_installazione | date:'dd/MM/yyyy' }}</td>
                </ng-container>

                <ng-container matColumnDef="dataRevisione">
                    <th mat-header-cell *matHeaderCellDef>DATA REVISIONE</th>
                    <td mat-cell *matCellDef="let row; let i = index" [ngClass]="{'scaduto': isScaduto(row.ds_data_installazione)}">
                        <ng-container *ngIf="!row.isEditable; else editModeRevisione">
                            <span>{{ calcolaDataRevisione(row.ds_data_installazione) | date:'dd/MM/yyyy' }}</span>
                            <div 
                                class="progress-bar-container"
                                matTooltip="{{calcolaGiorniMancanti(row.ds_data_installazione)}} giorni rimanenti"
                            >
                                <div 
                                    class="progress-bar" 
                                    [style.width.%]="calcolaProgresso(row.ds_data_installazione)" 
                                    [ngClass]="{'progress-bar-green': isMonthComplete(row.ds_data_installazione) && !isScaduto(row.ds_data_installazione), 'progress-bar-red': isScaduto(row.ds_data_installazione)}"
                                ></div>
                            </div>
                        </ng-container>
                        <ng-template #editModeRevisione>
                            <input *ngIf="row.isEditable" type="text" value="...">
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="tab_comune_desc">
                    <th mat-header-cell *matHeaderCellDef>COMUNE</th>
                    <td mat-cell *matCellDef="let row" (click)="saveDataWorksite(row)"> {{ row.tab_comune_desc }}</td>
                </ng-container>

                <ng-container matColumnDef="cn_indirizzo">
                    <th mat-header-cell *matHeaderCellDef>INDIRIZZO</th>
                    <td mat-cell *matCellDef="let row" (click)="saveDataWorksite(row)"> {{ row.cn_indirizzo }}</td>
                </ng-container>

                <ng-container matColumnDef="warning">
                    <th mat-header-cell *matHeaderCellDef>WARNING</th>
                    <td mat-cell *matCellDef="let row" (click)="saveDataWorksite(row)"> 
                        <img src={{row.warning_ico_link}} alt="row.warning_ico_link" width="7%"> 
                    </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef>DISATTIVA</th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon style="color: red" (click)="disableW(row.warning_key)">disabled_visible</mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;"></tr>
            </table>

            <mat-paginator [length]="devices?.length" [pageSize]="5" [pageSizeOptions]="[1, 5, 10, 25, 100]"
                aria-label="Select page">
            </mat-paginator>

        </div>
    </section>
</div>