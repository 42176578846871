<div>
    <button 
        mat-raised-button style="background-color: #e2c231" 
        routerLinkActive="list-item-active"
        routerLink="/default/addworksite"
    >Conferma</button>
    <br> <br>
    <mat-form-field>
        <mat-label>Filtra</mat-label>
        <input matInput placeholder="Filtra" (keyup)="applyFilter($event)">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="checked">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Seleziona</th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox color="primary" [(ngModel)]="row.checked"></mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="ac_nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>NOME</th>
            <td mat-cell *matCellDef="let row">{{row.ac_nome}}</td>
        </ng-container>
        <ng-container matColumnDef="ac_cognome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>COGNOME</th>
            <td mat-cell *matCellDef="let row">{{row.ac_cognome}}</td>
        </ng-container>
        <ng-container matColumnDef="ac_email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>EMAIL</th>
            <td mat-cell *matCellDef="let row">{{row.ac_email}}</td>
        </ng-container>
        <ng-container matColumnDef="tab_tipo_utente_desc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>TIPO</th>
            <td mat-cell *matCellDef="let row">{{row.tab_tipo_utente_desc}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
    <mat-paginator 
        [length]="accounts.length" 
        [pageSize]="25" 
        [pageSizeOptions]="[1, 5, 10, 25, 100]"
        aria-label="Select page"
    >
    </mat-paginator>
    <br>
    <br>
</div>