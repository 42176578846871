<div class="containerInserimentoLicord">
    <form [formGroup]="licordForm" id="contact" style="margin-bottom:8px; width:100%">
        <h3 style="text-align:center">Nuovo Licord</h3>   
        <h4 style="text-align:center">Aggiungi dispositivo</h4> 
        <div class="containerFormLicord">
            <div>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo1()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>ID dispositivo</mat-label>
                        <input formControlName="serialNumber" matInput>
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo2()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Numero progressivo</mat-label>
                        <input formControlName="progressiveNumber" matInput>
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo3()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Seriale Cella</mat-label>
                        <input formControlName="serialCella" matInput>
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo4()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Fondo Scala</mat-label>
                        <input formControlName="fondoScala" matInput>
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo5()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Sensibilità Cella</mat-label>
                        <input formControlName="sensibilityCella" matInput>
                    </mat-form-field>
                </p>     
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo13()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Fattore calibrazione</mat-label>
                        <input formControlName="fattore_calibrazione" matInput>
                    </mat-form-field>
                </p>         
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass" class="pdxInserimentoLicord">
                        <mat-label>{{modemssid}}</mat-label>              
                        <mat-select formControlName="ds_modem_key">
                            <mat-option *ngFor="let item of modemList" value="{{item.key}}">{{item.ssid}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </p> 
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo12()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Note</mat-label>
                        <input type="text" formControlName="ds_note" matInput>
                    </mat-form-field>
                </p>       
            </div>
            <div>                                 
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo11()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Data installazione</mat-label>
                        <input matInput formControlName="ds_data_installazione" [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </p>  
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo14()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Timeout accensione</mat-label>
                        <input type="number" formControlName="timeout_accensione" matInput>
                    </mat-form-field>
                </p>  
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo15()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Tentativi wifi</mat-label>
                        <input type="number" formControlName="tentativi_wifi" matInput>
                    </mat-form-field>
                </p>   
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo7()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Delta_T</mat-label>
                        <input type="number" formControlName="delta_t" [disabled]="gruppo2" matInput>
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo8()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Wake up 1</mat-label>
                        <input type="time" formControlName="wake_up_1" [disabled]="gruppo1" matInput>
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo9()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Wake up 2</mat-label>
                        <input type="time" formControlName="wake_up_2" [disabled]="gruppo1" matInput>
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo10()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Wake up 3</mat-label>
                        <input type="time" formControlName="wake_up_3" [disabled]="gruppo1" matInput>
                    </mat-form-field>
                </p>                        
            </div>
        </div>
        <!--<div class="containerFormLicord">
            <div>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclasss">
                        <button class="btn-info" mat-icon-button matSuffix aria-label="Info" (click)="showInfo12()">
                            <mat-icon>info</mat-icon>
                        </button>
                        <mat-label>Note</mat-label>
                        <input type="text" formControlName="ds_note" matInput>
                    </mat-form-field>
                </p>  
            </div>
        </div>-->
        <fieldset
            style="
                margin-top: 20px;
                margin-bottom: -40px
            "
        >
            <button 
                (click)="saveDevice()" 
                data-submit="...Sending" 
                class="btn-add"
                id="contact-submit"
                type="submit" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >cloud_upload</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >AGGIUNGI</span>
            </button>
            <button 
                data-submit="...Sending" 
                (click)="close()"
                class="btn-annulla"
                id="contact-submitt"
                type="submit" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >clear</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >ANNULLA</span>
            </button>
        </fieldset>
    </form>
</div>
