import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../service/auth.service';
import { HomepageService } from '../service/homepage.service';
import { LoggerService } from '../service/logger.service';
import { ErrorService } from '../service/error.service';
import { SwalService } from '../service/swal.service';
import { AccountsService } from '../service/accounts.service';
import { ResetPw } from '../models/resetPw.model';
import { HighchartsChartModule } from 'highcharts-angular';
HC_exporting(Highcharts);
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import Highcharts from 'highcharts';
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);

Highcharts.setOptions({
  lang: {
    months: [
      'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
      'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
    ],
    weekdays: [
      'Domenica', 'Lunedì', 'Martedì', 'Mercoledì',
      'Giovedì', 'Venerdì', 'Sabato'
    ],
    shortMonths: [
      'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu',
      'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'
    ],
    decimalPoint: ',',
    thousandsSep: '.',
    loading: 'Caricamento...',
    contextButtonTitle: 'Menu contestuale',
    printChart: 'Stampa grafico',
    downloadJPEG: 'Download immagine JPEG',
    downloadPDF: 'Download documento PDF',
    downloadPNG: 'Download immagine PNG',
    downloadSVG: 'Download immagine vettoriale SVG',
    downloadCSV: 'Download dati in formato CSV',
    downloadXLS: 'Download dati in formato XLS',
    viewFullscreen: 'Visualizza a schermo intero',
    exitFullscreen: 'Esci dallo schermo intero',
    viewData: 'Visualizza dati tabellari',
    //openInCloud: 'Apri nel cloud',
    //monthsShort: [
    //  'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu',
    //  'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'
    //],
    rangeSelectorFrom: 'Da',
    rangeSelectorTo: 'A',
    rangeSelectorZoom: 'Zoom',
    resetZoom: 'Reset zoom',
    resetZoomTitle: 'Resetta lo zoom al livello 1:1'
  }
});


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  formz: UntypedFormGroup;
  responsedata: any;
  input = false;
  ip: any; 
  hasForgotPsw = false;
  isEmailValid: boolean = false;
  delaySubmit: boolean = false;
  timer!: any;
  maxTimeout!: Date;  
  emailPattern = `/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i`;
  inputForgotPsw: string = '';
  delayReSend: number = 60;  
  timeLeft!: number;
  successMessage: string = '';
  errorMessage: string = '';

  user: ResetPw[] = [];
  emailz: string = '';
  token: string = '';
  isTokenValid = true

  constructor(
    private readonly fb: UntypedFormBuilder, 
    private service: AuthService, 
    private router: ActivatedRoute,
    private route: Router,   
    private http: HttpClient, 
    private ipS: HomepageService,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService,
    private accServ: AccountsService
  ) {
    this.form = this.fb.group({
      email: ['', [ Validators.required, Validators.email ]],
      password: ['', [ Validators.required, this.validatorPassword ]]
    });
    this.formz = this.fb.group({
      username: ['', [ Validators.required, Validators.email ]]
    });
  }

  ngOnInit(): void {
    /* this.ipS.getIPAddress().subscribe((res:any)=>{
      console.log('login',res.ip)
    })*/
      this.router.queryParams.subscribe(params => {
        if (params && params['t']) {
          this.token = params['t']
          this.service.verifyToken(this.token).subscribe(res => {
            if (!res.success) {
              this.isTokenValid = false
            }
          });
        } 
        else {
          this.logger.error('Parametro "t" non presente nella query URL');
        }
      });
  }

  ngOnDestroy(): void {console.log('Component destroyed'); }

  ProceedLogin() {
    let credential = this.form.value;
    credential["ip_address"] = "192.168.188.51";
    this.logger.info('Credentials: ', credential)
    if (this.form.valid) {
      this.service.proceedLogin(credential).subscribe(
        res => {
          this.responsedata = res;
          if (this.responsedata.success) {
            this.swal.login();
            this.logger.info('Response: ', res)
            localStorage.setItem('userdata', JSON.stringify(this.responsedata.data))          
            localStorage.setItem('ac_email', this.responsedata.data.ac_email)
            localStorage.setItem('ac_nome', this.responsedata.data.ac_nome)
            localStorage.setItem('ac_tipo_utente_cod', this.responsedata.data.ac_tipo_utente_cod)
            localStorage.setItem('token', this.responsedata.data.token);
            const scadenza = new Date(this.responsedata.data.ac_data_scadenza);
            this.logger.info(`Scadenza account: `, scadenza);
            const oggi = new Date();
            oggi.setHours(0, 0, 0, 0);
            if (scadenza < oggi) {
              this.logger.info(`L'account è scaduto il giorno: `, scadenza);
            }
            this.route.navigate(['default/worksites'])          
          }
          else {
            const scadenza = new Date(this.responsedata.data.ac_data_scadenza);
            const oggi = new Date();
            oggi.setHours(0, 0, 0, 0);
            if (scadenza < oggi) {
              this.logger.info(`L'account è scaduto il giorno: `, scadenza);
            }
            setTimeout(() => {
              this.swal.login_err();
            }, 2000);         
          }
        },
        error => {
            this.error.handleError(error)
            setTimeout(() => {
              this.swal.login_err();
            }, 2000);            
        }
      )
    }
  }

  submitForm() {
    if (this.form.valid) {
      this.logger.info('Form credentials: ', this.form.getRawValue());
      this.swal.form();
      this.route.navigate(['default/worksites']);
    } 
    else {
      this.error.handleError
      this.swal.form_err()
    }
  }

  validatorPassword(fc: UntypedFormControl) {
    const value = fc.value as string;
    const isInvalid = 'password' === value.trim().toLowerCase();
    return isInvalid ? { passwordError: 'Password is not a strong password' } : null;
  }

  validateEmail(email: string) {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
    this.isEmailValid = emailPattern.test(email);
    return this.isEmailValid;
  }

  show() {
    if(this.input) {
      this.input = false;
    }
    else {
      this.input = true;
    }    
  }  

  forgotpw(val:string){    
    console.log('email inserita da resettare',val)
    this.service.sendMail(val).subscribe(res=>{
      if(res!=null){
        if(res.success ){
          alert('Email inviata correttamente')
        }else{
          alert('Si è verificato un errore, riprova')
        }
        console.log(res) 
      }
      
    })
  }

  forgotpwww(val: any) {
    this.logger.info('Box element: ', this.inputForgotPsw);
    this.logger.info('Email to reset: ', val);
    this.service.sendMailzz(val).subscribe(
      (res) => {
        res.data = val;
        if (res.success) {
          this.logger.info('Roba: ', res.data);
          this.logger.debug('Email successfully sent to: ', val);
          this.swal.pwd();
        } 
        else {
          this.logger.error('Failed to send email: ', res);
          this.swal.pwd_err();
        }
      },
      (error) => {
        this.logger.error('Network or server error during email sending:', error);
        this.swal.pwd_err();
      }
    );    
  }    

}
