import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { LicordService } from '../../service/licord.service';
import { WorksiteService } from '../../service/worksite.service';
import { ErrorService } from '../../service/error.service';

declare var google: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class HomepageComponent implements OnInit, OnDestroy {

  @ViewChild('map') mapElement: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  map: any;
  mapInitialized: boolean = false;
  markers: google.maps.Marker[] = [];
  lat = 43.66703722671484;
  lng = 10.999165784352293;
  arrayWorksiteinWarning: number[] = []
  arrayLatLngWorksiteWarning: { cn_latitude: number, cn_longitude: number, cantieri_key: number }[] = []  

  constructor(
    private sLicord: LicordService,
    private sWorksite: WorksiteService,
    private router: Router,
    private error: ErrorService
  ) { }

  ngOnDestroy(): void {console.log('Component destroyed'); }



  ngOnInit() {
    this.sLicord.getAllWarningDevices().subscribe(
      (res) => {
        ///creazione mappa
        const mapProperties = {
          center: new google.maps.LatLng(this.lat, this.lng),
          zoom: 5,
          scrollwheel: false,
          streetViewControl: false,
          gestureHandling: 'cooperative',
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
          }
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
        res.data!.forEach((element: any) => {
          this.sWorksite.getWorksite(element.ds_cantiere_key).subscribe(res => {
            let x = {
              cantieri_key: res.data!.cantieri_key,
              cn_latitude: res.data!.cn_latitude,
              cn_longitude: res.data!.cn_longitude
            }
            this.arrayLatLngWorksiteWarning.push(x)
            ///mappa (fatto male ma funziona)
            this.arrayLatLngWorksiteWarning.forEach((location) => {
              var marker = new google.maps.Marker({
                position: new google.maps.LatLng(location.cn_latitude, location.cn_longitude),
                //icon: Image,
                map: this.map,
                title: "Cantiere" + location.cantieri_key,
                size: new google.maps.Size(5, 5)
              });
              marker.addListener("click", () => {
                localStorage.setItem('selected worksite', JSON.stringify(location));
                this.router.navigate(['default/licordList'])
              });
            });
          })
        });
      }, 
      (error) => {
        console.log(error)
      }
    )
  }

  /*ngOnInit() {
    const warningDevices$ = this.sLicord.getAllWarningDevices();
    if (warningDevices$) {
      warningDevices$.subscribe(
        () => {
          this.getWorksitesInWarning();
        },
        error => {
          this.error.handleError(error);
        }
      );
    } 
    else {
      console.error('Warning devices is null or undefined');
    }
    this.loadData();
  }*/

  /*ngAfterViewInit() {
    this.createMap();
  }*/

  async loadData() {
    try {
      const warningDevices = await this.sLicord.getAllWarningDevices().toPromise();
      for (const device of warningDevices.data) {
        const worksite = await this.sWorksite.getWorksite(device.ds_cantiere_key).toPromise();
        this.arrayLatLngWorksiteWarning.push({
          cantieri_key: worksite.data.cantieri_key,
          cn_latitude: worksite.data.cn_latitude,
          cn_longitude: worksite.data.cn_longitude
        });
      }
      if (this.mapInitialized) {
        this.createMarkers();
      }
    } catch (error) {
      this.error.handleError(error);
    }
  }
  
  createMap() {
    if (typeof google !== 'undefined') {
      const mapProperties = {
        center: new google.maps.LatLng(this.lat, this.lng),
        zoom: 5,
        scrollwheel: false,
        streetViewControl: false,
        gestureHandling: 'cooperative',
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
        }
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
      this.map.addListener('tilesloaded', () => {
        if (this.map) {
          this.mapInitialized = true;
          if (this.mapInitialized) {
            this.getWorksitesInWarning();
          }
        } 
        else {
          console.error('L\'oggetto mappa non è stato inizializzato correttamente.');
        }
      });
    } 
    else {
      console.error('Impossibile creare la mappa: google non è definito.');
    }
}


  getWorksitesInWarning() {
    this.sLicord.getAllWarningDevices().subscribe(
      res => {
        res.data!.forEach((element: { ds_cantiere_key: any; }) => {
          console.log('Cantiere key:', element.ds_cantiere_key);
          this.sWorksite.getWorksite(element.ds_cantiere_key).subscribe(
            res => {
              console.log('Dati del cantiere:', res.data);
              let x = {
                cantieri_key: res.data!.cantieri_key,
                cn_latitude: res.data!.cn_latitude,
                cn_longitude: res.data!.cn_longitude
              }
              this.arrayLatLngWorksiteWarning.push(x);
              if (this.mapInitialized) {
                this.createMarkers();
              }
            },
            error => {
              this.error.handleError(error);
            }
          );
        });
      },
      error => {
        this.error.handleError(error);
      }
    );
  }

  createMarkers() {
    if (this.map && this.arrayLatLngWorksiteWarning.length > 0) {
      this.arrayLatLngWorksiteWarning.forEach((location) => {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(location.cn_latitude, location.cn_longitude),
          map: this.map,
          title: "Cantiere" + location.cantieri_key,
          size: new google.maps.Size(5, 5)
        });        
        marker.addListener("click", () => {
          localStorage.setItem('selected worksite', JSON.stringify(location));
          this.router.navigate(['default/licordList']);
        });
        this.markers.push(marker);
      });
    } 
    else {
      console.error('Impossibile creare i marcatori: la mappa non è stata inizializzata correttamente.');
    }
  }

  navigateToWorksiteDetails() {
    this.router.navigate(['default/licordList']);
  }

  addMarkerClickListener(marker: google.maps.Marker) {
    marker.addListener('click', () => {
      this.navigateToWorksiteDetails();
    });
  }

  getMarkers() {
    return this.markers
  }

}
