import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Data } from './../models/data.model';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})

export class SensordataService {

  qapiUrl = "https://licord2024.connecta.ovh/api/services/get_data/"
  apiUrl = `${environment.apiUrl}api/services/get_data/`


  constructor(
    private http: HttpClient,
    private logger: LoggerService
  ) { }

  //Get all infos about device
  getData(device: string): Observable<any> {
    return this.http.get<Data>(this.apiUrl + device)
      .pipe(
        tap(response => {
          this.logger.debug('Data successfully loaded!')
        }),
        catchError(error => {
          this.logger.error('Error while retrieving data:', error);
          throw error;
        })
      );
  }

}
