import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultModule } from './layouts/default/default.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { NgxSpinnerModule } from 'ngx-spinner';
import { GoogleMapsModule } from '@angular/google-maps';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ResetComponent } from './reset/reset.component';
import { TokenInterceptorService } from './service/token-interceptor.service';
import { LoggerService } from './service/logger.service';
import { ErrorService } from './service/error.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from './service/config.service';

/*export function initializeApp(configService: ConfigService) {
  return () => configService.loadConfig();
}*/

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    SpinnerComponent,
    ResetComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgxSpinnerModule,
    //GoogleMapsModule,
    /*AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCZGeSBJXtGji28P1B6sjOA7oRnaYZl0LM'
    })*/
    
  ],
  providers: [
    ConfigService,
    LoggerService,
    ErrorService,
    /*{
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true
    },*/
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: TokenInterceptorService, 
      multi: true 
    },
    { 
      provide: MAT_DIALOG_DATA, 
      useValue: {} 
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
