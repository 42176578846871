<div 
    class="wrapper fadeInDown" 
    style="
        background-image: url('/assets/sfondo.jpg');
        background-repeat: no-repeat; 
        background-attachment: fixed;
        background-size: cover;
    "
>
    <div id="formContent">

        <!-- Tabs Titles -->
        <h2 class="active"> ACCEDI </h2>

        <!-- Icon -->
        <div class="fadeIn first">
            <img src="../../assets/logo-licord-orizzontale.png" id="icon" alt="User Icon" />
        </div>

        <!-- Login Form -->
        <form [formGroup]="form" (ngSubmit)="submitForm()">
            <input type="text" id="email" class="fadeIn second" name="email" placeholder="Email" [formControlName]="'email'">
            <div *ngIf="form.get('email')?.dirty && form.get('email')?.errors">
                Il formato della mail non è valido
            </div>
            <input type="password" id="password" class="fadeIn third" name="password" placeholder="Password" [formControlName]="'password'">
            <input [disabled]="!form.valid" type="submit" class="fadeIn fourth" value="Log In" (click)="ProceedLogin()">
        </form>

        <!-- Remind Passowrd -->
        <div id="formFooter">
          <a class="underlineHover" (click)="show()" href="#">Password dimenticata?</a>
          <div *ngIf="input" class="form-input">
            <input #box type="text" placeholder="Inserisci qui la tua mail">
            <span class="icon">
              <mat-icon id="send" (click)="forgotpwww(box.value)">send</mat-icon>
            </span>
          </div>
        </div>        

          <!--<div id="formFooter">
            <a
              (click)="show()"
              tabindex="5"
              class="underlineHover"
              >Password dimenticata?</a>
            <div class="form-input">
              <input
                  #box
                  type="email"
                  placeholder="Inserisci qui la tua mail"
                  [(ngModel)]="inputForgotPsw"
                  [pattern]="emailPattern"
                  (keydown.enter)="forgotpwww(inputForgotPsw)"
                  >
                  <span class="icon">
                    <mat-icon id="send" (click)="forgotpwww(box.value)">send</mat-icon>
                  </span>
                  <input
                  tabindex="6"
                  class="form-input-width input-custom fadeIn third"
                  label-placement="floating"
                  fill="solid"
                  #box
                  type="email"
                  placeholder="Inserisci qui la tua mail"
                  required
                  [(ngModel)]="inputForgotPsw"
                  [pattern]="emailPattern"
                  (keydown.enter)="forgotpwww(inputForgotPsw)"
                  >
                  <button
                    tabindex="7"
                    fill="clear"
                    slot="end"
                    [disabled]="this.delaySubmit"
                    (click)="forgotpwww(inputForgotPsw)"
                  >
                    <mat-icon color="primary" slot="icon-only" name="send">send</mat-icon>
                  </button>
            </div>
          </div>-->

    </div>
</div>