<mat-card>
    <mat-card-title>
        <h2 style="text-align:center">Gestione cantieri</h2>
        <mat-icon 
            (click)="openDialog(wok)"
            class="btn-add"
        >
        add_circle</mat-icon>
    </mat-card-title>

    <ng-template id="wok" #wok></ng-template>

    <mat-card-content>
        <div style="display: flex; flex-direction: column">
            <h4 *ngIf="dataL==false &&  filter=='0'" style="text-align: center">Attualmente non ci sono licord in warning</h4>
            <div *ngIf="dataL==false &&  filter=='1'" style="display: flex; flex-direction: column; justify-content: center"> 
                <h4 style="text-align: center">Nessuna corrispondenza trovata</h4>
            <button mat-raised-button 
                style="text-align: center; margin: 20px; font-size: 20px" (click)="filtra(null)">Nuova ricerca</button> 
            </div>    
        </div>
        <mat-form-field class="example-form-field">
            <button *ngIf="value" matPrefix mat-icon-button aria-label="Clear">
                <img src="/assets/x.png" alt="" width="10" (click)="filtra(null)">
            </button>
            <mat-label>Filtro</mat-label>
            <form 
                (ngSubmit)="filtra(value)"
                style="
                    padding: 10px;
                    border-radius: 4px;
                    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)
                "
            >
                <input matInput type="text" [(ngModel)]="value" [ngModelOptions]="{standalone: true}">
            </form>
            <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear">
                <img src="/assets/right-arrow.png" alt="" width="20" (click)="filtra(value)">
            </button>
        </mat-form-field>
        <section class="example-container mat-elevation-z8" tabindex="0">
            <div class="divTable">
                <table mat-table [dataSource]="dataSource2" matSort>
                    <ng-container matColumnDef="cantieri_key">
                        <th mat-header-cell *matHeaderCellDef>ID CANTIERE</th>
                        <td mat-cell *matCellDef="let row">{{ row.cantieri_key }}</td>
                    </ng-container>
                    <ng-container matColumnDef="tab_tipo_cantiere_desc">
                        <th mat-header-cell *matHeaderCellDef>TIPO CANTIERE</th>
                        <td mat-cell *matCellDef="let row">{{ row.tab_tipo_cantiere_desc }}</td>
                    </ng-container>
                    <ng-container matColumnDef="cn_indirizzo">
                        <th mat-header-cell *matHeaderCellDef>INDIRIZZO</th>
                        <td mat-cell *matCellDef="let row">{{ row.cn_indirizzo }}</td>
                    </ng-container>
                    <ng-container matColumnDef="tab_comune_desc">
                        <th mat-header-cell *matHeaderCellDef>COMUNE</th>
                        <td mat-cell *matCellDef="let row">{{ row.tab_comune_desc }}</td>
                    </ng-container>
                    <ng-container matColumnDef="tab_provincia_cod">
                        <th mat-header-cell *matHeaderCellDef>PROVINCIA</th>
                        <td mat-cell *matCellDef="let row" style="padding-left: 2em">{{ row.tab_provincia_cod }}</td>
                    </ng-container>
                    <ng-container matColumnDef="tab_cap_cod">
                        <th mat-header-cell *matHeaderCellDef>CAP</th>
                        <td mat-cell *matCellDef="let row">{{ row.tab_cap_cod }}</td>
                    </ng-container>
                    <ng-container matColumnDef="cn_numero_licord">
                        <th mat-header-cell *matHeaderCellDef>N°LICORD</th>
                        <td mat-cell *matCellDef="let row" style="padding-left: 2em">{{ row.cn_numero_licord }}</td>
                    </ng-container>
                    <ng-container matColumnDef="cn_cliente">
                        <th mat-header-cell *matHeaderCellDef>CLIENTE</th>
                        <td mat-cell *matCellDef="let row">{{ row.cn_cliente }}</td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>MODIFICA</th>
                        <td mat-cell *matCellDef="let row" style="padding-left: 2em">
                            <button mat-icon-button>
                                <mat-icon>edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columns2; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns2;" (click)="saveDataWorksite(row)"></tr>
                </table>
                <mat-paginator [length]="worksites?.length" [pageSize]="25" [pageSizeOptions]="[1, 5, 10, 25, 100]"
                    aria-label="Select page">
                </mat-paginator>
            </div>
        </section>
    </mat-card-content>
    
</mat-card>