import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddImmagesComponent } from './add-immages/add-immages.component';
import { AddTreDComponent } from '../tred/add-tred/add-tred.component';
import { WorksiteService } from '../../service/worksite.service';
import { DocumentService } from '../../service/document.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';
import { SwalService } from '../../service/swal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-images',
  templateUrl: './immages.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class ImmagesComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator; 

  showTable = true;
  isAdmin = false;
  dataSource!: MatTableDataSource<any>;
  img: any;
  idWorksite = JSON.parse(localStorage.getItem('selected worksite') || '[]').cantieri_key
  columns: string[] = [
    '#',
    'title', 
    'link', 
    'delete'
  ];    

  constructor(
    private sWorksite: WorksiteService, 
    private sDocument: DocumentService,
    private dialog: MatDialog,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService
  ) { }

  ngOnDestroy(): void {console.log('Component destroyed'); }


  ngOnInit(): void {
    if (localStorage.getItem('ac_tipo_utente_cod') == '1') {
      this.isAdmin = true;
    }
    this.sWorksite.getWorksite(this.idWorksite).subscribe(res => {
      if (res != null) {
        if (res.data.pictures.length.toString() === '0') {
          this.showTable = false
        } 
        else {
          this.img = res.data.pictures;
          this.logger.info('Images: ', this.img)
          this.dataSource = new MatTableDataSource(this.img);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      }
    },
    (error) => {
      this.error.handleError(error)
    })
  }

  openDialog(id: any) {
    this.dialog.open(AddImmagesComponent, {
      data:{
        id: id
      }
    });
  }

  openDialog2(id: any) {
    this.dialog.open(AddTreDComponent, {
      data:{
        id: id
      }
    });
  }

  deleteImg(picture_key: number) {
    let data = {
      cantieri_key: this.idWorksite,
      documentKey: picture_key,
      documentType: 1
    }
    this.swal.img_warning('Questa immagine verrà eliminata!').then((result) => {
      if (result.value) {
        this.logger.info('Image: ', data)
        this.sDocument.deleteFile(data).subscribe(res => {
          if (res != null) {
            this.logger.debug(res)
            this.ngOnInit();
            this.swal.img_delete()
          }
        }, 
        (error) => {
          this.error.handleError(error)
          this.swal.img_err();
        })
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) {
        this.swal.img_safe()
        return
      }
    })
  }

}
