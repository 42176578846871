export interface PasswordChange {
    old_password: string;
    new_password: string;
    rep_password: string;
}

export interface IChangePasswordInApp {
    old_password: string;
    new_password: string;
    rep_password: string;
    account_id: number;
}

export class ChangePasswordInApp implements IChangePasswordInApp {
    old_password: string = '';
    new_password: string = '';
    rep_password: string = '';
    account_id: number = 0;

    constructor(pass: IChangePasswordInApp | null) {
        if (!pass) return;
        this.account_id = pass.account_id;
        this.new_password = pass.new_password;
        this.rep_password = pass.rep_password;
        this.old_password = pass.old_password;
    }
}

export interface IForgottenPassword {
    customer_account_id: number;
    new_password: string;
    rep_password: string;
}

export class ForgottenPassword implements IForgottenPassword {
    customer_account_id: number = 0;
    new_password: string = '';
    rep_password: string = '';
    constructor(pass: IForgottenPassword | null) {
        if (!pass) return;
        this.customer_account_id = pass.customer_account_id;
        this.new_password = pass.new_password;
        this.rep_password = pass.rep_password;
    }
}

export enum PasswordPolicy {
    Min8Alfa1Number1,
    Min8AlfaUpper1Number1,
    Min8Alfa1Number1Special1,
    Min8AlfaUpper1Number1Special1
}