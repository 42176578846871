import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { PopsComponent } from '../licord/pops/pops.component';
import { Licord } from '../../models/licord.model';
import { LicordService } from '../../service/licord.service';
import { SensordataService } from '../../service/sensordata.service';
import { WorksiteService } from '../../service/worksite.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';
HC_exporting(Highcharts);
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


Highcharts.setOptions({
  lang: {
    months: [
      'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
      'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
    ],
    weekdays: [
      'Domenica', 'Lunedì', 'Martedì', 'Mercoledì',
      'Giovedì', 'Venerdì', 'Sabato'
    ],
    shortMonths: [
      'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu',
      'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'
    ],
    decimalPoint: ',',
    thousandsSep: '.',
    loading: 'Caricamento...',
    contextButtonTitle: 'Menu contestuale',
    printChart: 'Stampa grafico',
    downloadJPEG: 'Download immagine JPEG',
    downloadPDF: 'Download documento PDF',
    downloadPNG: 'Download immagine PNG',
    downloadSVG: 'Download immagine vettoriale SVG',
    downloadCSV: 'Download dati in formato CSV',
    downloadXLS: 'Download dati in formato XLS',
    viewFullscreen: 'Visualizza a schermo intero',
    exitFullscreen: 'Esci dallo schermo intero',
    viewData: 'Visualizza dati tabellari',
    openInCloud: 'Apri nel cloud',
    monthsShort: [
      'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu',
      'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'
    ],
    rangeSelectorFrom: 'Da',
    rangeSelectorTo: 'A',
    rangeSelectorZoom: 'Zoom',
    resetZoom: 'Reset zoom',
    resetZoomTitle: 'Resetta lo zoom al livello 1:1'
  }
});

declare var Highcharts: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild('lineCanvas', { static: false }) private lineCanvas!: ElementRef;
  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  idWorksite = JSON.parse(localStorage.getItem('selected worksite')!).cantieri_key;
  idLicord = JSON.parse(localStorage.getItem('selected device')!).dispositivi_key;
  x = JSON.parse(localStorage.getItem('selected worksite') || '[]');
  y = localStorage.getItem('selected device') || '[]';
  
  a1 = 0;
  a2 = 0
  d1 = 0;
  d2 = 0
  e1 = 0;
  e2 = 0
  r = 0;
  r1 = 0;
  r2 = 0;

  historical: any;
  forza1: { x: number, y: number }[] = [];
  Vaforza1 = true;
  batteria: number[] = [];
  batteriaaa: number[] = [];
  batteriaa: { x: number, y: number }[] = [];
  battery = true
  temperatura: { x: number, y: number }[] = [];
  Vatemp = true;
  temperatura2: { x: number, y: number }[] = [];
  Vatemp2 = true;

  mV = this.r1;
  mV0 = this.r;
  percentuale: any;
  percentuale0: any

  serieA1: any;
  serieA2: any;
  serieB: any;
  serieB2: any;
  serieB3: any;
  serieC: any
  lineChart: any;  
  
  public ds_cantiere_key: any;
  public serialNumber: any;
  public progressiveNumber: any;
  dispositivi_key!: string;
  ds_progressivo: any
  ds_seriale: any
  ds_data_installazione: any
  ds_seriale_cella: any
  fondo_scala_cella: any
  sensibilita_cella: any
  fattore_calibrazione: any
  public wake_up_1 = "";
  public wake_up_2 = "";
  public wake_up_3 = "";
  public delta_t = 0;
  public ds_modem_key: any;
  tab_tipo_cantiere_desc: any
  tab_comune_desc: any
  cn_indirizzo: any
  tab_provincia_cod: any
  tab_cap_cod: any
  cn_cliente: any
  responsabile: string = "";
  installatore: string = "";
  modemList: any;
  modemssid = "Scegli un modem"
  disp: any

  dataSource!: MatTableDataSource<Licord>;
  devices: any;
  dataL = true

  showTable = true;
  isAdmin = false;
  isEditable: boolean = false;
  editedRow: any
  licord: any;
  columns: string[] = [    
    'actions'
  ];  
  lisp: any;

  constructor(
    private sensor: SensordataService,
    private sLicord: LicordService,
    private sWorksite: WorksiteService,
    private router: Router,
    private dialog: MatDialog,
    private logger: LoggerService,
    private error: ErrorService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit() {
    this.battery = JSON.parse(localStorage.getItem('caricaBatteria')!)
    this.sensor.getData(localStorage.getItem('dispositivi_key')!).subscribe(res => {
      res.data?.forEach((el: any) => {
        this.forza1.push({ x: new Date(el.ms_data).getTime() + (2 * 60 * 60 * 1000), y: el.ms_sforzo_l1 })
        this.batteria.push( el.ms_batteria );
        this.batteriaaa.push( el.ms_batteria );
        this.batteriaa.push({ x: new Date(el.ms_data).getTime() + (2 * 60 * 60 * 1000), y: el.ms_batteria })
        this.temperatura.push({ x: new Date(el.ms_data).getTime() + (2 * 60 * 60 * 1000), y: el.ms_temperatura_amb })
        this.temperatura2.push({ x: new Date(el.ms_data).getTime() + (2 * 60 * 60 * 1000), y: el.ms_temperatura_met })
      });
      this.createchart(this.serieA1, 'forza') /*dati in uso*/
      this.logger.debug('Forza chart created!')
      this.createchart(this.serieA2, 'temperature') /*dati in uso*/
      this.logger.debug('Temperature chart created!')
      this.createchart(this.serieB3, 'batt2') /*dati in uso */   
      this.logger.debug('Battery chart created!')   
      this.r = this.batteria[this.batteria.length - 1];
      this.r1 = this.batteriaa[this.batteriaa.length - 1].y;
      this.r2 = this.batteriaa[this.batteriaa.length - 1].x;
      this.percentuale0 = this.batteryPercentage(this.r1);      
      this.a1 = this.forza1[this.forza1.length - 1].y;
      this.a2 = this.forza1[this.forza1.length - 1].x - (2 * 60 * 60 * 1000);
      this.d1 = this.temperatura[this.temperatura.length - 1].y;
      this.d2 = this.temperatura[this.temperatura.length - 1].x;
      this.e1 = this.temperatura2[this.temperatura2.length - 1].y;
      this.e2 = this.temperatura2[this.temperatura2.length - 1].x;
      this.logger.info('Sensor data: ', res)
    });

    this.sWorksite.getWorksite(this.idWorksite).subscribe((res: any) => {
      this.logger.info('ID Worksite: ', this.idWorksite)
      this.tab_tipo_cantiere_desc = res.data?.tab_tipo_cantiere_desc
      this.tab_comune_desc = res.data?.tab_comune_desc
      this.cn_indirizzo = res.data?.cn_indirizzo
      this.tab_provincia_cod = res.data?.tab_provincia_cod
      this.tab_cap_cod = res.data?.tab_cap_cod
      this.logger.info('Worksite infos: ', res)
    });

    this.sLicord.getDevices(this.x.cantieri_key).subscribe(
      (res: any) => {      
        this.devices = res.data
        this.logger.info('Licord: ', this.devices)
        this.progressiveNumber = this.devices?.ds_progressivo
        this.logger.info('N° progressivo: ', this.progressiveNumber)
        this.dataSource = new MatTableDataSource(this.devices);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      (error) => {
        this.error.handleError(error)
      }
    );

    this.sLicord.getDevice(this.idLicord).subscribe(
      (res: any) => {
        this.licord = res.data;
        this.logger.info('licord: ', this.licord);
        this.logger.info('idLicord: ', res);
        this.serialNumber = this.licord?.ds_seriale;
        this.logger.info('ID serialNumber with licord: ', this.serialNumber);
        this.progressiveNumber = this.licord?.ds_progressivo;
        this.logger.info('N° progressivo with licord: ', this.progressiveNumber);
        this.ds_data_installazione = this.licord?.ds_data_installazione;
        this.logger.info('Data installazione: ', this.ds_data_installazione);
        this.logger.info('licord con localStorage aggiornato: ', this.licord);
      },
      (error) => {
        this.error.handleError(error)
      }
    );


/********************************************************************/
    this.sLicord.getDevice(this.idLicord).subscribe(res => {
      this.ds_progressivo = res.data?.ds_progressivo
      this.ds_seriale = res.data?.ds_seriale
      this.ds_data_installazione = res.data?.ds_data_installazione;      
      this.logger.action('getDevice stuff: ', res)
    });

    this.sWorksite.getWorksite(this.idWorksite).subscribe(res => {
      this.tab_tipo_cantiere_desc = res.data?.tab_tipo_cantiere_desc;
      this.tab_comune_desc = res.data?.tab_comune_desc;
      this.cn_indirizzo = res.data?.cn_indirizzo;
      this.tab_provincia_cod = res.data?.tab_provincia_cod;
      this.tab_cap_cod = res.data?.tab_cap_cod;
      this.cn_cliente = res.data?.cn_cliente;
      this.logger.action('getWorksite stuff: ', res)
    });

    this.cdr.detectChanges();
/***************************************************************/


    this.serieA1 = [{
      name: 'Forza',
      data: this.forza1,
      color: '#d7c233',
      legendIndex: 1,
      visible: true,
      showInNavigator: true
    }];

    this.serieA2 = [{
      name: 'Temperatura ambientale',
      data: this.temperatura,
      color: '#39b963',
      legendIndex: 1,
      visible: true,
      showInNavigator: true
    },
    {
      name: 'Temperatura sonda',
      data: this.temperatura2,
      color: '#e23131',
      legendIndex: 1,
      visible: true,
      showInNavigator: true
    }];

    this.serieB3 = [{
      name: 'Batteria',
      data: this.batteriaa,
      color: '#14358b',
      legendIndex: 1,
      visible: true,
      showInNavigator: true
    }];

    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);

  }

  openDialog(id: any) {
    this.dialog.open(PopsComponent, {
      data:{
        dispositivi_key: id
      }
    });
  }

  save(row: any) {
    row.isEditable = false
    row = this.editedRow
    this.editedRow = null
  }

  saveDataLicord(data: any) {
    localStorage.setItem('selected device', JSON.stringify(data))
  }

  createchart(serie: any, a: string) {
    Highcharts.stockChart(a, {
      accessibility: {
        enabled: true
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Grafico'
      },
      subtitle: {
        text: 'I dati visualizzati mostrano l\'andamento dei valori registrati nel corso del tempo'
      },
      rangeSelector: {
        inputPosition: {
          align: 'center',
          x: 0,
          y: 0
        },
      },
      xAxis: {
        type: 'datetime',
        ordinal: false,
        tickPixelInterval: 150,
        dateTimeLabelFormats: {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%e. %b',
          week: '%e. %b',
          month: '%b \'%y',
          year: '%Y'
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
        timezoneOffset: Date.now()
      },
      yAxis: {
        opposite: false,
        plotLines: [{
          value: 0,
          width: 2,
          color: 'silver'
        }]
      },
      series: serie,
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              "printChart",
              "viewFullscreen",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
              "separator",
              "downloadCSV",
              "downloadXLS",
            ]
          }
        },
        error: true
      }
    });
  }    

  Vbatteria() {
    let box = document.getElementById('batteryy');
    this.serieB[0].visible = !this.serieB[0].visible
    this.battery = !this.battery
    if (this.battery) {
      box!.style.backgroundColor = '#14358b';
    }
    else {
      box!.style.backgroundColor = 'grey';
    }
    this.createchart(this.serieB, 'batteria')
  };

  Vtemp2() {
    let box = document.getElementById('temp2');
    this.serieA2[1].visible = !this.serieA2[1].visible
    this.Vatemp2 = !this.Vatemp2
    if (this.Vatemp2) {
      box!.style.backgroundColor = '#e23131';
    }
    else {
      box!.style.backgroundColor = 'grey';
    }
    this.createchart(this.serieA2, 'temperature')
  };

  Vtemp() {
    let box = document.getElementById('temp');
    this.serieA2[0].visible = !this.serieA2[0].visible
    this.Vatemp = !this.Vatemp
    if (this.Vatemp) {
      box!.style.backgroundColor = '#39b963';
    }
    else {
      box!.style.backgroundColor = 'grey';
    }
    this.createchart(this.serieA2, 'temperature')
  };

  Vforza1() {
    let box = document.getElementById('forza1');
    this.serieA1[0].visible = !this.serieA1[0].visible
    this.Vaforza1 = !this.Vaforza1
    if (this.Vaforza1) {
      box!.style.backgroundColor = '#d7c233';
    }
    else {
      box!.style.backgroundColor = 'grey';
    }
    this.createchart(this.serieA1, 'forza')
  };

  formatNumber(value: number): string {
    return value.toLocaleString('it-IT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    });
  }

  goBack() {
    this.router.navigate(['/default/licordList'])
  }

  calcolaForza() {
    return this.fattore_calibrazione + this.fondo_scala_cella * (this.r / this.sensibilita_cella)
  }

  convertToPercentage(mV: number): number {
    const table = [
      { percentage: 100, mV: 4290 },
      { percentage: 80, mV: 3970 },
      { percentage: 60, mV: 3770 },
      { percentage: 40, mV: 3600 },
      { percentage: 20, mV: 3500 },
      { percentage: 0, mV: 3330 }
    ];
    let lowerPoint = table[0];
    let upperPoint = table[table.length - 1];
    for (let i = 0; i < table.length; i++) {
      if (mV >= table[i].mV) {
          lowerPoint = table[i];
          break;
      }
    }
    for (let i = table.length - 1; i >= 0; i--) {
      if (mV <= table[i].mV) {
          upperPoint = table[i];
          break;
      }
    }
    const percentage = lowerPoint.percentage + 
                     ((mV - lowerPoint.mV) / (upperPoint.mV - lowerPoint.mV)) * 
                      (upperPoint.percentage - lowerPoint.percentage);  
    return percentage;
  }

  batteryPercentage(mV: number): number {
    if (mV >= 4081) {
        return 100;
    } 
    else if (mV >= 3863 && mV < 4081) {
        return 80;
    } 
    else if (mV >= 3680 && mV < 3863) {
        return 60;
    } 
    else if (mV >= 3586 && mV < 3680) {
        return 40;
    } 
    else if (mV >= 3470 && mV < 3586) {
        return 20;
    } 
    else {
        return 0;
    }
  }

  getBatteryColor(percentuale: number): string {
    if (percentuale >= 80) {
      return '#28bf28'; // high charge
    } 
    else if (percentuale >= 60) {
      return '#6cff6c'; // medium-high charge
    } 
    else if (percentuale >= 40) {
      return '#ffd700'; // medium charge
    } 
    else if (percentuale >= 20) {
      return '#ff8c00'; // low charge
    } 
    else {
      return '#ff0000'; // very low charge
    }
  }


}