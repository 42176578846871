import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class SwalService {

  constructor( ) { }

  /*********************LOGIN************************** */
  login() {
    Swal.fire({
      title: 'Welcome',
      text: 'Login effettuato con successo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  login_err() {
    Swal.fire({
      title: 'Errore',
      text: 'Avrai anche completato il form, ma sembra che Email o Password inserite siano errate, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  logout() {
    Swal.fire({
      title: 'Arrivederci',
      text: 'Logout effettuato con successo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  /*************************************************** */
  

  /*********************FORM************************** */
  form() {
    Swal.fire({
      title: 'Ottimo',
      text: 'Form completato con successo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  form_err() {
    Swal.fire({
      title: 'Errore',
      text: 'Errore durante la compilazione del form, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  /*************************************************** */


  /*********************PWD************************** */
  pwd() {
    Swal.fire({
      title: 'Inviata',
      text: 'Email inviata correttamente!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  pwd_link() {
    Swal.fire({
      title: 'Inviata',
      text: 'Se esiste un account associato a questa email, riceverai un link per il reset della password',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  pwd_chg() {
    Swal.fire({
      title: 'Cambiata',
      text: 'Password cambiata con successo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  pwd_err() {
    Swal.fire({
      title: 'Errore',
      text: 'Si è verificato un errore, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  pwd_warning() {
    Swal.fire({
      title: 'Spiacente',
      text: 'La password che hai scelto è troppo semplice: riprova usando numeri, lettere e caratteri speciali!',
      icon: 'warning',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }  
  pwd_reset() {
    Swal.fire({
      title: 'Ottimo',
      text: 'Password reimpostata con successo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }  
  pwd_mismatch() {
    Swal.fire({
      title: 'Spiacente',
      text: 'Le password non coincidono, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  } 
  pwd_violation() {
    Swal.fire({
      title: 'Spiacente',
      text: 'La password che hai scelto non soddisfa i criteri di sicurezza!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  } 
  pwd_reseterr() {
    Swal.fire({
      title: 'Ottimo',
      text: 'Errore durante la richiesta di reset della password!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }  
  /*************************************************** */


  /*********************PWD************************** */
  token() {
    Swal.fire({
      title: 'Ottimo',
      text: 'Token valido!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  token_exp() {
    Swal.fire({
      title: 'Errore',
      text: 'Token non valido o scaduto!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  token_err() {
    Swal.fire({
      title: 'Errore',
      text: 'Impossibile verificare il token per il reset della password!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  /*************************************************** */


  /********************DOCUMENTATION****************** */
  doc_warning(msg: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: 'Sei sicuro?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminalo',
      cancelButtonText: 'No, conservalo'
    })
  }
  doc_delete() {
    Swal.fire({
      title: 'Cancellato',
      text: 'Il documento è stato eliminato!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  doc_safe() {
    Swal.fire({
      title: 'Azione annullata',
      text: 'Il tuo documento è salvo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  doc_err() {
    Swal.fire({
      title: 'Errore',
      text: 'Il tuo documento non è stato eliminato!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  /*************************************************** */


  /*************************IMGS********************** */
  img_warning(msg: string): Promise<any> {
    return Swal.fire({
        title: 'Sei sicuro?',
        text: msg,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminala',
        cancelButtonText: 'No, conservala'
    });
  }
  img_delete() {
    Swal.fire({
      title: 'Cancellata',
      text: 'Questa immagine è stata eliminata!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  img_safe() {
    Swal.fire({
      title: 'Azione annullata',
      text: 'La tua immagine è salva!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  img_err() {
    Swal.fire({
      title: 'Errore',
      text: 'La tua immagine non è stata eliminata, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  /*************************************************** */


  /************************LICORD********************* */
  licord_warning(msg: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: 'Sei sicuro?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminalo',
      cancelButtonText: 'No, conservalo'
    })
  }
  licord_delete() {
    Swal.fire({
      title: 'Cancellato',
      text: 'Il licord è stato eliminato!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  licord_safe() {
    Swal.fire({
      title: 'Azione annullata',
      text: 'Il tuo licord è salvo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  licord_add() {
    Swal.fire({
      title: 'Aggiunto',
      text: 'Licord aggiunto con successo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  licord_edit() {
    Swal.fire({
      title: 'Modificato',
      text: 'Licord modificato con successo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  licord_err() {
    Swal.fire({
      title: 'Errore',
      text: 'Licord non inserito, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  licord_err2() {
    Swal.fire({
      title: 'Errore',
      text: 'Licord non eliminato, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  licord_config1() {
    Swal.fire({
      title: 'Inviati',
      text: 'Dati di configurazione inviai con successo!',
      icon: 'success',
      timer: 2500,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  licord_config2() {
    Swal.fire({
      title: 'Attenzione',
      text: 'I dati non sono stati inviati al Licord!',
      icon: 'warning',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  licord_config3() {
    Swal.fire({
      title: 'Errore',
      text: 'Dati di configurazione non inviati, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  /*************************************************** */


  /*************************USER********************** */
  user_warning(msg: string): Promise<SweetAlertResult> {
    return new Promise((resolve) => {
        Swal.fire({
            title: 'Sei sicuro?',
            text: msg,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, eliminalo',
            cancelButtonText: 'No, conservalo'
        }).then((result) => {
            resolve(result);
        });
    });
  }
  user_add() {
    Swal.fire({
      title: 'Aggiunto',
      text: 'Utente creato con successo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  user_delete() {
    Swal.fire({
      title: 'Cancellato',
      text: 'Questo utente è stato eliminato!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }  
  user_safe() {
    Swal.fire({
      title: 'Azione annullata',
      text: 'Il tuo utente è salvo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  user_link() {
    Swal.fire({
      title: 'Associato',
      text: 'Utente assegnato al cantiere con successo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  user_edit() {
    Swal.fire({
      title: 'Modificato',
      text: 'Utente modificato con successo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  user_err() {
    Swal.fire({
      title: 'Errore',
      text: 'Utente non inserito, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  user_err2() {
    Swal.fire({
      title: 'Errore',
      text: 'Utente non eliminato, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  user_err3() {
    Swal.fire({
      title: 'Errore',
      text: 'Utente non modificato, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }

  /*************************************************** */


  /************************MODEMS********************* */
  creds_warning(msg: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: 'Sei sicuro?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminale',
      cancelButtonText: 'No, conservale'
    })
  }
  creds_delete() {
    Swal.fire({
      title: 'Cancellate',
      text: 'Le credenziali sono state eliminate!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  creds_add() {
    Swal.fire({
      title: 'Aggiunte',
      text: 'Le nuove credenziali sono state create!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  creds_safe() {
    Swal.fire({
      title: 'Azione annullata',
      text: 'Le tue credenziali sono salve!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  creds_err() {
    Swal.fire({
      title: 'Errore',
      text: 'Le credenziali non sono state eliminate, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  /*************************************************** */


  /********************DOCUMENTATION****************** */
  load_warning(msg: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: 'Sei sicuro?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, inseriscilo comunque',
      cancelButtonText: 'No, inserirò un titolo'
    })
  }
  load_safe() {
    Swal.fire({
      title: 'Azione annullata',
      text: 'Niente di nuovo..',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  load_one() {
    Swal.fire({
      title: 'Attenzione',
      text: 'Puoi caricare un solo file per volta!',
      icon: 'warning',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  /*************************************************** */


  /***********************WORKSITE******************** */
  worksite_warning(msg: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: 'Sei sicuro?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, cancellato',
      cancelButtonText: 'No, conservalo'
    })
  }
  worksite_add() {
    Swal.fire({
      title: 'Aggiunto',
      text: 'Cantiere aggiunto con successo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  worksite_edit() {
    Swal.fire({
      title: 'Modificato',
      text: 'Cantiere modificato successo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  worksite_err() {
    Swal.fire({
      title: 'Errore',
      text: 'Cantiere non inserito, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  worksite_delete() {
    Swal.fire({
      title: 'Cancellato',
      text: 'Il cantiere è stato eliminato!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  worksite_safe() {
    Swal.fire({
      title: 'Azione annullata',
      text: 'Il tuo cantiere è salvo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  worksite_delerr() {
    Swal.fire({
      title: 'Errore',
      text: 'Il cantiere non è stato eliminato, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  /*************************************************** */


  /**************************3D*********************** */
  treddi_warning(msg: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: 'Sei sicuro?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminalo',
      cancelButtonText: 'No, conservalo'
    })
  }
  treddi_delete() {
    Swal.fire({
      title: 'Cancellato',
      text: 'Questo 3D è stato eliminato!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  treddi_safe() {
    Swal.fire({
      title: 'Azione annullata',
      text: 'Il tuo 3D è salvo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  treddi_err() {
    Swal.fire({
      title: 'Errore',
      text: 'Il tuo 3D non è stato eliminato, riprova!',
      icon: 'error',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  /*************************************************** */


  /***********************WARNING********************* */
  alert_warning(msg: string): Promise<SweetAlertResult> {
    return Swal.fire({
      title: 'Sei sicuro?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, disabilitato',
      cancelButtonText: 'No, conservalo'
    })
  }
  alert_delete() {
    Swal.fire({
      title: 'Disabilitato',
      text: 'Questo warning è stato disabilitato!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  alert_safe() {
    Swal.fire({
      title: 'Azione annullata',
      text: 'Il tuo warning è salvo!',
      icon: 'success',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  /*************************************************** */  


  /*************************INFO********************** */
  info1() {
    Swal.fire({
      title: 'ID dispositivo',
      text: 'Questo è l\'ID del tuo dispositivo Licord',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info2() {
    Swal.fire({
      title: 'N° progressivo',
      text: 'Questo valore viene assegnato automaticamente in modo appunto progressivo ad ogni Licord che viene aggiunto',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info3() {
    Swal.fire({
      title: 'Seriale cella',
      text: 'Questo è il numero seriale della cella',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info4() {
    Swal.fire({
      title: 'Fondo scala',
      text: 'Questo è il valore del fondo scala',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info5() {
    Swal.fire({
      title: 'Sensibilità cella',
      text: 'Questo è il valore della sensibilità della cella',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info6() {
    Swal.fire({
      title: 'Modem',
      text: 'Queste sono le credenziali del modem associato al Licord',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info7() {
    Swal.fire({
      title: 'Delta T',
      text: 'Questo è il delta_t; ha la priorità sui tempi di risveglio',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info8() {
    Swal.fire({
      title: 'Wake up 1',
      text: 'Questo è il tempo di risveglio 1',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info9() {
    Swal.fire({
      title: 'Wake up 2',
      text: 'Questo è il tempo di risveglio 2',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info10() {
    Swal.fire({
      title: 'Wake up 3',
      text: 'Questo è il tempo di risveglio 3',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info11() {
    Swal.fire({
      title: 'Data installazione',
      text: 'Questa è la data di installazione del tuo Licord',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info12() {
    Swal.fire({
      title: 'Note',
      text: 'Questo campo note opzionale può essere usato per commentare',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info13() {
    Swal.fire({
      title: 'Note',
      text: 'Deve avere la stessa unità di misura del Fondo scala!',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info14() {
    Swal.fire({
      title: 'Note',
      text: 'Timeout accensione Licord',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  info15() {
    Swal.fire({
      title: 'Note',
      text: 'Numero dei tentativi di connessione al router wifi',
      icon: 'info',
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false
    })
  }
  /*************************************************** */  

}