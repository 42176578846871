import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DropdownService } from '../../service/dropdown.service';
import { LicordService } from '../../service/licord.service';
import { WorksiteService } from '../../service/worksite.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';
import { SwalService } from '../../service/swal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modify-worksite',
  templateUrl: './modify-worksite.component.html',
  styleUrls: ['../../../theme/modal.scss']
})

export class ModifyWorksiteComponent implements OnInit, OnDestroy {  

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  public note = 'test';

  x = JSON.parse(localStorage.getItem('selected worksite') || '[]');
  idWorksite = JSON.parse(localStorage.getItem('selected worksite')!).cantieri_key

  form = this.fb.group({
    cn_indirizzo: [this.x.cn_indirizzo /* { validators: [Validators.required] }*/],
    cn_cliente: [this.x.cn_cliente /*{ validators: [Validators.required] }*/],
    cn_note: [this.x.cn_note /*{ validators: [Validators.required] }*/],
  });

  listaProvince: any;
  selectedProvCod = this.x.tab_provincia_cod;
  listaCitta: any;
  selectedCity = this.x.tab_comune_desc;
  selectedCityCod = this.x.cn_istat_cod;
  listatipologia: any;
  selectedTipo = this.x.tab_tipo_cantiere_desc;
  selectedTipoCod = this.x.cn_tipo_cantiere_cod;
  dataSource!: MatTableDataSource<any>;
  showTable = true;
  isAdmin = false;
  tab_tipo_cantiere_desc: any
  tab_comune_desc: any
  cn_indirizzo: any
  tab_provincia_cod: any
  tab_cap_cod: any
  cn_cliente: any
  flag = false;
  dataL = true

  constructor(
    private sLicord: LicordService,
    private fb: UntypedFormBuilder,
    private sDropDown: DropdownService,
    private sWorksite: WorksiteService,
    private logger: LoggerService,
    private error: ErrorService,
    private router: Router,
    private swal: SwalService
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit(): void {
    this.sWorksite.getWorksite(this.idWorksite).subscribe(res => {
      this.tab_tipo_cantiere_desc = res.data?.tab_tipo_cantiere_desc
      this.tab_comune_desc = res.data?.tab_comune_desc
      this.cn_indirizzo = res.data?.cn_indirizzo
      this.tab_provincia_cod = res.data?.tab_provincia_cod
      this.tab_cap_cod = res.data?.tab_cap_cod
      this.cn_cliente = res.data?.cn_cliente
    })
    /*metodo per chiedere dati cantiere (inserito perchè alla cancellazione di
    immangini o documenti il localstorage non viene aggiornato)*/
    this.sWorksite.getWorksite(this.x.cantieri_key).subscribe(
      (res) => {
        if(res != null) {
          this.x = res.data
        }
      }
    );
    this.sDropDown.getProvinces().subscribe(
      (res) => {
        if (res != null) {
          this.listaProvince = res.data;
        }
      },
      (error) => {
        this.error.handleError(error)
      }
    );
    this.sDropDown.getCities(this.selectedProvCod).subscribe(
      (res) => {
        if (res != null) {
          this.listaCitta = res.data;
        }
      },
      (error) => {
        this.error.handleError(error)
      }
    );
    this.sDropDown.getTypeWorksite().subscribe(
      (res) => {
        if (res != null) {
          this.listatipologia = res.data;
        }
      },
      (error) => {
        this.error.handleError(error)
      }
    );
  }

  updateProv(prov: any) {
    this.sDropDown.getCities(prov).subscribe(
      (res) => {
        if (res != null) {
          this.listaCitta = res.data;
        }
      },
      (error) => {
        this.error.handleError(error)
      }
    );
  }

  updateCity(city: any) {
    this.selectedCityCod = city;
  }

  updateType(type: any) {
    this.selectedTipoCod = type;
  }

  updateWorksite() {
    if (this.form.valid) {
      let credential = this.form.value;
      credential['cantieri_key'] = this.x.cantieri_key; 
      credential['cn_istat_cod'] = this.selectedCityCod;
      credential['cn_tipo_cantiere_cod'] = this.selectedTipoCod;
      credential['cn_attivo'] = 'S';  
      this.sWorksite.postWorksite(credential).subscribe(
        (res) => {
          if (res != null) {
            this.swal.worksite_edit();
            this.router.navigate(['/default/manageWorksite']);
          }
        },
        (error) => {
          this.error.handleError(error);
          this.swal.worksite_err();
        }
      );
    } 
    else {
      this.error.handleError('Form non valido');
    }
  }

  saveWorksite() {
    let credential = this.form.value;
    credential['cantieri_key'] = this.x.cantieri_key;
    credential['cn_istat_cod'] = this.selectedCityCod;
    credential['cn_tipo_cantiere_cod'] = this.selectedTipoCod;
    credential['cn_attivo'] = 'S'; 
    this.sWorksite.postWorksite(credential).subscribe(
      res => {
        if (res != null) {
          let idWorksite = res.data;
          this.sWorksite.getWorksite(idWorksite).subscribe(
            res => {
              if (res != null) {
                localStorage.setItem('selected worksite', JSON.stringify(res.data))
                this.swal.worksite_edit();
                this.router.navigate(['/default/manageWorksite'])
              }
            }
          )
        }
      },
      error => {
        this.error.handleError(error)
        this.swal.worksite_err()
      }
    )
  }
  

  delete() {
    let x = {
      key: JSON.parse(localStorage.getItem('selected worksite')!).cantieri_key,
    };
    this.swal.worksite_warning('Questo cantiere verrà eliminato!').then((result) => {
      if (result.value) {
        this.sWorksite.deleteWorksite(x).subscribe(
          (res) => {
            if (res != null) {
              this.router.navigate(['/default/manageWorksite']);
              this.swal.worksite_delete()
            }
          },
          (error) => {
            this.error.handleError(error);
            this.swal.worksite_delerr()
          }
        );
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) {
        this.swal.worksite_safe()
        return;
      }
    });
  }

}
