import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingService } from '../service/loading.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})

export class SpinnerComponent implements OnInit, OnDestroy {

  loading$ = this.loader.loading$;

  constructor(public loader: LoadingService) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {console.log('Component destroyed'); }

}
