import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class RoleGuard implements CanActivate  {

  constructor(private route: Router) { }

  canActivate() {
    let role = localStorage.getItem('ac_tipo_utente_cod')
    if (role == '1' || role == '2') {
      return true;
    }
    else {
      this.route.navigate(['']);
      return false;
    }    
  }
  
}
