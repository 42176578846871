<h1>Mappa cantieri in warning</h1>
<div 
    id="mappa" 
    style="
        margin-top: 10px; 
        border: 1px solid rgb(162, 162, 162); 
        border-radius: 5px; 
        margin-bottom: 1em;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)    
    "
>
    <div #map style="width: 100%; height: 450px"></div>
</div>

<br>
<app-warnings></app-warnings>