<!-- Modal Container -->
<div class="modal-container" *ngIf="isModalOpen">
    <div class="modal-content">
        <!--<button class="close-button" (click)="close()">×</button>-->
        <!-- Change Password Form -->
        <div *ngIf="!isTokenVerified && !token">
            <h2>Cambia Password -- test (da finire!)</h2>
            <form (submit)="changePassword()">
                <div class="form-group">
                    <label for="oldPassword">Password attuale</label>
                    <input id="oldPassword" type="password" [(ngModel)]="oldPassword" name="oldPassword" placeholder="inserisci la tua password attuale" required />
                </div>
                <div class="form-group">
                    <label for="newPassword">Nuova Password</label>
                    <input id="newPassword" type="password" [(ngModel)]="newPassword" name="newPassword" placeholder="inserisci la nuova password" required />
                </div>
                <div class="form-group">
                    <label for="repPassword">Conferma Password</label>
                    <input id="repPassword" type="password" [(ngModel)]="repPassword" name="repPassword" placeholder="ripeti la nuova password" required />
                </div>
                <button [disabled]="!form.valid"  type="submit">
                    <mat-icon 
                        style="
                            margin-right: 25px; 
                            transform: scale(2.3);
                            position: relative;
                            top: 2px
                        "
                    >save</mat-icon>
                    Change Password
                </button>
                <button 
                    data-submit="...Sending" 
                    (click)="close()"
                    class="btn-annulla"
                    id="contact-submitt"
                    type="submit" 
                    name="submit" 
                >
                    <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                    >clear</mat-icon>
                    <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                    >ANNULLA</span>
                </button>
            </form>
        </div>

        <!-- Token Verification -->
        <!--<div *ngIf="token && !isTokenVerified">
            <p>Token verification in progress...</p>
        </div>
        <div *ngIf="token && isTokenVerified">
            <h2>Reset Password</h2>
            <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>    
            <form (submit)="resetPassword()">
                <div class="form-group">
                    <label for="newPasswordReset">New Password</label>
                    <input id="newPasswordReset" type="password" [(ngModel)]="newPassword" name="newPasswordReset" placeholder="New Password" required />
                </div>      
                <div class="form-group">
                    <label for="confirmPasswordReset">Confirm Password</label>
                    <input id="confirmPasswordReset" type="password" [(ngModel)]="repPassword" name="confirmPasswordReset" placeholder="Confirm Password" required />
                </div>  
                <button type="submit">Reset Password</button>
            </form>
        </div>-->

        <!-- Request Password Reset Form -->
        <!--<div *ngIf="!token && !isTokenVerified">
            <h2>Request Password Reset</h2>  
            <div *ngIf="successMessage" class="success-message">{{ successMessage }}</div>
            <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
            <form (submit)="requestPasswordReset()">
                <div class="form-group">
                    <label for="username">Email</label>
                    <input id="username" type="email" [(ngModel)]="username" name="username" placeholder="Enter your email" required />
                </div>
                <button type="submit">Send Request</button>
            </form>
        </div>-->

        <!-- Password Validation -->
        <!--<div>
            <h2>Check Password</h2>
            <div class="form-group">
                <input [(ngModel)]="password" type="password" placeholder="Enter your password" />
                <button (click)="checkPassword()">Check Password</button>
            </div>
            <div *ngIf="!isValid" class="error-message">
            The password does not meet the requirements.
            </div>
        </div>-->
    </div>
</div>  