import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class LoggerService {

    logLevel: 'debug' | 'info' | 'warn' | 'error' = 'debug';

    setLogLevel(level: 'debug' | 'info' | 'warn' | 'error'): void {
        this.logLevel = level;
    }

    debug(message: string, data?: any): void {
        if (this.logLevel === 'debug') {
            console.log(`%cDEBUG - ${message}`, 'color: #1df578;', data);
        }
    }

    info(message: string, data?: any): void {
        if (['debug', 'info'].includes(this.logLevel)) {
            console.log(`%cINFO - ${message}`, 'color: #1d9af5;', data);
        }
    }

    action(message: string, data?: any): void {
        if (['debug', 'info', 'warn'].includes(this.logLevel)) {
            console.warn(`%cACTION - ${message}`, 'color: #f5e41d;', data);
        }
    }
    
    error(message: string, data?: any): void {
        console.error(`%c*ERROR* - ${message}`, 'color: #f51d2e; font-weight: bold;', data);
    }

    formatMessage(message: string, level: string): string {
        const timestamp = new Date().toISOString();
        return `[${timestamp}] ${level.toUpperCase()}: ${message}`;
    }

}