import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { LicordService } from '../../../service/licord.service';
import { WorksiteService } from '../../../service/worksite.service';
import { ErrorService } from '../../../service/error.service';
import { SwalService } from '../../../service/swal.service';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['../../../../theme/modal.scss']
})

export class PopUpComponent implements OnInit, OnDestroy {

  modemList: any;
  modemssid = "Scegli un modem"
  disableSelect = new FormControl(false);
  dispositivi_key!: string;

  public serialNumber: any;
  public progressiveNumber: any;
  public ds_data_installazione: any;
  public serialCella: any;
  public fondoScala: any;
  public sensibilityCella: any;
  public fattore_calibrazione: any;
  public tensione_eccitazione_batteria = 0;
  public area_sezione_resistente_clamp_smart = 0;
  public modulo_elastico_inox = 0;
  public wake_up_1 = "";
  public wake_up_2 = "";
  public wake_up_3 = "";
  public delta_t = 0;
  public ds_note: string ="";
  public ds_modem_key: any;
  public ds_cantiere_key: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private sLicord: LicordService, 
    private sWorksite: WorksiteService,
    private swal: SwalService,
    private error: ErrorService,
    public dialog: MatDialogRef<PopUpComponent>
  ) {
    this.dispositivi_key = data.dispositivi_key
    //messo volutamente dentro il costruttore 
    this.sLicord.getDevice(this.dispositivi_key.toString()).subscribe(res => {
      this.serialNumber = res.data!.ds_seriale;
      this.progressiveNumber = res.data!.ds_progressivo;
      this.ds_data_installazione = res.data!.ds_data_installazione
      this.serialCella = res.data!.ds_seriale_cella
      this.fondoScala = res.data!.ds_fondo_scala_cella
      this.sensibilityCella = res.data!.ds_sensibilita_cella
      this.fattore_calibrazione = res.data!.ds_fattore_calibrazione
      this.wake_up_1 = res.data!.ds_wakeup_T1
      this.wake_up_2 = res.data!.ds_wakeup_T2
      this.wake_up_3 = res.data!.ds_wakeup_T3
      this.delta_t = res.data!.ds_wakeup_delta_t
      this.ds_note = res.data!.ds_note
      this.ds_cantiere_key = res.data!.ds_cantiere_key
      this.ds_modem_key = res.data?.ds_modem_key
      this.sWorksite.getWorksite(this.ds_cantiere_key).subscribe(res => {
        this.modemList = res.data.modems
        this.modemList.forEach((element: any) => {
          if (element.key == this.ds_modem_key) {
            this.modemssid = element.ssid
          }
        });
      })
    })
  }

  ngOnDestroy(): void {console.log('Component destroyed'); }


  ngOnInit(): void { }

  aggiornaLicord() {
    let data = {
      dispositivi_key: this.dispositivi_key,
      ds_cantiere_key: this.ds_cantiere_key,
      ds_attivo: 'S',
      ds_seriale: this.serialNumber,
      ds_progressivo: this.progressiveNumber,
      ds_seriale_cella: this.serialCella,
      ds_fondo_scala_cella: this.fondoScala,
      ds_sensibilita_cella: this.sensibilityCella,
      ds_fattore_calibrazione: this.fattore_calibrazione,
      ds_note: this.ds_note,
      ds_wakeup_delta_t: this.delta_t,
      ds_wakeup_T1: this.wake_up_1,
      ds_wakeup_T2: this.wake_up_2,
      ds_wakeup_T3: this.wake_up_3,
      ds_data_installazione: this.addDays(this.ds_data_installazione, 1),
      ds_modem_key: +this.ds_modem_key
    };

    this.sLicord.saveDevice(data).subscribe(res => {
        if (res.success) {         
          this.swal.licord_edit(); 
          window.location.reload();
        }
      },
      (error) => {
        this.error.handleError(error)
      }
    );
  }

  close() {
    this.dialog.close();
  }

  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

}

