import { Component, OnInit , OnDestroy} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AccountsService } from '../../service/accounts.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';
import { SwalService } from '../../service/swal.service';
import { PasswordComponent } from '../password/password.component';

@Component({
  selector: 'app-modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['../../../theme/modal.scss']
})

export class ModifyUserComponent implements OnInit, OnDestroy {

  x = JSON.parse(localStorage.getItem('selected account') || '[]')

  form = this.fb.group({
    ac_nome: [this.x.ac_nome, { validators: [Validators.required] }],
    ac_cognome: [this.x.ac_cognome, { validators: [Validators.required] }],
    ac_email: [this.x.ac_email, { validators: [Validators.required] }],
    ac_telefono: [this.x.ac_telefono, { validators: [Validators.required] }],
    ac_cellulare: [this.x.ac_cellulare, { validators: [Validators.required] }],
    ac_societa: [this.x.ac_societa, { validators: [Validators.required] }],
    ac_note: [this.x.ac_note, { validators: [Validators.required] }],
    /*ac_password: [this.x.ac_password, { validators: [Validators.required] }],
    ac_rep_password: [this.x.ac_rep_password, { validators: [Validators.required] }]*/
  })

  listatipologia = [
    {
      value: 1,
      tipo: 'Admin'
    }, {
      value: 3,
      tipo: 'Responsabile'
    }, {
      value: 2,
      tipo: 'Installatore'
    }
  ]

  selectedTipo = this.x.ac_tipo_utente_cod;
  user!: any;

  constructor(
    private fb: UntypedFormBuilder, 
    private sAccount: AccountsService, 
    private router: Router,
    private dialog: MatDialogRef<ModifyUserComponent>,
    private dialogRef: MatDialog,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit(): void { }

  updateType(type: any) {
    this.selectedTipo = type
  }

  Modifica() {
    let credential = this.form.value
    credential["account_key"] = this.x.account_key
    credential["ac_tipo_utente_cod"] = this.selectedTipo
    credential["ac_data_scadenza"] = "2023-06-22T14:20:20.268Z";
    credential["ac_attivo"] = "S"
    this.sAccount.save_account(credential).subscribe(
      res => {
        if (res != null) {
          this.swal.user_edit();
          window.location.reload();
        }
      },
      error => {
        this.error.handleError(error);
        this.swal.user_err3()
      }
    )
  }

  openDialog(id: any) {
    this.dialogRef.open(PasswordComponent, {
      data:{
        username: id
      }
    });
  }

  close() {
    this.dialog.close();
    this.router.navigate(['/default/manageAccounts'])
  }

}
