<div class="containerInserimentoLicord">
    <form 
        id="contact" 
        [formGroup]="form" 
        style="margin-bottom: 8px; width: 100%"
    >
        <h3 style="text-align:center">Inserimento Cantiere</h3>
        <h4 style="text-align:center">Aggiungi un nuovo cantiere</h4>
        <div class="containerFormLicord">
            <div>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Provincia</mat-label>
                        <mat-select (selectionChange)="updateProv($event.value)" autofocus>
                            <mat-option 
                                *ngFor="let i of listaProvince" 
                                [value]="i.tab_provincia_cod"
                            >{{ i.tab_provincia_desc }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>        
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Tipo Cantiere</mat-label>
                        <mat-select (selectionChange)="updateType($event.value)" autofocus>
                            <mat-option 
                                *ngFor="let i of listatipologia" 
                                [value]="i.tab_tipo_cantiere_cod"
                            >{{ i.tab_tipo_cantiere_desc }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Cliente</mat-label>
                        <input formControlName="cn_cliente" matInput>
                    </mat-form-field>
                </p>        
            </div>
            <div>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Città</mat-label>
                        <mat-select (selectionChange)="updateCity($event.value)" autofocus>
                            <mat-option 
                                *ngFor="let i of listaCitta" 
                                [value]="i.tab_istat_cod"
                            >{{ i.tab_comune_desc }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Indirizzo</mat-label>
                        <input formControlName="cn_indirizzo" matInput>
                    </mat-form-field>
                </p>                                    
                
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Note</mat-label>
                        <input formControlName="cn_note" matInput>
                    </mat-form-field>
                </p>
            </div>
        </div>   
        <fieldset
            style="
                margin-top: 20px;
                margin-bottom: -40px
            "
        >
            <button 
                [disabled]="!form.valid" 
                (click)="saveWorksite()" 
                data-submit="...Sending"
                id="contact-submit"
                type="submit" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >cloud_upload</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >AGGIUNGI</span>
            </button>
            <button 
                data-submit="...Sending" 
                (click)="close()"
                class="btn-annulla"
                id="contact-submitt"
                type="submit" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >clear</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >ANNULLA</span>
            </button>
        </fieldset>
    </form>
</div>