import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { LoadingService } from './loading.service';
import { ErrorService } from './error.service';
import { LoggerService } from './logger.service';
import{ finalize } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})

export class TokenInterceptorService implements HttpInterceptor {

  constructor(
    private inject: Injector, 
    private loader: LoadingService,
    private error: ErrorService,
    private logger: LoggerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
    this.loader.show()
    let authService = this.inject.get(AuthService)
    let authreq = this.AddTokenheader(req, authService.GetToken());    
    return next.handle(authreq).pipe(
      finalize(() => {        
        this.loader.hide();
      }),
      catchError(errordata => {
        if(errordata.status === 401) {
          //need to implement logout
          authService.Logout();
          //refresh token logic
        }
        this.error.handleError(errordata);
        return throwError(errordata)
      })
    );
  }

  AddTokenheader(req: HttpRequest<any>, token: any){
    return req.clone({headers:req.headers.set('Authorization', 'Bearer ' + token)})
  }

}
