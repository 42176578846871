import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PopUpComponent } from './pop-up/pop-up.component';
import { RemoteComponent } from './remote/remote.component';
import { PopsComponent } from './pops/pops.component';
import { NewLicordComponent } from './new-licord/new-licord.component';
import { LicordService } from '../../service/licord.service';
import { WorksiteService } from '../../service/worksite.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';
import { SwalService } from '../../service/swal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-licord',
  templateUrl: './licord.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class LicordComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  idWorksite = JSON.parse(localStorage.getItem('selected worksite')!).cantieri_key;
  x = JSON.parse(localStorage.getItem('selected worksite') || '[]')
  y = JSON.parse(localStorage.getItem('selected device') || '[]')


  showTable = true;
  isAdmin = false;
  isEditable: boolean = false;
  editedRow: any
  dataSource!: MatTableDataSource<any>;
  licord: any;
  columns: string[] = [    
    'serialNumber',
    'token', 
    'progressiveNumber', 
    'dataInstallazione', 
    'dataRevisione',   
    'dati',
    'configurazione',
    'actions', 
    'delete'
  ];  

  constructor(
    private sLicord: LicordService, 
    private sWorksite: WorksiteService,
    private dialogRef: MatDialog,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit(): void {
    if (localStorage.getItem('ac_tipo_utente_cod') == '1') {
      this.isAdmin = true;
    }
    this.sLicord.getDevices(this.x.cantieri_key).subscribe(res => {      
        if (res.error_message!.msg_code === 8) {
          this.showTable = false
        } 
        else {
          this.licord = res.data
          this.dataSource = new MatTableDataSource(this.licord);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator          
        }
    },
    (error) => {
        this.error.handleError(error)
    });
    this.sWorksite.getWorksite(this.idWorksite.ds_cantiere_key).subscribe(res => {
      if (res != null) {
        localStorage.setItem("selected worksite", JSON.stringify(res.data));
      }
    })   
  }

  edit(row: any) {
    row.isEditable = true
    row = this.editedRow
    this.editedRow = {...row}
  }

  save(row: any) {
    row.isEditable = false
    row = this.editedRow
    this.editedRow = null
  }

  openDialog(id: any) {
    this.dialogRef.open(PopUpComponent, {
      data:{
        dispositivi_key: id
      }
    });
  }

  openDialog2(id: any) {
    this.dialogRef.open(NewLicordComponent, {
      data: {
        dispositivi_key: id
      }
    })
  } 

  openDialog3(id: any) {
    this.dialogRef.open(PopsComponent, {
      data: {
        dispositivi_key: id
      }
    })
  } 

  openDialog4(id: any) {
    this.dialogRef.open(RemoteComponent, {
      data: {
        dispositivi_key: id
      }
    })
  } 

  saveDataLicord(data: any) {
    localStorage.setItem('selected device', JSON.stringify(data))
  }

  goCharts(data: any) {
    localStorage.setItem('dispositivi_key', data.dispositivi_key)
    this.router.navigate(['/default/dashboard'])
  }

  deleteLicord(dispositivi_key: any) {
    let body = {
      "dispositivi_key": dispositivi_key
    }
    this.swal.licord_warning('Questo licord verrà eliminato!').then((result) => {
      if (result.value) {
        this.sLicord.deleteDevice(body).subscribe(res => {
          if (res != null) {
            this.ngOnInit();
            this.swal.licord_delete()
          }
        }, 
        (error) => {
            this.error.handleError(error)
            this.swal.licord_err2();
        })
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) {
        this.swal.licord_safe()
        return
      }
    })
  }  

  calcolaDataRevisione(ds_data_installazione: Date): Date {
    const dataInstallazione = new Date(ds_data_installazione);
    dataInstallazione.setFullYear(dataInstallazione.getFullYear() + 2);
    return dataInstallazione;
  }

  isScaduto(ds_data_installazione: Date): boolean {
    const dataRevisione = this.calcolaDataRevisione(ds_data_installazione);
    const oggi = new Date();
    return oggi > dataRevisione;
  }  

  calcolaProgresso(ds_data_installazione: Date): number {
    const dataAttuale = new Date();
    const dataInstallazione = new Date(ds_data_installazione);
    const diffInMesi = (dataAttuale.getFullYear() - dataInstallazione.getFullYear()) * 12 + (dataAttuale.getMonth() - dataInstallazione.getMonth());
    const progresso = (diffInMesi / 24) * 100;
    return Math.max(0, Math.min(100, progresso));
  }

  isMonthComplete(ds_data_installazione: Date): boolean {
    const dataInstallazione = new Date(ds_data_installazione);
    const dataAttuale = new Date();
    const mesiTrascorsi = (dataAttuale.getFullYear() - dataInstallazione.getFullYear()) * 12 + dataAttuale.getMonth() - dataInstallazione.getMonth();
    const scadenza = new Date(dataInstallazione.getFullYear() + 2, dataInstallazione.getMonth(), dataInstallazione.getDate());
    const dataLimite = new Date(scadenza.getTime() + (24 * 60 * 60 * 1000));
    return mesiTrascorsi <= 24 || dataAttuale.getTime() <= dataLimite.getTime();
  }

  calcolaGiorniMancanti(ds_data_installazione: Date): number {
    const dataRevisione = this.calcolaDataRevisione(ds_data_installazione);
    const oggi = new Date();
    const giorniMancanti = Math.ceil((dataRevisione.getTime() - oggi.getTime()) / (1000 * 3600 * 24));
    return giorniMancanti > 0 ? giorniMancanti : 0;
  }

}
