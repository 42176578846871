import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { User } from '../models/user.model';
import { ChangePasswordInApp, IForgottenPassword } from '../models/password.model';
import { ErrorService } from './error.service';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class AccountsService {

  qapiUrl = "https://licord2024.connecta.ovh/api/services/"
  apiUrl = `${environment.apiUrl}api/services/`
  
  constructor(
    private http: HttpClient,
    private error: ErrorService,
    private logger: LoggerService
  ) { }

  get_accounts(string?: string) {
    if(string != null) {
      let headers = new HttpHeaders().set('Search', string)
      return this.http.get<User>(this.apiUrl + "get_accounts", {'headers': headers}).pipe(
        catchError(this.error.handleError)
      )
    }
    return this.http.get<User>(this.apiUrl + "get_accounts").pipe(
      catchError(this.error.handleError)
    )
  }

  get_account(string: string, account_id: number) {
    return this.http.get<User>(this.apiUrl + `get_account/${account_id}`)
    .pipe(
      catchError(this.error.handleError)
    )
  }

  save_account(data: any) {
    return this.http.post(this.apiUrl + "save_account", data)
    .pipe(
      tap(response => {
        this.logger.debug('Account successfully saved!')
      }),
      catchError(this.error.handleError)
    )
  }

  delete_account(id: any) {
    return this.http.post(this.apiUrl + "del_account", id)
    .pipe(
      tap(response => {
        this.logger.debug('Account successfully deleted!')
      }),
      catchError(this.error.handleError)
    )
  }

  //Connect users to rispective worksite
  save_worksite_account(data: any) {
    return this.http.post(this.apiUrl + "save_worksite_account", data)
    .pipe(
      tap(response => {
        this.logger.debug('Account successfully linked to the worksite!')
      }),
      catchError(this.error.handleError)
    )
  }

  delete_worksite_account(ids: any) {
    return this.http.post(this.apiUrl + "del_worksite_account", ids)
    .pipe(
      tap(response => {
        this.logger.debug('Account successfully removed from the worksite!')
      }),
      catchError(this.error.handleError)
    )
  }

  change_forgot_password(object: IForgottenPassword) {
    return this.http.post<any>(this.apiUrl + 'change_forgot_password', object)
      .pipe(catchError(this.error.handleError));
  }

  change_password_in_app(object: ChangePasswordInApp) {
    return this.http.post<any>(this.apiUrl + 'put_password', object)
      .pipe(catchError(this.error.handleError));
  }

}
