import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LicordService } from '../../service/licord.service';
import { WorksiteService } from '../../service/worksite.service';
import { LoggerService } from '../../service/logger.service';
import { SwalService } from '../../service/swal.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-warnings',
  templateUrl: './warnings.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class WarningsComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  dataL = true
  filter = localStorage.getItem('filtro');
  dataSource!: MatTableDataSource<any>;
  devices: any;
  value: string | null = null;
  columns: string[] = [
    'serialnumber', 
    'cliente', 
    'dataInstallazione', 
    'dataRevisione',
    'tab_comune_desc',
    'cn_indirizzo', 
    'warning', 
    'delete'
  ];

  constructor(
    private sLicord: LicordService, 
    private router: Router, 
    private sWorksite: WorksiteService,
    private logger: LoggerService,
    private swal: SwalService
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit(): void {
    if (this.filter == null) {
      localStorage.setItem('filtro', '0')
      this.filter = '0'
    }
    if(this.value){
      this.sLicord.getAllWarningDevices(this.value!).subscribe(res => {
        if (res != null) {
          if (res.error_message!.msg_code === 8) {
            this.dataL = false
          } 
          else {
            this.dataL = true
            this.devices = res.data
            this.logger.info('Devices: ', this.devices)
            this.dataSource = new MatTableDataSource(this.devices)
            this.dataSource.paginator = this.paginator;
          }
        }
      })
    }    
  }

  disableW(warning_key: any) {
    let data = {
      warning_key: warning_key
    }
    this.swal.alert_warning('Questo warning verrà disabilitato!').then((result) => {
      if (result.value) {
        this.sLicord.closeWarning(data).subscribe(res => {
          if (res != null) {
            this.logger.debug(res)
            this.ngOnInit()
            this.swal.alert_delete()
          }
        })
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) {
        this.swal.alert_safe()
        return
      }
    })
  }

  saveDataWorksite(data: any) {
    this.sWorksite.getWorksite(data.ds_cantiere_key).subscribe(res => {
      if (res != null) {
        localStorage.setItem("selected worksite", JSON.stringify(res.data));
        this.router.navigate(['/default/licordList'])
      }
    })
  }

  filtra(value: string | null) {
    if (value != "" || value != null) {
      localStorage.setItem('filtro', '1')
      this.filter = '1'
    } 
    else {
      localStorage.setItem('filtro', '0')
    }
    this.value = value
    this.ngOnInit()
  }

  calcolaDataRevisione(ds_data_installazione: Date): Date {
    const dataInstallazione = new Date(ds_data_installazione);
    dataInstallazione.setFullYear(dataInstallazione.getFullYear() + 2);
    return dataInstallazione;
  }

  isScaduto(ds_data_installazione: Date): boolean {
    const dataRevisione = this.calcolaDataRevisione(ds_data_installazione);
    const oggi = new Date();
    return oggi > dataRevisione;
  }  

  calcolaProgresso(ds_data_installazione: Date): number {
    const dataAttuale = new Date();
    const dataInstallazione = new Date(ds_data_installazione);
    const diffInMesi = (dataAttuale.getFullYear() - dataInstallazione.getFullYear()) * 12 + (dataAttuale.getMonth() - dataInstallazione.getMonth());
    const progresso = (diffInMesi / 24) * 100;
    return Math.max(0, Math.min(100, progresso));
  }

  isMonthComplete(ds_data_installazione: Date): boolean {
    const dataInstallazione = new Date(ds_data_installazione);
    const dataAttuale = new Date();
    const mesiTrascorsi = (dataAttuale.getFullYear() - dataInstallazione.getFullYear()) * 12 + dataAttuale.getMonth() - dataInstallazione.getMonth();
    const scadenza = new Date(dataInstallazione.getFullYear() + 2, dataInstallazione.getMonth(), dataInstallazione.getDate());
    const dataLimite = new Date(scadenza.getTime() + (24 * 60 * 60 * 1000));
    return mesiTrascorsi <= 24 || dataAttuale.getTime() <= dataLimite.getTime();
  }

  calcolaGiorniMancanti(ds_data_installazione: Date): number {
    const dataRevisione = this.calcolaDataRevisione(ds_data_installazione);
    const oggi = new Date();
    const giorniMancanti = Math.ceil((dataRevisione.getTime() - oggi.getTime()) / (1000 * 3600 * 24));
    return giorniMancanti > 0 ? giorniMancanti : 0;
  }

}
