<div class="containerInserimentoLicord">
    <form 
        [formGroup]="form" 
        id="contact" 
        style="
            margin-bottom: 8px; 
            width: 100%
        "
    >
        <h3 style="text-align:center">Inserimento 3D</h3> 
        <h4 style="text-align:center">Aggiungi una nuovo modello 3D</h4>  
        <p 
            [hidden]="!x._3d_map" 
            style="
                display: flex;
                flex-direction: row;
                align-content: center;
                justify-content: center;
                flex-wrap: wrap
            "
        >{{ x._3d_map.substring(x._3d_map.lastIndexOf("/") + 1) }} 
            <mat-icon>done</mat-icon>
        </p>
        <p 
            [hidden]="x._3d_map" 
            style="
                display: flex;
                flex-direction: row;
                align-content: center;
                justify-content: center;
                flex-wrap: wrap
            "
        >Non presente <mat-icon>clear</mat-icon>
        </p>    
        <div class="containerFormLicord">
            <div 
                style="
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center
                "
            >
                <p style="margin-right:10px">
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Titolo 3D</mat-label>
                        <input [(ngModel)]="titleImg" matInput>
                    </mat-form-field>
                </p>
                <fieldset
                    style="
                        margin-top: 20px;
                        margin-bottom: -40px
                    "
                >
                    <button                 
                        (click)="Input3D.click()"
                        class="btn-add" 
                        id="contact-submit"
                        type="submit" 
                        name="submit" 
                        data-submit="...Sending" 
                    >
                        <mat-icon 
                            style="
                                margin-right: 25px; 
                                transform: scale(2.3);
                                position: relative;
                                top: 2px
                            "
                        >add_photo_alternate</mat-icon>
                        <span 
                            style="
                                position: relative; 
                                top: -3px
                            "
                        >CARICA 3D</span>
                        <input 
                            [(ngModel)]="title3D"
                            type="file" 
                            #Input3D
                            (change)="onFileInput(Input3D.files, title3D!,1)"
                            style="display: none"
                            accept=".glb" 
                        />
                    </button>
                    <button 
                        class="btn-delete" 
                        (click)="delete3D()" 
                        id="contact-submitt"
                        type="submit" 
                        name="submit" 
                        data-submit="...Sending"
                    >
                        <mat-icon 
                            style="
                                margin-right: 25px; 
                                transform: scale(2.3);
                                position: relative;
                                top: 2px
                            "
                        >delete</mat-icon>
                        <span 
                            style="
                                position: relative; 
                                top: -3px
                            "
                        >CANCELLA 3D</span>
                    </button>
                    <button 
                        data-submit="...Sending" 
                        (click)="close()"
                        class="btn-annulla"
                        id="contact-submittt"
                        type="submit" 
                        name="submit" 
                    >
                        <mat-icon 
                            style="
                                margin-right: 25px; 
                                transform: scale(2.3);
                                position: relative;
                                top: 2px
                            "
                        >clear</mat-icon>
                        <span 
                            style="
                                position: relative; 
                                top: -3px
                            "
                        >ANNULLA</span>
                    </button>
                </fieldset>
            </div>
        </div>
    </form>
</div>