import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Provincia } from '../models/provincia.model';
import { Città } from '../models/citta.model';
import { TypeWorksite } from '../models/typeWorksite.model';
import { ErrorService } from './error.service';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class DropdownService {

  qapiUrl = "https://licord2024.connecta.ovh/api/services/"  
  apiUrl = `${environment.apiUrl}api/services/`  

  constructor(
    private http: HttpClient,
    private error: ErrorService,
    private logger: LoggerService
  ) { }

  getProvinces() {
    return this.http.get<Provincia>(this.apiUrl + "get_provinces")
    .pipe(
      tap(response => {
        this.logger.debug('Successfully loaded provinces!')
      }),
      catchError(this.error.handleError)
    )
  }

  getCities(provincia: string) {
    return this.http.get<Città>(this.apiUrl + "get_cities/" + provincia)
    .pipe(
      tap(response => {
        this.logger.debug('Successfully loaded cities!')
      }),
      catchError(this.error.handleError)
    )
  }

  getCoordinates(provincia: string, city: string, address: string): Observable<any> {
    // Implementa qui la logica per ottenere le coordinate in base alla provincia, città e indirizzo
    // Esempio: utilizzare un servizio di geocoding come Google Maps API, Here Maps API, etc.
    // Esempio di implementazione fittizia:
    const encodedAddress = encodeURIComponent(address);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&components=administrative_area:${provincia}|locality:${city}&key=AIzaSyCZGeSBJXtGji28P1B6sjOA7oRnaYZl0LM`;

    return this.http.get(url)
      .pipe(
        tap(response => {
          this.logger.debug('Successfully retrieved coordinates!');
        }),
        catchError(this.error.handleError)
      );
  }

  getTypeWorksite() {
    return this.http.get<TypeWorksite>(this.apiUrl + "get_types_worksite")
    .pipe(
      tap(response => {
        this.logger.debug('Successfully loaded worksites!')
      }),
      catchError(this.error.handleError)
    )
  }

  getTypeDocument() {
    return this.http.get(this.apiUrl + "get_types_document")
    .pipe(
      tap(response => {
        this.logger.debug('Successfully loaded documents!')
      }),
      catchError(this.error.handleError)
    )
  }

}
