<div style="display:flex; flex-direction:row">

    <mat-card 
        class="lastValueee" 
        style="
            width: 100%; 
            background-color: #580505; 
            color: white;
            cursor: default
        "
    >
        <mat-card-title>DATI</mat-card-title>
        <mat-card-content>
            TIPO CANTIERE: <strong style="font-size:18px; font-weight:bold">{{ tab_tipo_cantiere_desc }}</strong> <br>
            INDIRIZZO: <strong style="font-size:18px; font-weight:bold"> {{ cn_indirizzo }}, {{ tab_comune_desc }} {{ tab_cap_cod }} {{ tab_provincia_cod }} </strong> <br>
            PROGRESSIVO: <strong style="font-size:18px; font-weight:bold">{{ licord?.ds_progressivo }}</strong> <br>
            ID: <strong style="font-size:18px; font-weight:bold">{{ licord?.ds_seriale }}</strong> <br>
            DATA INSTALLAZIONE: <strong style="font-size:18px; font-weight:bold">{{ ds_data_installazione | date: 'dd/MM/yy' }}</strong><br>
            DATA E ORA ULTIMO RILEVAMENTO: <strong style="font-size:18px; font-weight:bold">{{ a2 | date:'dd/MM/yy HH:mm:ss' }}</strong>
        </mat-card-content>
    </mat-card>

    <mat-card 
        class="lastValue" 
        id="batteryy" 
        style="
            background-color: #14358b; 
            color: white;
            cursor: auto
        "
    >
        <mat-card-title 
            style="
                text-align: center;
                margin-bottom: 56px
            ">BATTERIA</mat-card-title><br>
        <mat-card-content>
            <div 
                style="
                    display: flex; 
                    flex-direction: column;
                    justify-content: space-between; 
                    align-items: left
                "
            >       
            <mat-card-content 
                style="
                    display: flex; 
                    flex-direction: row;
                    justify-content: center
                "
            >
                <div 
                    style="
                        height: 50px;
                        margin-top: -48px;
                        margin-bottom: 10px
                    "
                >
                    <mat-icon 
                        *ngIf="percentuale0" 
                        [ngStyle]="{ 'color': getBatteryColor(percentuale0) }"
                        style="
                            font-size: 50px; 
                            width: 50px; 
                            height: 50px
                        "
                    >battery_full</mat-icon>
                </div>
            </mat-card-content> 
            <mat-card-content 
                style="
                    text-align: center; 
                    margin-top: -15px;
                    margin-bottom: 30px
                "
            >
                <strong style="font-size:18px">{{ percentuale0 }}</strong> %
            </mat-card-content>      
                <mat-card-content style="text-align: center">
                    Ultimo valore registrato:<br>
                    <strong style="font-size:18px">{{ r1 | number:'1.2-2' }}</strong> mV
                </mat-card-content>          
            </div> 
        </mat-card-content>  
    </mat-card> 

    <mat-card 
        class="lastValue" 
        id="forza1" 
        style="
            background-color: #d7c233; 
            color: white;
            cursor: default
        "
    >
        <mat-card-title 
            style="
                text-align: center;
                margin-bottom: 125px
            "
        >FORZA
        </mat-card-title><br>
        <mat-card-content style="text-align: center">
            Ultimo valore registrato:<br>
            <strong style="font-size:18px">{{ a1 | number:'1.2-2' }}</strong> kg
        </mat-card-content>
    </mat-card>  
    <mat-card 
        class="lastValue" 
        id="temp2" 
        (click)="Vtemp2()" 
        style="
            background-color: #e23131; 
            color: white
        "
    >
        <mat-card-title
            style="
                text-align: center;
                margin-bottom: 114px
            "
        >TEMPERATURA SONDA
        </mat-card-title>
        <mat-card-content style="text-align: center">
                Ultimo valore registrato:<br>
            <strong style="font-size:18px">{{ e1 | number:'1.2-2' }}</strong> °C 
        </mat-card-content>
    </mat-card>  
    <mat-card 
        class="lastValue" 
        id="temp" 
        (click)="Vtemp()" 
        style="
            background-color: #39b963; 
            color: white
        "
    >
        <mat-card-title
            style="
                text-align: center;
                margin-bottom: 114px
            "
        >TEMPERATURA AMBIENTALE
        </mat-card-title>
        <mat-card-content style="text-align: center">
                Ultimo valore registrato:<br>
            <strong style="font-size:18px">{{ d1 | number:'1.2-2' }}</strong> °C 
        </mat-card-content>
    </mat-card>   
</div>
<br>

<!-- ATTENZIONE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->
<!-- SE DEVI CANCELLARE UN GRAFICO RIMUOVI ANCHE IL RELATIVO CREATECHART DA NGONINIT() 
    ALTRIMENTI NON VISUALIZZA NESSUN DATO NELLA PAGINA!!!!!!!!!!!!-->
<!-- IN FASE DI TEST METTI SOLO DISPLAY:NONE-->
<mat-card>
    <mat-card-title>GRAFICO FORZA</mat-card-title>
    <div id="forza"></div>
</mat-card>
<br>
<mat-card>
    <mat-card-title>GRAFICO TEMPERATURE</mat-card-title>
    <div id="temperature"></div>
</mat-card>
<br>
<mat-card>
    <mat-card-title>GRAFICO BATTERIA</mat-card-title>
    <div id="batt2"></div>
</mat-card>