import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountsService } from '../../../service/accounts.service';
import { DropdownService } from '../../../service/dropdown.service';
import { WorksiteService } from '../../../service/worksite.service';
import { LoggerService } from '../../../service/logger.service';
import { ErrorService } from '../../../service/error.service';
import { SwalService } from '../../../service/swal.service';

@Component({
  selector: 'app-add-tech',
  templateUrl: './add-tech.component.html',
  styleUrls: ['../../../../theme/modal.scss']
})

export class AddTechComponent implements OnInit, OnDestroy {  

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  x = JSON.parse(localStorage.getItem('selected worksite') || '[]');
  idWorksite = JSON.parse(localStorage.getItem('selected worksite')!).cantieri_key

  form = this.fb.group({
    cn_indirizzo: [this.x.cn_indirizzo /* { validators: [Validators.required] }*/],
    cn_cliente: [this.x.cn_cliente /*{ validators: [Validators.required] }*/],
    cn_note: [this.x.cn_note /*{ validators: [Validators.required] }*/],
  });

  addForm = this.fb.group({
    cn_email: [this.x.cn_indirizzo /* { validators: [Validators.required] }*/],
  });

  file: File | null = null;
  img: File | null = null;
  listatipologia: any;
  selectedTipoCod = this.x.cn_tipo_cantiere_cod;
  dataSource!: MatTableDataSource<any>;
  licord: any;
  accounts: any;
  account_key: any;
  showTable = true;
  isAdmin = false;
  tab_tipo_cantiere_desc: any
  cn_cliente: any
  flag = false;
  dataL = true
  passwordType = "password"
  responsabile: string = ""
  installatore: string = ""
  modems!: Array<any>

  constructor(
    private fb: UntypedFormBuilder,
    private sDropDown: DropdownService,
    private sUser: AccountsService,
    private sWorksite: WorksiteService,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService,
    public dialog: MatDialogRef<AddTechComponent>
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit(): void {
    this.sWorksite.getWorksite(this.idWorksite).subscribe(res => {
      this.tab_tipo_cantiere_desc = res.data?.tab_tipo_cantiere_desc
      this.cn_cliente = res.data?.cn_cliente
      this.modems = res.data.modems
    });
    this.sWorksite.getWorksite(this.x.cantieri_key).subscribe(
      (res) => {
        if(res != null) {
          this.x = res.data
        }
      }
    );
    this.sDropDown.getTypeWorksite().subscribe(
      (res) => {
        if (res != null) {
          this.listatipologia = res.data;
        }
      },
      (error) => {
        this.error.handleError(error)
      }
    );
    this.sUser.get_accounts().subscribe((res) => {
      if (res != null) {
        this.accounts = res.data;
      }
    });   
  }

  updateType(type: any) {
    this.selectedTipoCod = type;
  }

  updateAccountkey(accountkey: any) {
    this.account_key = accountkey;
  }

  assignAccount() {
    let data = {
      cantieri_key: this.x.cantieri_key,
      account_key: this.account_key,
      rel_installatore: '',
    };
    this.sUser.save_worksite_account(data).subscribe(
      (res) => {
        if (res != null) {
          this.swal.user_link()
          window.location.reload();
        }
      },
      (error) => {
        this.error.handleError(error);
          this.swal.user_err()
      }
    );
  } 

  close() {
    this.dialog.close();
  }

}
