<div class="containerInserimentoLicord">
    <form id="contact" style="margin-bottom: 8px; width: 100%">
        <h3 style="text-align:center">Inserimento Credenziali Modem Wifi</h3>   
        <h4 style="text-align:center">Aggiungi nuove credenziali</h4> 
        <div class="containerFormLicord">
            <div 
                style="
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center
                "
            >
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>SSID</mat-label>
                        <input [(ngModel)]="ssid" matInput>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Password</mat-label>
                        <input [(ngModel)]="pw" matInput>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <fieldset
            style="
                margin-top: 20px;
                margin-bottom: -40px
            "
        >
            <button 
                class="btn-add" 
                data-submit="...Sending" 
                (click)="addCredentials()"
                id="contact-submit"
                name="submit" 
                type="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >cloud_upload</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >AGGIUNGI</span>
                <input 
                    type="file" 
                    #documentInput
                    (change)="onDocumentInput(documentInput.files, titleFile!, selectedTipoDocument)" 
                    style="display: none"
                    accept=".pdf, .csv, .txt, .docx, .dwg" 
                />
            </button>
            <button 
                data-submit="...Sending" 
                (click)="close()"
                class="btn-annulla"
                id="contact-submitt"
                type="submit" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >clear</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >ANNULLA</span>
            </button>
        </fieldset>
    </form>
</div>
