import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pops',
  templateUrl: './pops.component.html',
  styleUrls: ['../../../../theme/modal.scss']
})

export class PopsComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private router: Router,
    public dialog: MatDialogRef<PopsComponent>
  ) { }

  ngOnDestroy(): void {console.log('Component destroyed'); }


  ngOnInit(): void {
    setTimeout(() => {
      this.goCharts(this.data)
      this.close()
    }, 0);
  }

  goCharts(data: any) {
    localStorage.setItem('dispositivi_key', data.dispositivi_key)
    this.router.navigate(['/default/dashboard'])
  }

  close() {
    this.dialog.close();
  }

}

