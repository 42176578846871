import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ResetPw } from '../models/resetPw.model';
import { LoggerService } from './logger.service';
import { SwalService } from './swal.service';
import { environment } from '../../environments/environment.prod';
import { ConfigurationService } from './configuration.service';


@Injectable({
  providedIn: 'root'
})

export class AuthService {

  qapiurlLogin = 'https://licord2024.connecta.ovh/api/services/login';
  qapiurlSendMail = 'https://licord2024.connecta.ovh/api/services/new_password_request?username=';
  qapiurlVerifyToken = 'https://licord2024.connecta.ovh/api/services/reset_password_?t=';
  qapiurlResetPw = 'https://licord2024.connecta.ovh/api/services/reset_password?t=';

  apiurlLogin = `${environment.apiUrl}api/services/login`;
  apiurlSendMail = `${environment.apiUrl}api/services/new_password_request?username=`;
  apiurlVerifyToken = `${environment.apiUrl}api/services/reset_password_?t=`;
  apiurlResetPw = `${environment.apiUrl}api/services/reset_password?t=`;
  apiurl = `https://app.licord.it/api/services/new_password_request?username=`

  headerDict = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Headers': 'Content-Type',
  }

  requestOptions = {                                                                                                                                                                                 
    headers: new HttpHeaders(this.headerDict), 
  };

  tokenresp: any

  constructor(
    private http: HttpClient, 
    private router: Router,
    private logger: LoggerService,
    private swal: SwalService,
    private configurationService: ConfigurationService,
  ) { }

  proceedLogin(usercred: any): Observable<any> {
    return this.http.post(this.qapiurlLogin, usercred, this.requestOptions)
      .pipe(
        tap(response => {
          this.logger.debug('Successfully logged in!')
        }),
        catchError(error => {
          this.logger.error('Error while loggin: ', error);
          throw error
        })
      );
  }

  sendMail(mail:any){   
    return this.http.post<ResetPw>(this.apiurlSendMail + mail, null)
  }

  verifyToken(token: string) {
    return this.http.post<ResetPw>(this.apiurlVerifyToken + token, null);
  }

  /*resetPw(token: string, data: any) {
    return this.http.post<ResetPw>(this.apiurlResetPw + token, data);
  }*/

  sendMailzz(email: string) {
    const payload = { username: email };
    this.logger.debug('Endpoint is: ', this.apiurlSendMail);
    this.logger.info('Sending email reset request with payload: ', payload);
    return this.http.post<any>(this.apiurlSendMail + email, this.requestOptions)
    .pipe(
      tap((response) => {
        this.logger.info('Response from password reset request: ', response);
      }),
      catchError((error) => {
        this.logger.error('Error during password reset request: ', error);
        return throwError(error);
      })
    );
  }  
  
  /*verifyToken(token: string) {
    this.logger.action('Try to verify the token', token); 
    return this.http.post<ResetPw>(this.apiurlVerifyToken + token, null)
      .pipe(
        tap(response => {
          this.logger.debug('Successfully verified the token', response); 
        }),
        catchError(error => {
          this.logger.error('Error while verifying the token: ', error);
          throw error
        })
      );
  }*/

  resetPw(token: string, data: any) {
    this.logger.action('Try to reset the password for ', token + data); 
    return this.http.post<ResetPw>(this.apiurlResetPw + token, data)
      .pipe(
        tap(response => {
          this.logger.debug('Password reset with success for token');
        }),
        catchError(error => {
          this.logger.error('Error while resetting the password: ', error);
          throw error
        })
      );
  }

  //Auth method
  IsLoggedIn() {
    try {
      const isLoggedIn = localStorage.getItem('token') !== null;
      this.logger.debug('Successfully logged in');
      return isLoggedIn;
    } 
    catch (error) {
      this.logger.error('Error while checking login status: ', error);
      throw error;
    }
  }
  
  GetToken() {
    try {
      const token = localStorage.getItem('token') || '';
      return token;
    } 
    catch (error) {
      this.logger.error('Error while retrieving token: ', error);
      throw error;
    }
  }
  
  Logout() {
    try {
      this.swal.logout();
      localStorage.clear(); 
      this.logger.debug('Successfully logged out!');
      this.router.navigateByUrl('');
    } 
    catch (error) {
      this.logger.error('Error while logging out: ', error);
      throw error;
    }
  }  

}
