import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Licord } from '../../models/licord.model';
import { Worksite } from '../../models/worksite.model';
import { LicordService } from '../../service/licord.service';
import { WorksiteService } from '../../service/worksite.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';
import { PopsComponent } from '../licord/pops/pops.component';
import View3D from '@egjs/view3d';

@Component({
  selector: 'app-licord-list',
  templateUrl: './licord-list.component.html',
  styleUrls: ['../../../theme/grid.scss']
})

export class LicordListComponent implements OnInit, OnDestroy {
  
  idWorksite = JSON.parse(localStorage.getItem('selected worksite')!).cantieri_key;
  x = JSON.parse(localStorage.getItem('selected worksite') || '[]');
  lat = JSON.parse(localStorage.getItem('selected worksite') || '[]').cn_latitude;
  lng = JSON.parse(localStorage.getItem('selected worksite') || '[]').cn_longitude;
  //lat = 43.666887;
  //lng = 10.999253;


  @ViewChild('map') mapElement: any;
  @ViewChild('el', { static: false }) divView!: ElementRef;
  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  devicewarning!: {
    ds_progressivo: string,
    ds_seriale: string,
    ds_data_installazione: string,
    warning_type_desc: string
  }[];

  dataL = true;
  passwordType = "password";
  responsabile: string = "";
  installatore: string = "";
  modems!: Array<any>;
  tab_tipo_cantiere_desc: any;
  tab_comune_desc: any;
  cn_indirizzo: any;
  tab_provincia_cod: any;
  tab_cap_cod: any;
  cn_cliente: any;
  showTable = true;

  flag = false;
  map: any;
  dataSource!: MatTableDataSource<Licord>;
  devices: any;
  images: any;
  img3D: string = '';
  columns: string[] = [
    'ds_progressivo',
    'ds_attivo',
    'ds_seriale',
    'ds_data_installazione',
    'dataRevisione',
    'warning',
    'dati'
  ];

  idz: any;
  progrz: any;
  installz: any

  property3d = {
    src: JSON.parse(localStorage.getItem('selected worksite') || '[]')._3d_map,
    envmap: "https://naver.github.io/egjs-view3d/texture/dry_hay_field_1k.hdr",
    shadow: {
      darkness: 0.5,
      shadowScale: 10,
      blur: 1
    },
    webAR: {
      vertical: true
    },
    sceneViewer: {
      vertical: true
    }
  };

  selectedImage: any = null;
  lensStyle = {};
  zooming: boolean = false;

  constructor(
    private router: Router,
    private sLicord: LicordService,
    private sWorksite: WorksiteService,
    private logger: LoggerService,
    private error: ErrorService,
    private dialog: MatDialog
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit(): void {
    this.sWorksite.getWorksite(this.idWorksite).subscribe(res => {
      this.valutaAccount(res);
      this.tab_tipo_cantiere_desc = res.data?.tab_tipo_cantiere_desc;
      this.tab_comune_desc = res.data?.tab_comune_desc;
      this.cn_indirizzo = res.data?.cn_indirizzo;
      this.tab_provincia_cod = res.data?.tab_provincia_cod;
      this.tab_cap_cod = res.data?.tab_cap_cod;
      this.cn_cliente = res.data?.cn_cliente;
      this.modems = res.data.modems;
      this.images = res.data.pictures;    //this.images = res?.data.pictures;
      this.img3D = res.data!._3d_map;
    });


    /************ULTIMA MODIFICA, FUNZIONA!!!*********************/
    this.sWorksite.getWorksite(this.idWorksite.ds_cantiere_key).subscribe(res => {
      if (res != null) {
        localStorage.setItem("selected worksite", JSON.stringify(res.data));
      }
    });
    /**********************************/

    this.sLicord.getDevices(this.x.cantieri_key).subscribe(res => {
      if (res != null) {
        if (res.error_message!.msg_code === 8) {
          this.dataL = false;
        } 
        else {
          this.devices = res.data;
          this.dataSource = new MatTableDataSource(this.devices);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          /**********************************/
          // Check if data inside the localStorage have been correctly updated
          const selectedDevice = JSON.parse(localStorage.getItem('selected device')!);
          if (selectedDevice) {
            this.idz = selectedDevice.ds_seriale;
            this.progrz = selectedDevice.ds_progressivo;
            this.installz = selectedDevice.ds_data_installazione;
          } 
          else {
            this.error.handleError('Device not found inside the localStorage');
          }
          /**********************************/
        }
      }
    });
  }

  ngAfterViewInit() {
    this.initMap();
    this.init3DView();
  }

  initMap() {
    // Verifica che lat e lng siano numeri validi
    if (typeof this.lat === 'number' && typeof this.lng === 'number' && !isNaN(this.lat) && !isNaN(this.lng)) {
      const mapProperties = {
        center: new google.maps.LatLng(this.lat, this.lng),
        zoom: 16,
        streetViewControl: true,
        scrollwheel: false,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
        }
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
      const marker = new google.maps.Marker({
        //position: new google.maps.LatLng(this.lat, this.lng),
        position: { lat: this.lat, lng: this.lng },
        map: this.map,
        draggable: true,
        title: "Drag me!"
      });
      google.maps.event.addListener(marker, 'dragend', (evt: any) => {
        let body = {
          cantieri_key: this.idWorksite,
          cn_latitude: evt.latLng.lat(),
          cn_longitude: evt.latLng.lng(),
        };
        if (localStorage.getItem('ac_tipo_utente_cod') == '1' || localStorage.getItem('ac_tipo_utente_cod') == '2') {
          this.sWorksite.updateLatLng(body).subscribe(res => {
            this.logger.info('Updated lat/lng response: ', res);
          });
        }
        this.map.panTo(evt.latLng);
      });
      this.map.setCenter(marker.getPosition());
      marker.setMap(this.map);
    } 
    else {
      this.logger.error('Invalid coordinates for map initialization - Lat:', this.lat);
      this.logger.error('Invalid coordinates for map initialization - Lng:', this.lng);
    }
  }  

  init3DView() {
    new View3D(this.divView.nativeElement, this.property3d);
  }

  valutaAccount(res: Worksite) {
    res.data?.accounts.forEach(el => {
      if (el.ac_tipo_utente_cod == 2) {
        this.responsabile = el.ac_nome + ' ' + el.ac_cognome;
      }
      if (el.ac_tipo_utente_cod == 3) {
        this.installatore = el.ac_nome + ' ' + el.ac_cognome;
      }
    });
  }

  fullscreen(i: any) {
    let elem = document.getElementById('full');
    let elem2 = document.getElementById('card');
    if (this.flag === false) {
      elem!.style.setProperty('display', 'block');
      elem!.style.backgroundImage = "url(" + i.link + ")";
      elem2!.style.setProperty('display', 'none');
      this.flag = true;
    }
  }

  exitfullscreen() {
    let elem = document.getElementById('full');
    let elem2 = document.getElementById('card');
    if (this.flag === true) {
      this.logger.debug('exit..');
      elem!.style.setProperty('display', 'none');
      elem2!.style.setProperty('display', 'block');
      new View3D(this.divView.nativeElement, this.property3d);
      this.flag = false;
    }
  }

  fullscrin(image: any) {
    this.selectedImage = image;
  }

  closeFullscrin() {
    this.selectedImage = null;
  }

  zoom(event: MouseEvent, image: any) {
    const lensSize = 100; 
    const zoomFactor = 2; 
    const img = event.target as HTMLImageElement;
    const imgRect = img.getBoundingClientRect();
    const mouseX = event.clientX - imgRect.left;
    const mouseY = event.clientY - imgRect.top;
    if (mouseX < 0 || mouseY < 0 || mouseX > imgRect.width || mouseY > imgRect.height) {
      this.resetZoom();
      return;
    }
    const lensX = mouseX - lensSize / 2;
    const lensY = mouseY - lensSize / 2;
    this.lensStyle = {
      top: `${lensY}px`,
      left: `${lensX}px`,
      backgroundImage: `url(${image.link})`,
      backgroundPosition: `-${mouseX * zoomFactor - lensSize / 2}px -${mouseY * zoomFactor - lensSize / 2}px`,
      backgroundSize: `${imgRect.width * zoomFactor}px ${imgRect.height * zoomFactor}px`
    };
    this.zooming = true;
  }

  resetZoom() {
    this.zooming = false;
    this.lensStyle = {}; 
  }

  openDialog(id: any) {
    this.dialog.open(PopsComponent, {
      data: {
        dispositivi_key: id
      }
    });
  }

  saveData(data: any) {
    localStorage.setItem('selected device', JSON.stringify(data))
  }

  goCharts(data: any) {
    localStorage.setItem('dispositivi_key', data.dispositivi_key);
    localStorage.setItem('caricaBatteria', this.valutaBatteria(data).toString());
    this.router.navigate(['/default/dashboard']);
  }

  togglePassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
  }

  valutaBatteria(data: any) {
    for (let i = 0; i <= data.ds_list_warnings.length - 1; i++) {
      if (data.ds_list_warnings[i].warning_ico_link === '/assets/lowbattery.png') {
        return true;
      }
    }
    return false;
  }

  calcolaDataRevisione(ds_data_installazione: Date): Date {
    const dataInstallazione = new Date(ds_data_installazione);
    dataInstallazione.setFullYear(dataInstallazione.getFullYear() + 2);
    return dataInstallazione;
  }

  isScaduto(ds_data_installazione: Date): boolean {
    const dataRevisione = this.calcolaDataRevisione(ds_data_installazione);
    const oggi = new Date();
    const expired = oggi > dataRevisione;
    return expired;
  }

  calcolaProgresso(ds_data_installazione: Date): number {
    const dataAttuale = new Date();
    const dataInstallazione = new Date(ds_data_installazione);
    const diffInMesi = (dataAttuale.getFullYear() - dataInstallazione.getFullYear()) * 12 + (dataAttuale.getMonth() - dataInstallazione.getMonth());
    const progresso = (diffInMesi / 24) * 100;
    const progress = Math.max(0, Math.min(100, progresso));
    return progress;
  }

  isMonthComplete(ds_data_installazione: Date): boolean {
    const dataInstallazione = new Date(ds_data_installazione);
    const dataAttuale = new Date();
    const mesiTrascorsi = (dataAttuale.getFullYear() - dataInstallazione.getFullYear()) * 12 + dataAttuale.getMonth() - dataInstallazione.getMonth();
    const scadenza = new Date(dataInstallazione.getFullYear() + 2, dataInstallazione.getMonth(), dataInstallazione.getDate());
    const dataLimite = new Date(scadenza.getTime() + (24 * 60 * 60 * 1000));
    const monthComplete = mesiTrascorsi <= 24 || dataAttuale.getTime() <= dataLimite.getTime();
    return monthComplete;
  }

  calcolaGiorniMancanti(ds_data_installazione: Date): number {
    const dataRevisione = this.calcolaDataRevisione(ds_data_installazione);
    const oggi = new Date();
    const giorniMancanti = Math.ceil((dataRevisione.getTime() - oggi.getTime()) / (1000 * 3600 * 24));
    const daysRemaining = giorniMancanti > 0 ? giorniMancanti : 0;
    return daysRemaining;
  }

  getImageUrl(image: { link: string }): string {
    return image.link.replace("itrepo", "it/repo");
  }
  
  

}
