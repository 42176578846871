import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountsService } from '../../service/accounts.service';
import { LoggerService } from '../../service/logger.service';
import { SwalService } from '../../service/swal.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['../../../theme/modal.scss']
})

export class UsersComponent implements OnInit, OnDestroy {

  form = this.fb.group({
    ac_nome: ['', { validators: [Validators.required] }],
    ac_cognome: ['', { validators: [Validators.required] }],
    ac_email: ['', { validators: [Validators.required] }],
    ac_telefono: ['', { validators: [Validators.required] }],
    ac_cellulare: ['', { validators: [Validators.required] }],
    ac_societa: ['', { validators: [Validators.required] }],
    ac_note: ['', { validators: [Validators.required] }],
    new_password: ['', { validators: [Validators.required, this.passwordMatchValidator] }],
    rep_password: ['', { validators: [Validators.required, this.passwordMatchValidator] }]
  })

  listatipologia = [
    {
      value: 1,
      tipo: 'Admin'
    }, {
      value: 3,
      tipo: 'Responsabile'
    }, {
      value: 2,
      tipo: 'Installatore'
    }
  ]

  selectedTipo: any

  constructor(
    private fb: UntypedFormBuilder, 
    private sAccount: AccountsService, 
    private router: Router,
    private dialog: MatDialogRef<UsersComponent>,
    private logger: LoggerService,
    private swal: SwalService
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit(): void { }
  
  updateType(type: any) {
    this.selectedTipo = type
  }

  saveAccount() {
    let credential = this.form.value
    credential["account_key"] = 0;
    credential["ac_data_scadenza"] = "2025-12-25T14:20:20.268Z";
    credential["ac_attivo"] = "S";
    credential["ac_tipo_utente_cod"] = this.selectedTipo;
    this.sAccount.save_account(credential).subscribe(res => {
      if (res != null) {
        this.swal.user_add();        
        setTimeout(() => {
              location.reload();
        }, 2000); 
      }
    })
  }

  close() {
    this.dialog.close();
  }

  
  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const newPassword = control.get('new_password');
    const repPassword = control.get('rep_password');
    if (!newPassword || !repPassword) {
      return null;
    }
    if (newPassword.value !== repPassword.value) {
      repPassword.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } 
    else {
      repPassword?.setErrors(null);
      return null;
    }
  }

}
