import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Worksite } from '../models/worksite.model';
import { Worksites } from '../models/worksites.model';
import { ErrorService } from './error.service';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class WorksiteService {

  qurlWorksites = "https://licord2024.connecta.ovh/api/services/get_worksites"
  qurlWorksite = "https://licord2024.connecta.ovh/api/services/get_worksite/"
  qurlSaveWorksite = "https://licord2024.connecta.ovh/api/services/save_worksite"
  qurlDeleteWorksite = "https://licord2024.connecta.ovh/api/services/del_worksite"
  qurlLatLng = "https://licord2024.connecta.ovh/api/services/save_worksite_coords"

  urlWorksites = `${environment.apiUrl}api/services/get_worksites`
  urlWorksite = `${environment.apiUrl}api/services/get_worksite/`
  urlSaveWorksite = `${environment.apiUrl}api/services/save_worksite`
  urlDeleteWorksite = `${environment.apiUrl}api/services/del_worksite`
  urlLatLng = `${environment.apiUrl}api/services/save_worksite_coords`

  constructor(
    private http: HttpClient,
    private error: ErrorService,
    private logger: LoggerService
  ) { }


  getWorksites(string?: string) {
    if(string != null) {
      let headers = new HttpHeaders().set('Search', string)
      return this.http.get<Worksites>(this.urlWorksites, {'headers': headers})
      .pipe(
        tap(response => {
          this.logger.debug('Worksites successfully loaded!')
        }),
        catchError(this.error.handleError)
      )
    }
    return this.http.get<Worksites>(this.urlWorksites)
    .pipe(
      tap(response => {
        this.logger.debug('Worksites successfully loaded!')
      }),
      catchError(this.error.handleError)
    )
  }

  getWorksite(id: any) {
    return this.http.get<Worksite>(this.urlWorksite + id)
    .pipe(
      tap(response => {
        this.logger.debug('Worksite successfully loaded!')
      }),
      catchError(this.error.handleError)
    )
  }

  postWorksite(data: any) {
    return this.http.post<Worksite>(this.urlSaveWorksite, data)
    .pipe(
      tap(response => {
        this.logger.debug('Worksite successfully loaded!')
      }),
      catchError(this.error.handleError)
    )
  }

  deleteWorksite(id: any) {  
    return this.http.post(this.urlDeleteWorksite, id)
    .pipe(
      tap(response => {
        this.logger.debug('Worksite successfully loaded!')
      }),
      catchError(this.error.handleError)
    )
  }

  updateLatLng(data: any) {
    return this.http.post(this.urlLatLng, data)
    .pipe(
      tap(response => {
        this.logger.debug('Worksite successfully loaded!')
      }),
      catchError(this.error.handleError)
    )
  }

}
