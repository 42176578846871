<mat-card>
    <mat-card-title>
        <h2 style="text-align:center">Documentazione</h2>
        <mat-icon 
            (click)="openDialog(docu)"
            routerLinkActive="list-item-active" 
            class="btn-add"
        >
        add_circle</mat-icon>
    </mat-card-title>

    <ng-template id="docu" #docu></ng-template>

    <h3 [hidden]="showTable==true">Attualmente non ci sono documenti disponibili</h3>

    <mat-card-content [hidden]="showTable==false">
        <section class="example-container mat-elevation-z8" tabindex="0">
            <div class="divTable">
                <table mat-table [dataSource]="dataSource" matSort>

                    <ng-container matColumnDef="#">
                        <th mat-header-cell *matHeaderCellDef>#</th>
                        <td mat-cell *matCellDef="let index = index">{{ index + 1 }}</td>
                    </ng-container>

                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef>TITOLO</th>
                        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="link">
                        <th mat-header-cell *matHeaderCellDef style="width:5px; padding-right:10px">DOWNLOAD</th>
                        <td mat-cell *matCellDef="let row">
                            <a href={{row.link}} target="_blank">
                                <mat-icon title="Download">file_download</mat-icon>
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef style="width:5px">ELIMINA</th>
                        <td mat-cell *matCellDef="let row">
                            <mat-icon *ngIf="isAdmin" (click)="deleteDoc(row.key)" style="color: red" title="Cancella">delete</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
                </table>

                <mat-paginator [length]="doc?.length" [pageSize]="5" [pageSizeOptions]="[1, 5, 10, 25, 100]"
                    aria-label="Select page">
                </mat-paginator>
            </div>
        </section>
    </mat-card-content>
</mat-card>
