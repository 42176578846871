<div class="containerInserimentoLicord">
    <form id="contact" [formGroup]="form" style="min-width:100%; margin-left:5px; border:none">
        <h3 style="text-align:center">Dati Cantiere</h3>
        <h4 style="text-align:center">Modifica cantiere</h4>
        <div style="display:flex; flex-direction:row">
            <p style="flex:1; margin:5px">
                <mat-form-field appearance="outline" class="byclass">
                    <mat-label>Provincia</mat-label>
                    <mat-select [(value)]="selectedProvCod" (selectionChange)="updateProv($event.value)" autofocus>
                        <mat-option *ngFor="let i of listaProvince" [value]="i.tab_provincia_cod">
                            {{ i.tab_provincia_desc }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
            <p style="flex:1; margin:5px">
                <mat-form-field appearance="outline" class="byclass">
                    <mat-label>Città</mat-label>
                    <mat-select [(value)]="selectedCityCod" (selectionChange)="updateCity($event.value)" autofocus>
                        <mat-option *ngFor="let i of listaCitta" [value]="i.tab_istat_cod">
                            {{ i.tab_comune_desc }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
            <p style="flex:1; margin:5px">
                <mat-form-field appearance="outline" class="byclass">
                    <mat-label>Tipo Cantiere</mat-label>
                        <mat-select [(value)]="selectedTipoCod" (selectionChange)="updateType($event.value)" autofocus>
                            <mat-option *ngFor="let i of listatipologia" [value]="i.tab_tipo_cantiere_cod">
                                {{ i.tab_tipo_cantiere_desc }}
                            </mat-option>
                        </mat-select>
                </mat-form-field>
            </p>
            <p style="flex:2; margin:5px">
                <mat-form-field appearance="outline" class="byclass">
                    <mat-label>Indirizzo</mat-label>
                    <input formControlName="cn_indirizzo" matInput>
                </mat-form-field>
            </p>
        </div>  
        <div style="display:flex; flex-direction:row">
            <p style="flex:1; margin:5px">
                <mat-form-field appearance="outline" class="byclass">
                    <mat-label>Cliente</mat-label>
                    <input formControlName="cn_cliente" matInput>
                </mat-form-field>
            </p>    
            <p style="flex:2; margin:5px">
                <mat-form-field appearance="outline" class="byclass">
                    <mat-label>Note</mat-label>
                    <input formControlName="cn_note" matInput>
                </mat-form-field>
            </p>
        </div>     
        <fieldset
            style="
                display: flex;
                flex-direction: row;
                justify-content: center            
            "
        >
            <button 
                (click)="saveWorksite()"
                class="btn-add" 
                id="contact-submit"
                type="submit" 
                name="submit" 
                data-submit="...Sending"                     
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >save</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >SALVA</span>
            </button>
            <button                         
                (click)="delete()"
                class="btn-canc"
                data-submit="...Sending" 
                id="contact-submitt"
                type="submit" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >delete</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >CANCELLA CANTIERE</span>
            </button>  
        </fieldset>
    </form>
    <br>
</div>

<div class="table">
    <app-modems class="item"></app-modems><br> 
    <app-licord class="item"></app-licord><br>
    <app-worksite-technicians class="item"></app-worksite-technicians><br>
    <app-documentation class="item"></app-documentation><br>
    <app-images class="item"></app-images>
</div>