import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, OnDestroy {

  email: any;
  user: any;
  type: any;
  displayToolsAdmin: boolean = false;
  currentrole: any;

  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnDestroy(): void {console.log('Component destroyed'); }


  ngOnInit(): void {
    this.MenuDisplay()
    this.showCredential()
  }

  MenuDisplay() {
    if(!!localStorage.getItem('token')){
      this.currentrole = localStorage.getItem('ac_tipo_utente_cod')
      this.displayToolsAdmin = (this.currentrole == '1'|| this.currentrole == '3');
    }
  }

  showCredential() {
    this.email = localStorage.getItem('ac_email');
    this.user = localStorage.getItem('ac_nome');
    let userr = localStorage.getItem('ac_nome');
    this.user = userr;
    let ac_tipo_utente_cod = localStorage.getItem('ac_tipo_utente_cod');    
    if(ac_tipo_utente_cod == '1'){
      this.type = 'Amministratore'
    }
    else if(ac_tipo_utente_cod == '3'){
      this.type = 'Responsabile'
    }
    else if(ac_tipo_utente_cod == '2'){
      this.type = 'Installatore'
    }
  }

  toggleSideBar() {
    //nel mobile (sotto i 900 px) scompare la sidebar dopo aver scelto la sezione
    if(window.innerWidth < 900){
      this.toggleSideBarForMe.emit();
      setTimeout(() => {
        window.dispatchEvent(
          new Event('resize')
        );
      }, 300);
    }    
  }

}
