<div *ngIf="!isTokenValid">
    <h1>Token non valido </h1>
</div>

<div class="body" *ngIf="isTokenValid">
    <div class="wrapper fadeInDown">
        <div id="formContent">
            <h2 class="active"> Reset Password </h2>
            <div class="fadeIn first">
                <img src="/assets/logo-licord-orizzontale1.png" id="icon" alt="User Icon" />
            </div>
            <!-- Login Form -->
            <form [formGroup]="form" (ngSubmit)="Reset()">
                <div class="form-input">
                    <input [type]="changetypeN?'password':'text'" id="passwordNew" class="fadeIn third" name="login"
                        placeholder="Nuova Password" [formControlName]="'passwordNew'">
                    <span class="icon">

                        <mat-icon (click)="viewpass('N')">remove_red_eye</mat-icon>
                    </span>
                </div>
                <div *ngIf="f()['passwordNew'].touched && f()['passwordNew'].invalid" class="alert alert-danger">
                    <div *ngIf="f()['passwordNew']?.errors?.['required']">Password obbligatoria!</div>
                </div>
                <div class="form-input">
                    <input [type]="changetypeC?'password':'text'" id="passwordConfirm" class="fadeIn third" name="login"
                        placeholder="Conferma Password" [formControlName]="'passwordConfirm'">
                    <span class="icon">
                        <mat-icon (click)="viewpass('C')">remove_red_eye</mat-icon>
                    </span>
                </div>
                <div *ngIf="f()['passwordConfirm'].touched && f()['passwordConfirm'].invalid"
                    class="alert alert-danger">
                    <div *ngIf="f()['passwordConfirm'].errors?.['required']">Password obbligatoria!</div>
                    <div *ngIf="f()['passwordConfirm'].errors?.['confirmedValidator']">Password e Confirm Password devono coincidere!</div>
                </div>
                <input [disabled]="!form.valid" type="submit" class="fadeIn fourth" value="Reset">
            </form>
        </div>
    </div>
</div>