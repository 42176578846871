import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnDestroy(): void {console.log('Component destroyed'); }


  ngOnInit(): void { }

  currentYear: number = new Date().getFullYear();

}
