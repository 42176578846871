import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { DocumentService } from '../../../service/document.service';
import { DropdownService } from '../../../service/dropdown.service';
import { LicordService } from '../../../service/licord.service';
import { WorksiteService } from '../../../service/worksite.service';
import { LoggerService } from '../../../service/logger.service';
import { ErrorService } from '../../../service/error.service';
import { SwalService } from '../../../service/swal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-docu',
  templateUrl: './add-docu.component.html',
  styleUrls: ['../../../../theme/modal.scss']
})

export class AddDocuComponent implements OnInit, OnDestroy {  

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  public titleFile = null;

  x = JSON.parse(localStorage.getItem('selected worksite') || '[]');
  idWorksite = JSON.parse(localStorage.getItem('selected worksite')!).cantieri_key

  form = this.fb.group({
    cn_indirizzo: [this.x.cn_indirizzo /* { validators: [Validators.required] }*/],
    cn_cliente: [this.x.cn_cliente /*{ validators: [Validators.required] }*/],
    cn_note: [this.x.cn_note /*{ validators: [Validators.required] }*/],
  });

  file: File | null = null;
  selectedTipo = this.x.tab_tipo_cantiere_desc;
  selectedTipoCod = this.x.cn_tipo_cantiere_cod;
  dataSource!: MatTableDataSource<any>;
  typesDocument: any;
  selectedTipoFoto: any;
  selectedTipoDocument: any;
  showTable = true;
  isAdmin = false;
  tab_tipo_cantiere_desc: any
  flag = false;
  dataL = true

  constructor(
    private sDocument: DocumentService,
    private fb: UntypedFormBuilder,
    private sWorksite: WorksiteService,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService,
    public dialog: MatDialogRef<AddDocuComponent>
  ) { }

  ngOnDestroy(): void {console.log('Component destroyed'); }


  ngOnInit(): void {

    this.sWorksite.getWorksite(this.idWorksite).subscribe(res => {
      this.tab_tipo_cantiere_desc = res.data?.tab_tipo_cantiere_desc
      this.logger.info('Server response: ', res)
    })
    /*metodo per chiedere dati cantiere (inserito perchè alla cancellazione di
    immangini o documenti il localstorage non viene aggiornato)*/
    this.sWorksite.getWorksite(this.x.cantieri_key).subscribe(
      (res) => {
        if(res != null) {
          this.x = res.data
        }
      }
    ); 
    this.sDocument.getTypesDocument().subscribe(res => {
      this.typesDocument = res.data
      this.logger.info('Type of documents: ', res.data)
    });
    if (localStorage.getItem('ac_tipo_utente_cod') == '1') {
      this.isAdmin = true;
    }
  }

  updateType(type: any) {
    this.selectedTipoCod = type;
  }

  updateTypeFoto(type: any) {
    this.selectedTipoFoto = type;
  }

  updateTypeDocument(type: any) {
    this.selectedTipoDocument = type;
  }

  onDocumentInput(files: FileList | null, title: string, type: number): void {
    if (files && files.length == 1) {
      this.file = files.item(0);
      this.logger.info('File: ', this.file)
      if (title == null) {
        this.swal.load_warning('In assenza del titolo verrà utilizzato il nome del file').then((result) => {
          if (result.value) {
            this.sDocument.uploadDocument(this.file!, this.file!.name, type);
          } 
          else if (result.dismiss === Swal.DismissReason.cancel) {
            this.swal.load_safe()
            window.location.reload();
            return;
          }
        });
      } 
      else {
        this.sDocument.uploadDocument(this.file!, title, type);
        //window.location.reload();
      }
    } 
    else {
      this.swal.load_one()
    }
  }

  onFileInput(files: FileList | null, title: string, type: number): void {
    if (files && files.length == 1) {
      this.file = files.item(0);
      if (title == null) {
        this.swal.load_warning('In assenza del titolo verrà utilizzato il nome del file').then((result) => {
          if (result.value) {
            this.sDocument.uploadFile(this.file!, this.file!.name, type);
          } 
          else if (result.dismiss === Swal.DismissReason.cancel) {
            this.swal.load_safe()
            window.location.reload();
            return;
          }
        });
      } 
      else {
        this.sDocument.uploadFile(this.file!, title, type);
      }
    } 
    else {
      this.swal.load_one()
    }
  }

  close() {
    this.dialog.close();
  }

}
