import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { RoleGuard } from './shared/role.guard';

import { DefaultComponent } from './layouts/default/default.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { HomepageComponent } from './modules/homepage/homepage.component';
import { WorksiteComponent } from './modules/worksite/worksite.component';
import { UsersComponent } from './modules/users/users.component';
import { ModifyUserComponent } from './modules/modify-user/modify-user.component';
import { LicordListComponent } from './modules/licord-list/licord-list.component';
import { WarningsComponent } from './modules/warnings/warnings.component';
import { AddWorksiteComponent } from './modules/add-worksite/add-worksite.component';
import { WorksiteTechniciansComponent } from './modules/worksite-technicians/worksite-technicians.component';
import { AssignTechnicianComponent } from './modules/assign-technician/assign-technician.component';
import { ModifyWorksiteComponent } from './modules/modify-worksite/modify-worksite.component';
import { ManageAccountsComponent } from './modules/manage-accounts/manage-accounts.component';
import { ManageWorksiteComponent } from './modules/manage-worksite/manage-worksite.component';
import { DocumentationComponent } from './modules/documentation/documentation.component';
import { DeviceTotComponent } from './modules/device-tot/device-tot.component';
import { ResetComponent } from './reset/reset.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AddModemComponent } from './modules/modems/add-modem/add-modem.component';
import { AddImmagesComponent } from './modules/immages/add-immages/add-immages.component';
import { AddDocuComponent } from './modules/documentation/add-docu/add-docu.component';
import { AddTreDComponent } from './modules/tred/add-tred/add-tred.component';

const routes: Routes = [
  {
    path: 'default', 
    component: DefaultComponent, 
    canActivate: [AuthGuard],
    children: [
      { 
        path: 'dashboard', 
        component: DashboardComponent 
      },
      { 
        path: 'homepage', 
        component: HomepageComponent 
      },
      { 
        path: 'worksites', 
        component: WorksiteComponent 
      },
      { 
        path: 'users', 
        component: UsersComponent, 
        canActivate: [RoleGuard] 
      },
      { 
        path: 'modifyUsers', 
        component: ModifyUserComponent, 
        canActivate: [RoleGuard] 
      },
      { 
        path: 'licordList', 
        component: LicordListComponent 
      },
      { 
        path: 'warnings', 
        component: WarningsComponent 
      },
      { 
        path: 'addworksite', 
        component: AddWorksiteComponent, 
        canActivate: [RoleGuard] 
      },
      { 
        path: 'addmodem', 
        component: AddModemComponent, 
        canActivate: [RoleGuard] 
      },
      { 
        path: 'addimmages', 
        component: AddImmagesComponent, 
        canActivate: [RoleGuard] 
      },
      { 
        path: 'adddocu', 
        component: AddDocuComponent, 
        canActivate: [RoleGuard] 
      },
      { 
        path: 'addtred', 
        component: AddTreDComponent, 
        canActivate: [RoleGuard] 
      },
      { 
        path: 'worksite_technician', 
        component: WorksiteTechniciansComponent 
      },
      { 
        path: 'assigntechnician', 
        component: AssignTechnicianComponent, 
        canActivate: [RoleGuard] 
      },     
      { 
        path: 'modifyWorksite', 
        component: ModifyWorksiteComponent, 
        canActivate: [RoleGuard] 
      },
      { 
        path: 'manageAccounts', 
        component: ManageAccountsComponent, 
        canActivate: [RoleGuard] 
      },
      { 
        path: 'manageWorksite', 
        component: ManageWorksiteComponent, 
        canActivate: [RoleGuard] 
      },
      { 
        path: 'documentation', 
        component: DocumentationComponent 
      },
      { 
        path: 'devices', 
        component: DeviceTotComponent 
      },
    ]
  },
  { 
    path: 'PasswordReset', 
    component: ResetComponent 
  },
  {
    path: '', 
    component: LoginComponent 
  },
  { 
    path: '**', 
    component: NotFoundComponent 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
