<div class="containerInserimentoLicord">
    <form id="contact" style="margin-bottom:8px; width:100%">
        <h3 style="text-align:center">Licord</h3>   
        <div class="containerFormLicord">
            <div>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>ID dispositivo</mat-label>
                    </mat-form-field>
                </p>
            </div>
        </div>
    </form>
</div>
