import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentService } from '../../service/document.service';
import { SwalService } from '../../service/swal.service';
import { WorksiteService } from '../../service/worksite.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';
import { AddTreDComponent } from './add-tred/add-tred.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tred',
  templateUrl: './tred.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class TreDComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true })

  idWorksite = JSON.parse(localStorage.getItem('selected worksite') || '[]').cantieri_key
  x = JSON.parse(localStorage.getItem('selected worksite') || '[]')

  showTable = true;
  isAdmin = false;
  dataSource!: MatTableDataSource<any>;
  tred: any;
  treddi: any;
  paginator!: MatPaginator;
  columns: string[] = [
    '#',
    'title', 
    'date',
    'link', 
    'delete'
  ];    

  constructor(
    private sWorksite: WorksiteService, 
    private sDocument: DocumentService,
    private logger: LoggerService,
    private error: ErrorService,
    private dialog: MatDialog,
    private swal: SwalService
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit(): void {
    if (localStorage.getItem('ac_tipo_utente_cod') == '1') {
      this.isAdmin = true;
    }
    this.sWorksite.getWorksite(this.idWorksite).subscribe(res => {
      if (res != null) {
        if (res.data._3d_map_key.length.toString() === '0') {
          this.showTable = false
        } 
        else {
          this.tred = res.data._3d_map_key;
          this.treddi = res.data;
          this.dataSource = new MatTableDataSource(this.tred);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      }
    },
    (error) => {
      this.error.handleError(error)
    })
  }

  openDialog(id: any) {
    this.dialog.open(AddTreDComponent, {
      data:{
        id: id
      }
    });
  }

  delete3D(_3d_key: number) {
    let data = {
      documentKey: _3d_key,
      documentType: 1
    }
    this.swal.img_warning('Questo 3D verrà eliminato!').then((result) => {
      if (result.value) {
        this.sDocument.deleteFile(data).subscribe(res => {
          if (res != null) {
            this.logger.debug(res)
            this.ngOnInit();
            this.swal.treddi_delete()
          }
        }, 
        (error) => {
          this.error.handleError(error)
          this.swal.treddi_err();
        })
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) {
        this.swal.treddi_safe()
        return
      }
    })
  }

}
