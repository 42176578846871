import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AccountsService } from '../../service/accounts.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';

@Component({
  selector: 'app-assign-technician',
  templateUrl: './assign-technician.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class AssignTechnicianComponent implements OnInit, AfterViewInit, OnDestroy {
  
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  accounts: any[] = [];
  columns: string[] = ['checked', 'ac_nome', 'ac_cognome', 'ac_email', 'tab_tipo_utente_desc'];

  constructor(
    private sUser: AccountsService,
    private logger: LoggerService,
    private errorService: ErrorService // Aggiungi l'ErrorService qui
  ) { }

  ngOnDestroy(): void {console.log('Component destroyed'); }


  ngOnInit(): void {
    this.sUser.get_accounts().subscribe(
      (res: any) => {
        if (res && res.data) {
          this.logger.info('Account list: ', res.data);
          this.accounts = res.data;
          this.dataSource = new MatTableDataSource(this.accounts);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        } 
        else {
          this.logger.error('Empty response or missing data: ', res);
        }
      },
      (error: any) => {
        this.logger.error('Error fetching accounts: ', error);
        this.errorService.handleError(error); // Usa l'ErrorService qui
      }
    );
  }

  ngAfterViewInit(): void {
    // Nessuna azione necessaria qui, l'inizializzazione del sort e paginator è gestita in ngOnInit
  }

  applyFilter(event: any): void {
    if (this.dataSource) {
      const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
      this.dataSource.filter = filterValue;
    }
  }
}
