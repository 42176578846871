<mat-card class="cardTable">
    <mat-card-title>
        <h2 style="text-align:center">Gestione accounts</h2>
        <mat-icon 
            (click)="openDialog(user)"
            routerLinkActive="list-item-active"
            class="btn-add"
        >add_circle</mat-icon>
    </mat-card-title>
    <ng-template id="user" #user></ng-template>
    <mat-card-content>
        <div style="display: flex; flex-direction: column">
            <h4 *ngIf="dataL==false &&  filter=='0'" style="text-align: center">Attualmente non ci sono licord in warning</h4>
            <div *ngIf="dataL==false &&  filter=='1'" style="display: flex; flex-direction: column; justify-content: center"> 
                <h4 style="text-align: center">Nessuna corrispondenza trovata</h4>
            <button mat-raised-button 
                style="text-align: center; margin: 20px; font-size: 20px" (click)="filtra(null)">Nuova ricerca</button> 
            </div>    
        </div>
        <mat-form-field class="example-form-field">
            <button *ngIf="value" matPrefix mat-icon-button aria-label="Clear">
                <img src="/assets/x.png" alt="" width="10" (click)="filtra(null)">
            </button>
            <mat-label>Filtro</mat-label>
            <form 
                (ngSubmit)="filtra(value)"
                style="
                    padding: 10px;
                    border-radius: 4px;
                    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)
                "
            >
                <input matInput type="text" [(ngModel)]="value" [ngModelOptions]="{standalone: true}">
            </form>
            <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear">
                <img src="/assets/right-arrow.png" alt="" width="20" (click)="filtra(value)">
            </button>
        </mat-form-field>
        <section class="example-container mat-elevation-z8" tabindex="0">
            <div class="divTable">
                <table mat-table [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>NOME</th>
                        <td mat-cell *matCellDef="let row">{{ row.ac_nome }}</td>
                    </ng-container>
                    <ng-container matColumnDef="lastname">
                        <th mat-header-cell *matHeaderCellDef>COGNOME</th>
                        <td mat-cell *matCellDef="let row">{{ row.ac_cognome }}</td>
                    </ng-container>
                    <ng-container matColumnDef="mail">
                        <th mat-header-cell *matHeaderCellDef>EMAIL</th>
                        <td mat-cell *matCellDef="let row">{{ row.ac_email }}</td>
                    </ng-container>
                    <ng-container matColumnDef="phone">
                        <th mat-header-cell *matHeaderCellDef>TELEFONO</th>
                        <td mat-cell *matCellDef="let row">{{ row.ac_cellulare }}</td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef>TIPO</th>
                        <td mat-cell *matCellDef="let row">{{ row.tab_tipo_utente_desc }}</td>
                    </ng-container>
                    <ng-container matColumnDef="company">
                        <th mat-header-cell *matHeaderCellDef>SOCIETÀ</th>
                        <td mat-cell *matCellDef="let row">{{ row.ac_societa }}</td>
                    </ng-container>
                    <ng-container matColumnDef="note">
                        <th mat-header-cell *matHeaderCellDef>NOTE</th>
                        <td mat-cell *matCellDef="let row">{{ row.ac_note }}</td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>ACTIONS</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button 
                                    mat-menu-item 
                                    (click)="openDialog2(user)"
                                    routerLinkActive="list-item-active"
                                >
                                    <mat-icon>edit</mat-icon>
                                    <span>Modifica</span>
                                </button>
                                <!--<button 
                                    mat-menu-item 
                                    (click)="openDialog3(user)"
                                    routerLinkActive="list-item-active"
                                >
                                    <mat-icon>lock_open</mat-icon>
                                    <span>Cambia password</span>
                                </button>-->
                                <button mat-menu-item (click)="deleteData(row.account_key)">
                                    <mat-icon style="color: red;">delete</mat-icon>
                                    <span>Elimina</span>
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns;" (click)="saveDataAccount(row)"></tr>
                </table>
                <mat-paginator [length]="users?.length" [pageSize]="25" [pageSizeOptions]="[1, 5, 10, 25, 100]"
                    aria-label="Select page">
                </mat-paginator>
            </div>
        </section>
    </mat-card-content>
</mat-card>
<br>