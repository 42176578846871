import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { TypesDocument } from '../models/typesDocument';
import { ErrorService } from './error.service';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class DocumentService {

  qapiUrl = "https://licord2024.connecta.ovh/api/services/"
  apiUrl = `${environment.apiUrl}api/services/`  

  constructor(
    private http: HttpClient,
    private error: ErrorService,
    private logger: LoggerService
  ) { }

  getTypesDocument() {
    return this.http.get<TypesDocument>(this.apiUrl + "get_types_document")
    .pipe(
      tap(response => {
        this.logger.debug('Successfully taken document infos!')
      }),
      catchError(this.error.handleError)
    )
  }

  addCredentials(data: any) {
    return this.http.post(this.apiUrl + 'put_modem', data)
    .pipe(
      tap(response => {
        this.logger.debug('Credentials successfully credentials created!')        
      }),
      catchError(error => this.error.handleError(error))
    )
    .subscribe(res => {
      this.logger.debug('Response after loading the credentials');
      window.location.reload()      
    })
  }

  uploadFile(file: File, title: string, type: number) {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      let toSend = {
        location_key: JSON.parse(localStorage.getItem('selected worksite')!).cantieri_key,
        title: title,
        description: "file di prova",
        filename: file.name,
        file: btoa(reader.result?.toString()!),
        type: type
      }
      return this.http.post(this.apiUrl + "put_file", toSend)
        .pipe(
          tap(response => {
            this.logger.debug('Image successfully uploaded!')  
          }),
          catchError(error => this.error.handleError(error))
        )
        .subscribe(res => {
          this.logger.info('Response after uploading the file');
          window.location.reload()
        })
    }
  }
  
  uploadDocument(file: File, title: string, type: number) {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      let toSend = {
        cantiere_key: JSON.parse(localStorage.getItem('selected worksite')!).cantieri_key,
        title: title,
        description: "file di prova",
        filename: file.name,
        document: btoa(reader.result?.toString()!),
        document_type_cod: type
      }
      return this.http.post(this.apiUrl + "put_document", toSend)
        .pipe(
          tap(response => {
            this.logger.debug('Document successfully uploaded!')  
          }),
          catchError(error => this.error.handleError(error))
        )
        .subscribe(res => {
          this.logger.info('Response after uploading the document');
          window.location.reload()
        })
    }
  }

  deleteFile(data: any) {
    return this.http.post(this.apiUrl + "del_file", data)
    .pipe(
      tap(response => {
        this.logger.debug('Image successfully deleted!')  
      }),
      catchError(error => this.error.handleError(error))
    )
  }

  deleteDocument(data: any) {
    return this.http.post(this.apiUrl + "del_document", data)
    .pipe(
      tap(response => {
        this.logger.debug('Document successfully deleted!')  
      }),
      catchError(error => this.error.handleError(error))
    )
  }

  deleteCredentials(data: any) {
    return this.http.post(this.apiUrl + "del_modem", data)
    .pipe(
      tap(response => {
        this.logger.debug('Credentials successfully deleted!')  
      }),
      catchError(error => this.error.handleError(error))
    )
  }

}
