<div class="containerInserimentoLicord">
    <form id="contact" style="margin-bottom:8px; width:100%">
        <h3 style="text-align:center">Licord</h3>   
        <h4 style="text-align:center">Configura dispositivo</h4>
        <div class="containerFormLicord">
            <div>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Delta_T</mat-label>
                        <input type="number" [(ngModel)]="delta_t" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Wake up 1</mat-label>
                        <input type="time" [(ngModel)]="wake_up_1" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Wake up 2</mat-label>
                        <input type="time" [(ngModel)]="wake_up_2" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Wake up 3</mat-label>
                        <input type="time" [(ngModel)]="wake_up_3" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Timeout accensione</mat-label>
                        <input type="number" [(ngModel)]="timeout_accensione" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Tentativi wifi</mat-label>
                        <input type="number" [(ngModel)]="tentativi_wifi" matInput [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                </p>
                <p class="pdxInserimentoLicord">
                    <mat-checkbox [(ngModel)]="invia" [ngModelOptions]="{standalone: true}" class="byclass">
                        Invia al Licord
                    </mat-checkbox>
                </p>                
            </div>
        </div>
        <fieldset
            style="
                margin-top: 20px;
                margin-bottom: -40px
            "
        >
            <button 
                (click)="aggiornaLicord()" 
                data-submit="...Sending" 
                class="btn-add"
                id="contact-submit"
                type="submit" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >save</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >SALVA</span>
            </button>
            <button 
                data-submit="...Sending" 
                (click)="close()"
                class="btn-annulla"
                id="contact-submitt"
                type="button" 
                name="submit" 
            >
                <mat-icon 
                    style="
                        margin-right: 25px; 
                        transform: scale(2.3);
                        position: relative;
                        top: 2px
                    "
                >clear</mat-icon>
                <span 
                    style="
                        position: relative; 
                        top: -3px
                    "
                >ANNULLA</span>
            </button>
        </fieldset>
    </form>
</div>
