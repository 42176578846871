<div class="containerInserimentoLicord">
    <form [formGroup]="form" id="contact" style="margin-bottom: 8px; width: 100%">
        <h3 style="text-align:center">Inserimento File</h3>
        <h4 style="text-align:center">Aggiungi un nuovo file</h4>    
        <div class="containerFormLicord">
            <div>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Titolo file</mat-label>
                        <input [(ngModel)]="titleFile" matInput [ngModelOptions]="{standalone: true}">    
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Tipo documento</mat-label>
                        <mat-select (selectionChange)="updateTypeDocument($event.value)" autofocus>
                            <mat-option *ngFor="let i of typesDocument" [value]="i.tab_tipo_documento_cod">        
                                {{ i.tab_tipo_documento_desc }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>
                <fieldset
                    style="
                        margin-top: 20px;
                        margin-bottom: -40px
                    "
                >
                    <button 
                        class="btn-add" 
                        data-submit="...Sending" 
                        (click)="documentInput.click()"
                        id="contact-submit"
                        name="submit" 
                        type="submit" 
                    >
                        <mat-icon 
                            style="
                                margin-right: 25px; 
                                transform: scale(2.3);
                                position: relative;
                                top: 2px
                            "
                        >cloud_upload</mat-icon>
                        <span 
                            style="
                                position: relative; 
                                top: -3px
                            "
                        >AGGIUNGI</span>
                        <input 
                            type="file" 
                            #documentInput
                            (change)="onDocumentInput(documentInput.files, titleFile!, selectedTipoDocument)" 
                            style="display: none"
                            accept=".pdf, .csv, .txt, .docx, .dwg" 
                        />
                    </button>
                    <button 
                        data-submit="...Sending" 
                        (click)="close()"
                        class="btn-annulla"
                        id="contact-submitt"
                        type="submit" 
                        name="submit" 
                    >
                        <mat-icon 
                            style="
                                margin-right: 25px; 
                                transform: scale(2.3);
                                position: relative;
                                top: 2px
                            "
                        >clear</mat-icon>
                        <span 
                            style="
                                position: relative; 
                                top: -3px
                            "
                        >ANNULLA</span>
                    </button>
                </fieldset>
            </div>
        </div>
    </form>
</div>