<mat-nav-list>
    <div class="profile-card"  >
        <div class="header">
            <h3>{{ user }}</h3>
            <h5>( {{ type }} )</h5>            
            <h5>{{ email }}</h5>
        </div>        
        <img 
            routerLinkActive="list-item-active" 
            routerLink="worksites" 
            src="/assets/logo-licord-orizzontale1.svg" 
            alt="logo licord" 
            style="width: 320px; cursor: pointer;"
        >
    </div>
    <mat-divider></mat-divider>
    <div>
        <h2 matSubheader style="margin-top: 0;">Main Tools</h2>
        <a 
            mat-list-item routerLinkActive="list-item-active" 
            routerLink="worksites"  
            (click)="toggleSideBar()"
        >Cantieri</a>
        <a 
            mat-list-item routerLinkActive="list-item-active" 
            routerLink="devices" 
            (click)="toggleSideBar()"
        >LiCoRD</a>    
        <a 
            mat-list-item routerLinkActive="list-item-active" 
            routerLink="homepage" 
            (click)="toggleSideBar()"
        >Warning</a>
    </div>
    <mat-divider></mat-divider>
    <div *ngIf="displayToolsAdmin">
        <h2 matSubheader style="margin-top: 0;">Admin Tools</h2>
        <a 
            mat-list-item routerLinkActive="list-item-active" 
            routerLink="manageAccounts" 
            (click)="toggleSideBar()"
        >Gestione Accounts</a>
        <a 
            mat-list-item routerLinkActive="list-item-active" 
            routerLink="manageWorksite" 
            (click)="toggleSideBar()"
        >Gestione Cantieri</a>
    </div>
    <mat-divider></mat-divider>
</mat-nav-list>
