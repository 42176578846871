import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef } from '@angular/material/dialog';
import { LicordService } from '../../../service/licord.service';
import { WorksiteService } from '../../../service/worksite.service';
import { LoggerService } from '../../../service/logger.service';
import { ErrorService } from '../../../service/error.service';
import { SwalService } from '../../../service/swal.service';

@Component({
  selector: 'app-new-licord',
  templateUrl: './new-licord.component.html',
  styleUrls: ['../../../../theme/modal.scss']
})

export class NewLicordComponent implements OnInit, OnDestroy {  

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  public serialNumber: any
  public progressiveNumber: any
  public ds_data_installazione: any
  public serialCella: any
  public fondoScala: any
  public sensibilityCella: any 
  public fattore_calibrazione: any 
  public note = ""
  public wake_up_1 = "";
  public wake_up_2 = "";
  public wake_up_3 = "";
  public delta_t = 0;
  public ssid = null
  public pw = null
  public ds_modem_key: any
  public ds_cantiere_key: any
  public timeout_accensione: any
  public tentativi_wifi: any

  gruppo1: boolean = false;
  gruppo2: boolean = false;

  x = JSON.parse(localStorage.getItem('selected worksite') || '[]');
  idWorksite = JSON.parse(localStorage.getItem('selected worksite')!).cantieri_key
  idLicord = JSON.parse(localStorage.getItem('selected device') || '[]');

  licordForm = this.fb.group({
      serialNumber: ['', { validators: [Validators.required] }],
      progressiveNumber: ['', { validators: [Validators.required] }],
      serialCella: ['', { validators: [Validators.required] }],
      fondoScala: ['', { validators: [Validators.required] }],
      sensibilityCella: ['', { validators: [Validators.required] }],
      fattore_calibrazione: ['', { validators: [Validators.required] }],
      ds_data_installazione: ['', { validators: [Validators.required] }],
      wake_up_1: ['', /*{ validators: [Validators.required] }*/],
      wake_up_2: ['', /*{ validators: [Validators.required] }*/],
      wake_up_3: ['', /*{ validators: [Validators.required] }*/],
      delta_t: ['', { validators: [Validators.required] }],
      timeout_accensione: ['', { validators: [Validators.required] }],
      tentativi_wifi: ['', { validators: [Validators.required] }],
      ds_modem_key: ['', { validators: [Validators.required] }],
      ds_note: ['', ]
  });

  modemList: any
  modemssid = "Scegli un modem"
  selectedModem = this.idLicord.ds_modem_key;
  licord: any;
  showTable = true;
  isAdmin = false;
  devices: any;
  modems!: Array<any>
  progre_num: number = 1;

  constructor(
    private sLicord: LicordService,
    private fb: UntypedFormBuilder,
    private sWorksite: WorksiteService,
    private router: Router,
    private swal: SwalService,
    public dialog: MatDialogRef<NewLicordComponent>,
    private logger: LoggerService,
    private error: ErrorService
  ) { }  

  ngOnDestroy(): void {console.log('Component destroyed'); }


  ngOnInit(): void {

    this.sWorksite.getWorksite(this.idWorksite)
    .subscribe(res => {
      this.logger.info('Server response: ', res)
      this.modemList = res.data.modems
      this.modemList.forEach((element: any) => {
        if (element.key == this.ds_modem_key) {
          this.modemssid = element.ssid
        }
      });
    })
    if (localStorage.getItem('ac_tipo_utente_cod') == '1') {
      this.isAdmin = true;
    };
    this.licordForm.get('ds_data_installazione')?.valueChanges.subscribe(value => {
      if (value) {
        const date = new Date(value);
        date.setDate(date.getDate() + 1);
        this.licordForm.get('ds_data_installazione')?.setValue(date, { emitEvent: false });
      }
    });
  }

  updateModemkey(modemkey: any) {
    this.ds_modem_key = modemkey;
  }

  onDeltaTChange() {
    this.gruppo1 = true;
    this.gruppo2 = false
  }

  onWakeInputChange() {
    this.gruppo1 = false;
    this.gruppo2 = true
  }

  saveDevice() {
    let data = {
      dispositivi_key: 0,
      ds_cantiere_key: this.x.cantieri_key,
      ds_attivo: 'S',
      ds_seriale: this.licordForm.value.serialNumber,
      ds_progressivo: this.licordForm.value.progressiveNumber,
      ds_seriale_cella: this.licordForm.value.serialCella,
      ds_fondo_scala_cella: this.licordForm.value.fondoScala,
      ds_sensibilita_cella: this.licordForm.value.sensibilityCella,
      ds_fattore_calibrazione: this.licordForm.value.fattore_calibrazione,
      ds_modem_key: this.selectedModem,
      ds_wakeup_delta_t: this.licordForm.value.delta_t,
      ds_wakeup_T1: this.licordForm.value.wake_up_1,
      ds_wakeup_T2: this.licordForm.value.wake_up_2,
      ds_wakeup_T3: this.licordForm.value.wake_up_3,
      ds_data_installazione: this.licordForm.value.ds_data_installazione,
      //ds_data_installazione: this.addDays(this.licordForm.value.ds_data_installazione, 1)
      ds_note: this.licordForm.value.ds_note,
      ds_timeout_accensione: this.licordForm.value.timeout_accensione,
      ds_tentativi_conn_wifi: this.licordForm.value.tentativi_wifi
    };
    this.logger.info('Device: ', data)
    /*if(this.licordForm.invalid){
      this.swal.licord_err()
    }*/
    this.sLicord.saveDevice(data).subscribe(
      (res) => {
        this.swal.licord_add();
        /*if (res.success) {         
          this.logger.info('New Licord data: ', data)
          localStorage.setItem('selected device', JSON.stringify(res.data))
          this.swal.licord_add();
          //window.location.reload();
          this.router.navigate(['/default/devices'])
        }*/
        if (res != null && res.data) {
          this.logger.info('New Licord data: ', data)
          localStorage.setItem('selected device', JSON.stringify(res.data))
          this.swal.licord_add();
          window.location.reload();
          this.router.navigate(['/default/devices'])
        }
      },
      (error) => {
          this.error.handleError(error);
          this.swal.licord_err()
      }
    );
  }

  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  daje(): void {
    this.progre_num++;
  }

  close() {
    this.dialog.close();
  }

  showInfo1() {
    this.swal.info1()
  }

  showInfo2() {
    this.swal.info2()  
  }

  showInfo3() {
    this.swal.info3()  
  }

  showInfo4() {
    this.swal.info4()  
  }

  showInfo5() {
    this.swal.info5()  
  }

  showInfo6() {
    this.swal.info6()  
  }

  showInfo7() {
    this.swal.info7()  
  }

  showInfo8() {
    this.swal.info8()  
  }

  showInfo9() {
    this.swal.info9()  
  }

  showInfo10() {
    this.swal.info10()  
  }

  showInfo11() {
    this.swal.info11()  
  }

  showInfo12() {
    this.swal.info12()  
  }

  showInfo13() {
    this.swal.info13()  
  }

  showInfo14() {
    this.swal.info14()  
  }

  showInfo15() {
    this.swal.info15()  
  }

}
