import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { LoggerService } from './logger.service';


@Injectable({
  providedIn: 'root'
})

export class ErrorService {

  constructor(
    private http: HttpClient,
    private logger: LoggerService
  ) { }

  handleError(error: any): Observable<any> {
    if (error instanceof HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // Errore client-side
            const errorMessage = `An error occurred: ${error.error.message}`;
            this.logger.error(errorMessage);
            return throwError(errorMessage);
        } 
        else {
            // Errore server-side
            const errorMessage = `HTTP Error: ${error.status} occurred while making a request. `;
            const requestInfo = error.url ? `URL: ${error.url}` : '';
            this.logger.error(`${errorMessage} ${requestInfo}`, error.error);
            if (error.error instanceof ProgressEvent && error.error.target instanceof XMLHttpRequest) {
                this.logger.error('Error: ', error.error.target.response);
            }
            return throwError(errorMessage);
        }
    } 
    else {
        // Errore generico
        const errorMessage = 'An unexpected error occurred';
        this.logger.error(errorMessage, error);
        return throwError(errorMessage);
    }
  }

}
