import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FlexLayoutModule } from '@angular/flex-layout';
//import { HighchartsChartModule } from 'highcharts-angular';
import { ThousandSeparatorPipe } from '../../../app/pipe/sign';

import { MatNativeDateModule, MatOptionModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../../shared/shared.module';

import { DefaultComponent } from './default.component';
import { DashboardComponent } from '../../modules/dashboard/dashboard.component';
import { HomepageComponent } from '../../modules/homepage/homepage.component';
import { WorksiteComponent } from '../../modules/worksite/worksite.component';
import { WarningsComponent } from '../../modules/warnings/warnings.component';
import { UsersComponent } from '../../modules/users/users.component';
import { ModifyUserComponent } from '../../modules/modify-user/modify-user.component';
import { AssignTechnicianComponent } from '../../modules/assign-technician/assign-technician.component';
import { AddWorksiteComponent } from '../../modules/add-worksite/add-worksite.component';
import { LicordListComponent } from '../../modules/licord-list/licord-list.component';
import { WorksiteTechniciansComponent } from '../../modules/worksite-technicians/worksite-technicians.component';
import { ManageAccountsComponent } from '../../modules/manage-accounts/manage-accounts.component';
import { ManageWorksiteComponent } from '../../modules/manage-worksite/manage-worksite.component';
import { ModifyWorksiteComponent } from '../../modules/modify-worksite/modify-worksite.component';
import { PopsComponent } from '../../modules/licord/pops/pops.component';
import { RemoteComponent } from '../../modules/licord/remote/remote.component';
import { DevUpComponent } from '../../modules/device-tot/dev-up/dev-up.component';
import { DocumentationComponent } from '../../modules/documentation/documentation.component';
import { AddDocuComponent } from '../../modules/documentation/add-docu/add-docu.component';
import { ImmagesComponent } from '../../modules/immages/immages.component';
import { AddImmagesComponent } from '../../modules/immages/add-immages/add-immages.component';
import { TreDComponent } from '../../modules/tred/tred.component';
import { AddTreDComponent } from '../../modules/tred/add-tred/add-tred.component';
import { LicordComponent } from '../../modules/licord/licord.component';
import { PopUpComponent } from '../../modules/licord/pop-up/pop-up.component';
import { DeviceTotComponent } from '../../modules/device-tot/device-tot.component';
import { NewLicordComponent } from '../../modules/licord/new-licord/new-licord.component';
import { ModemsComponent } from '../../modules/modems/modems.component';
import { AddModemComponent } from '../../modules/modems/add-modem/add-modem.component';
import { AddTechComponent } from '../../modules/worksite-technicians/add-docu/add-tech.component';
import { PasswordComponent } from '../../modules/password/password.component';

import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';

@NgModule({
  declarations: [
    DefaultComponent,
    DashboardComponent,
    HomepageComponent,
    WorksiteComponent,
    WarningsComponent,
    UsersComponent,
    ModifyUserComponent,
    AssignTechnicianComponent,
    AddWorksiteComponent,
    LicordListComponent,
    WorksiteTechniciansComponent,
    ManageAccountsComponent,
    ManageWorksiteComponent,
    ModifyWorksiteComponent,
    DocumentationComponent,
    AddDocuComponent,
    ImmagesComponent,
    AddImmagesComponent,
    TreDComponent,
    AddTreDComponent,
    LicordComponent,
    DeviceTotComponent,
    ModemsComponent,
    AddModemComponent,
    PopUpComponent,
    ThousandSeparatorPipe,
    NewLicordComponent,
    AddTechComponent,
    PopsComponent,
    RemoteComponent,
    DevUpComponent,
    PasswordComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    FlexLayoutModule,
    //HighchartsChartModule,
    MatNativeDateModule,
    MatOptionModule,
    MatSidenavModule,
    MatDividerModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatSelectModule,
    MatTooltipModule,
    SharedModule    
  ],
  exports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    FlexLayoutModule,
    MatNativeDateModule,
    MatOptionModule,
    MatSidenavModule,
    MatDividerModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatSelectModule,
    MatTooltipModule  
  ],
  providers: [
    LoggerService,
    ErrorService,
    /*{ 
      provide: HTTP_INTERCEPTORS, 
      useClass: TokenInterceptorService, 
      multi: true 
    }, */
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'it' 
    },
    { 
      provide: MAT_DIALOG_DATA, 
      useValue: {} 
    }
  ]
})

export class DefaultModule { }
