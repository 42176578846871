import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoggerService } from './logger.service';


@Injectable({
    providedIn: 'root'
})

export class ConfigService {

    private configUrl: string = 'https://sviluppo.connecta.ovh/Licord/www2024/config.json';

    constructor(
        private http: HttpClient,
        private logger: LoggerService
    ) { }

    loadConfig(): Promise<void> {
        return this.http.get<{ [key: string]: { apiUrl: string } }>(this.configUrl).toPromise()
            .then(config => {
                if (!config) {
                    this.logger.error('The configuration file is "undefined"');
                    throw new Error('Configuration file not found');
                }    
                this.logger.info('Loaded configuration: ', config);
                const domain = window.location.hostname;
                this.logger.info('Actual domain: ', domain);
                const domainConfig = config[domain];
                if (domainConfig && domainConfig.apiUrl) {
                    environment.apiUrl = domainConfig.apiUrl;
                } 
                else {
                    this.logger.error('Configuration for the domain not found: ', domain);
                    // Set a specific value if needed
                    environment.apiUrl = 'https://app.licord.it';
                }
            })
            .catch(error => {
                this.logger.error('Error while loading the configuration file: ', error);
                // Set a specific value if the configuration fila has not been found
                environment.apiUrl = 'https://app.licord.it';
            });
    }

}
