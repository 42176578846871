import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { DocumentService } from '../../../service/document.service';
import { WorksiteService } from '../../../service/worksite.service';
import { LoggerService } from '../../../service/logger.service';
import { ErrorService } from '../../../service/error.service';
import { SwalService } from '../../../service/swal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-tred',
  templateUrl: './add-tred.component.html',
  styleUrls: ['../../../../theme/modal.scss']
})

export class AddTreDComponent implements OnInit, OnDestroy {  

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  public titleFile = null;
  public titleImg = null;
  public title3D = null;

  x = JSON.parse(localStorage.getItem('selected worksite') || '[]');
  idWorksite = JSON.parse(localStorage.getItem('selected worksite')!).cantieri_key

  form = this.fb.group({
    cn_indirizzo: [this.x.cn_indirizzo /* { validators: [Validators.required] }*/],
    cn_cliente: [this.x.cn_cliente /*{ validators: [Validators.required] }*/],
    cn_note: [this.x.cn_note /*{ validators: [Validators.required] }*/],
  });

  file: File | null = null;
  img: File | null = null;
  listatipologia: any;
  dataSource!: MatTableDataSource<any>;
  typesDocument: any;
  selectedTipoFoto: any;
  selectedTipoDocument: any;
  showTable = true;
  isAdmin = false;
  tab_tipo_cantiere_desc: any
  flag = false;
  images: any
  img3D: string = ''
  dataL = true
  date = new Date();

  constructor(
    private sDocument: DocumentService,
    private fb: UntypedFormBuilder,
    private sWorksite: WorksiteService,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService,
    public dialog: MatDialogRef<AddTreDComponent>
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit(): void {
    this.sWorksite.getWorksite(this.idWorksite).subscribe(res => {
      this.tab_tipo_cantiere_desc = res.data?.tab_tipo_cantiere_desc
      this.images = res.data?.pictures
      this.img3D = res.data!._3d_map
      this.logger.info('Server response: ', res)
    });
    /*metodo per chiedere dati cantiere (inserito perchè alla cancellazione di
    immangini o documenti il localstorage non viene aggiornato)*/
    this.sWorksite.getWorksite(this.x.cantieri_key).subscribe(
      (res) => {
        if(res != null) {
          this.x = res.data
        }
      }
    );
    this.sDocument.getTypesDocument().subscribe(res => {
      this.typesDocument = res.data
    });
  }

  updateTypeFoto(type: any) {
    this.selectedTipoFoto = type;
  }

  updateTypeDocument(type: any) {
    this.selectedTipoDocument = type;
  }

  onFileInput(files: FileList | null, title: string, type: number): void {
    if (files && files.length == 1) {
      this.file = files.item(0);
      if (title == null) {
        this.swal.load_warning('In assenza del titolo verrà utilizzato il nome del file').then((result) => {
          if (result.value) {
            this.sDocument.uploadFile(this.file!, this.file!.name, type);
          } 
          else if (result.dismiss === Swal.DismissReason.cancel) {
            this.swal.load_safe()
            window.location.reload();
            return;
          }
        });
      } 
      else {
        this.sDocument.uploadFile(this.file!, title, type);
      }
    } 
    else {
      this.swal.load_one()
    }
  }

  delete3D() {
    let data = {
      documentKey: this.x._3d_map_key,
      documentType: 2
    }
    this.swal.treddi_warning('Questo 3D verrà eliminato!').then((result) => {
      if (result.value) {
        this.sDocument.deleteFile(data).subscribe(res => {
          if (res != null) {
            this.ngOnInit()
            this.swal.treddi_delete()
          }
        }, 
        (error) => {
            this.error.handleError(error);
            this.swal.treddi_err()
        })
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) {
        this.swal.treddi_safe()
        return
      }
    })
  }

  close() {
    this.dialog.close();
  }

}
