<div id="full">
    <mat-icon (click)="exitfullscreen()" style="background-color:white; float:right; cursor:pointer">close</mat-icon>
</div>
<mat-card id="card">
    <div class="row1">
        <div class="column1">
            TIPO CANTIERE: <strong style="font-size:18px; font-weight:bold">{{ tab_tipo_cantiere_desc }}</strong> <br>
            INDIRIZZO: <strong style="font-size:18px; font-weight:bold">{{ tab_comune_desc }} {{ cn_indirizzo }}, {{ tab_provincia_cod }} CAP {{ tab_cap_cod }}</strong> <br>
        </div>
        <div class="column1">
            <button 
                mat-button 
                class="btn-doc"
                routerLinkActive="list-item-active" 
                routerLink="/default/documentation"
            >
                <h4>Documentazione</h4>
            </button>
        </div>
    </div>
    <div class="row1">
        <div class="column1">
            <div #el class=" view3d-wrapper" style="width:100%; height:500px;">
                <canvas class="my-canvas" style="width:100%; height:500px"></canvas>
            </div>
        </div>
        <div class="column1">
            <div #map style="width:100%;height:500px"></div>
        </div>
    </div>
    <!--<div class="row2">
        <div class="column2" *ngFor="let image of images">
            <div class="box box1">
                <img class="zoom" [src]="image.link" [alt]="image.picture_key" (click)="fullscreen(image);">
            </div>
        </div>
    </div>-->
    <div class="row2">
        <div class="column2" *ngFor="let image of images">
            <div class="box box1">
                <img [src]="getImageUrl(image)" [alt]="image.picture_key" (click)="fullscrin(image)">
            </div>
        </div>
        <div 
            class="modal-content" 
            *ngIf="selectedImage"
            style="
                /*position: fixed;*/
                /*top: 0px;*/
                /*left: 0px;*/
                z-index: 9999;
                box-shadow: 0 0 20px #0003, 0 5px 5px #0000003d;
                border-radius: 8px;
                padding: 10px;
                /*height: 90%;*/
                /*width: 100%;*/
                /*display: flex;*/
                /*align-content: center;*/
                /*justify-content: center;*/
                /*flex-direction: row;*/
                /*align-items: center;*/
                /*flex-wrap: wrap;*/
            "
        >
            <mat-icon class="close" (click)="closeFullscrin()">close</mat-icon>
            <!-- IMMAGINE SOTTO CARD -->
            <img 
                (mousemove)="zoom($event, selectedImage)"
                (mouseleave)="resetZoom()"
                class="fullscreen-image zoom" 
                [src]="selectedImage.link" 
                [alt]="selectedImage.picture_key"
            >
            <div class="lens" *ngIf="zooming" [ngStyle]="lensStyle"></div>
            <!-- IMMAGINE FULLVIEW DENTRO MODAL -->
            <!--<img 
                class="fullscreen-image" 
                style="display: block; max-height: 100%"
                [src]="selectedImage.link" 
                [alt]="selectedImage.picture_key"
            >-->
        </div>
    </div>
    <!-- Modale per fullscreen -->
    <!--<div 
        class="fullscreen-modal" 
        *ngIf="selectedImage"
        style="    
            position: fixed;
            top: 0;
            left: 15%;
            width: 70%;
            height: auto;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            background: #fff;
        "
    >
        <div class="modal-content nobord">
            <mat-icon class="close" (click)="closeFullscrin()">close</mat-icon>
            <img class="fullscreen-image" [src]="selectedImage.link" [alt]="selectedImage.picture_key">
        </div>
    </div>-->      
    <div style="display:flex">
        <div 
            class="column1" 
            style="
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: flex-start;
                justify-content: center;
                align-items: flex-start;
                flex: 1;
                border-right: 1px solid grey;
            "
        >
            <h5>Responsabile: <strong style="font-size:20px; color:#e2c231">{{ responsabile }}</strong></h5>
            <h5>Installatore: <strong style="font-size:20px; color:#e2c231">{{ installatore }}</strong></h5>
            <h5>Cliente: <strong style="font-size:20px; color:#e2c231">{{ cn_cliente }}</strong></h5>
        </div>
        <div 
            class="column1" 
            style="
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: flex-start;
                justify-content: center;
                align-items: flex-start;
                flex: 2;
                padding-left: 20px;
            "
        >
            <span (click)="togglePassword()" style="cursor:pointer">
                Mostra password <mat-icon>visibility</mat-icon>
            </span>
            <h5 *ngFor="let item of modems">
                SSID : {{ item.ssid }} / Password : 
                <input 
                    class="pw" 
                    type="{{passwordType}}" 
                    value="{{item.password}}"
                    name="password" 
                    readonly="readonly"
                >
            </h5>
        </div>
        <br>
    </div>
    <h4 *ngIf="!dataL" style="text-align:center">Attualmente non ci sono dispositivi installati</h4>
    <div *ngIf="dataL">
        <mat-card-content>
            <section class="example-container mat-elevation-z8" tabindex="0">
                <div class="divTable">
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="ds_progressivo">
                            <th mat-header-cell *matHeaderCellDef>PROGRESSIVO</th>
                            <td mat-cell *matCellDef="let row">{{ row.ds_progressivo }}</td>
                        </ng-container>
                        <ng-container matColumnDef="ds_attivo">
                            <th mat-header-cell *matHeaderCellDef>STATO</th>
                            <td mat-cell *matCellDef="let row">{{( row.ds_attivo =="S") ? "Attivo" : "Non Attivo" }}</td>
                        </ng-container>
                        <ng-container matColumnDef="ds_seriale">
                            <th mat-header-cell *matHeaderCellDef>ID</th>
                            <td mat-cell *matCellDef="let row">{{ row.ds_seriale }}</td>
                        </ng-container>
                        <ng-container matColumnDef="ds_data_installazione">
                            <th mat-header-cell *matHeaderCellDef>DATA INSTALLAZIONE</th>
                            <td mat-cell *matCellDef="let row">{{ row.ds_data_installazione | date:'dd/MM/yyyy '}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="dataRevisione">
                            <th mat-header-cell *matHeaderCellDef>DATA REVISIONE</th>
                            <td mat-cell *matCellDef="let row; let i = index" [ngClass]="{'scaduto': isScaduto(row.ds_data_installazione)}">
                                <ng-container *ngIf="!row.isEditable; else editModeRevisione">
                                    <span>{{ calcolaDataRevisione(row.ds_data_installazione) | date:'dd/MM/yyyy' }}</span>
                                    <div 
                                        class="progress-bar-container"
                                        matTooltip="{{calcolaGiorniMancanti(row.ds_data_installazione)}} giorni rimanenti"
                                    >
                                        <div 
                                            class="progress-bar" 
                                            [style.width.%]="calcolaProgresso(row.ds_data_installazione)" 
                                            [ngClass]="{'progress-bar-green': isMonthComplete(row.ds_data_installazione) && !isScaduto(row.ds_data_installazione), 'progress-bar-red': isScaduto(row.ds_data_installazione)}"
                                        ></div>
                                    </div>
                                </ng-container>
                                <ng-template #editModeRevisione>
                                    <input *ngIf="row.isEditable" type="text" value="...">
                                </ng-template>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="warning">
                            <th mat-header-cell *matHeaderCellDef style="width: 35%">WARNING</th>
                            <td mat-cell *matCellDef="let row" style="width: 35%">
                                <img *ngFor="let item of row.ds_list_warnings" src="{{item.warning_ico_link}}" alt="item.warning_ico_link"
                                    width="38px" style="padding-right:5px">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="dati">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>GRAFICI</th>
                            <td mat-cell *matCellDef="let row"><img src="/assets/graph.png" alt="grafico"
                                width="20" (click)="openDialog(row.dispositivi_key)">
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="columns;sticky: true"></tr>
                        <tr (click)="saveData(row)" mat-row *matRowDef="let row; columns: columns;"></tr>
                    </table>
                </div>
            </section>
        </mat-card-content>
    </div>
</mat-card>