import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddTechComponent } from './add-docu/add-tech.component';
import { AccountsService } from '../../service/accounts.service';
import { WorksiteService } from '../../service/worksite.service';
import { LoggerService } from '../../service/logger.service';
import { ErrorService } from '../../service/error.service';
import { SwalService } from '../../service/swal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-worksite-technicians',
  templateUrl: './worksite-technicians.component.html',
  styleUrls: ['../../../theme/tab.scss']
})

export class WorksiteTechniciansComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator; 

  showTable = true;
  isAdmin = false;
  dataSource!: MatTableDataSource<any>;
  tecnici: any
  idWorksite = JSON.parse(localStorage.getItem('selected worksite') || '[]').cantieri_key;
  columns: string[] = [
    '#',
    'ac_nome', 
    'ac_cognome', 
    'ac_email', 
    'ac_cellulare', 
    'ac_telefono', 
    'ac_societa',
    'delete'
  ];  

  constructor(
    private sWorksite: WorksiteService, 
    private saccounts: AccountsService,
    private dialogRef: MatDialog,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService
  ) { }

  ngOnDestroy(): void {
    this.logger.action('Component destroyed!'); 
  }

  ngOnInit(): void {
    if (localStorage.getItem('ac_tipo_utente_cod') == '1') {
      this.isAdmin = true;
    }
    this.sWorksite.getWorksite(this.idWorksite).subscribe(res => {
      if (res != null) {
        if (res.data.accounts.length.toString() === "0") {
          this.showTable = false
        } 
        else {          
          this.tecnici = res.data.accounts;
          this.dataSource = new MatTableDataSource(this.tecnici);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      }
    },
    (error) => {
        this.error.handleError(error)
    })
  }

  openDialog(id: any) {
    this.dialogRef.open(AddTechComponent, {
      data:{
        account_key: id
      }
    });
  }

  deleteTec(key: any) {
    let data = {
      cantieri_key: this.idWorksite,
      account_key: key,
      rel_installatore: ""
    }
    this.swal.doc_warning('Questo tecnico non sarà più associato al cantiere!').then((result) => {
      if (result.value) {
        this.saccounts.delete_worksite_account(data).subscribe(res => {
          if (res != null) {
            this.logger.debug(res)
            this.ngOnInit()
            this.swal.user_delete()
          }
        },
        (error) => {
            this.error.handleError(error)
            this.swal.user_err()
        })
      } 
      else if (result.dismiss === Swal.DismissReason.cancel) {
        this.swal.user_safe()
        return
      }
    })
  }

}
