import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { LicordInWarning } from '../models/licordInWarning.model';
import { Licord } from './../models/licord.model';
import { ErrorService } from './error.service';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class LicordService {

  qapiUrl = "https://licord2024.connecta.ovh/api/services/"
  apiUrl = `${environment.apiUrl}api/services/`

  
  constructor(
    private http: HttpClient,
    private error: ErrorService,
    private logger: LoggerService
  ) { }


  getTotDevices(string?: string){
    if(string != null) {
      let headers = new HttpHeaders().set('Search', string)
      return this.http.get<Licord>(this.apiUrl + 'get_devices', {'headers': headers})
      .pipe(
        tap(response => {
          this.logger.debug('Devices successfully loaded!')
        }),
        catchError(this.error.handleError)
      )
    }
    return this.http.get<Licord>(this.apiUrl + 'get_devices')
    .pipe(
      tap(response => {
        this.logger.debug('Devices successfully loaded!')
      }),
      catchError(this.error.handleError)
    )
  }

  getDevices(worksite: number){
    return this.http.get<Licord>(this.apiUrl + 'get_devices/' + worksite)
    .pipe(
      tap(response => {
        this.logger.debug('Devices successfully loaded!')
      }),
      catchError(this.error.handleError)
    )
  }

  getDevice(deviceKey: any) {
    return this.http.get<Licord>(this.apiUrl + 'get_device/' + deviceKey)
    .pipe(
      tap(response => {
        this.logger.debug('Device successfully loaded!')
      }),
      catchError(this.error.handleError)
    )
  }

  getWarningDevices(worksite: number) {
    return this.http.get<LicordInWarning>(this.apiUrl + 'get_warning_devices/' + worksite.toString())
    .pipe(
      tap(response => {
        this.logger.debug('Waring devices successfully loaded!')
      }),
      catchError(this.error.handleError)
    )
  }

  getAllWarningDevices(string?: string) {
    if(string != null) {
      let headers = new HttpHeaders().set('Search',string)
      return this.http.get<LicordInWarning>(this.apiUrl + 'get_warning_devices', {'headers': headers})
      .pipe(
        tap(response => {
          this.logger.debug('All warning devices successfully loaded!')
        }),
        catchError(this.error.handleError)
      )
    }
    return this.http.get<LicordInWarning>(this.apiUrl + 'get_warning_devices')
    .pipe(
      tap(response => {
        this.logger.debug('All warning devices successfully loaded!')
      }),
      catchError(this.error.handleError)
    )
  }

  saveDevice(data: any) {
    return this.http.post<Licord>(this.apiUrl + 'save_device', data)
    .pipe(
      tap(response => {
        this.logger.debug('Device successfully saved!')
      }),
      catchError(this.error.handleError)
      )
  }

  deleteDevice(device_key: any) {
    return this.http.post(this.apiUrl + 'del_device', device_key)
    .pipe(
      tap(response => {
        this.logger.debug('Device successfully deleted!')
      }),
      catchError(this.error.handleError)
    )
  }

  closeWarning(warning_key: any) {
    return this.http.post(this.apiUrl + 'close_warning', warning_key)
    .pipe(
      tap(response => {
        this.logger.debug('Warning device successfully closed!')
      }),
      catchError(this.error.handleError)
    )
  }

  
  
}
