<mat-card>
    <mat-card-title>
        <h2 style="text-align:center">Tecnici del cantiere</h2>
        <mat-icon 
            (click)="openDialog(tech)"
            routerLinkActive="list-item-active" 
            class="btn-add"
        >
        add_circle</mat-icon>
    </mat-card-title>

    <ng-template id="tech" #tech></ng-template>    

    <h3 [hidden]="showTable==true">Attualmente non ci sono tecnici associati al cantiere</h3>

    <mat-card-content [hidden]="showTable==false">
        <section class="example-container mat-elevation-z8" tabindex="0">
            <div class="divTable">
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="#">
                    <th mat-header-cell *matHeaderCellDef>#</th>
                    <td mat-cell *matCellDef="let index = index">{{ index + 1 }}</td>
                </ng-container>

                <ng-container matColumnDef="ac_nome">
                    <th mat-header-cell *matHeaderCellDef>NOME</th>
                    <td mat-cell *matCellDef="let row">{{ row.ac_nome }}</td>
                </ng-container>              

                <ng-container matColumnDef="ac_cognome">
                    <th mat-header-cell *matHeaderCellDef>COGNOME</th>
                    <td mat-cell *matCellDef="let row">{{ row.ac_cognome }}</td>
                </ng-container>   

                <ng-container matColumnDef="ac_email">
                    <th mat-header-cell *matHeaderCellDef>EMAIL</th>
                    <td mat-cell *matCellDef="let row">{{ row.ac_email }}</td>
                </ng-container>              
                
                <ng-container matColumnDef="ac_cellulare">
                    <th mat-header-cell *matHeaderCellDef >CELLULARE</th>
                    <td mat-cell *matCellDef="let row">{{ row.ac_cellulare }}</td>
                </ng-container>

                <ng-container matColumnDef="ac_telefono">
                    <th mat-header-cell *matHeaderCellDef >TELEFONO</th>
                    <td mat-cell *matCellDef="let row">{{ row.ac_telefono }}</td>
                </ng-container>

                <ng-container matColumnDef="ac_societa">
                    <th mat-header-cell *matHeaderCellDef >SOCIETA'</th>
                    <td mat-cell *matCellDef="let row">{{ row.ac_societa }}</td>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef style="width: 5px">ELIMINA</th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon *ngIf="isAdmin" (click)="deleteTec(row.account_key)" style="color: red">delete</mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;"></tr>
            </table>

            <mat-paginator [length]="tecnici?.length" [pageSize]="5" [pageSizeOptions]="[1, 5, 10, 25, 100]"
                aria-label="Select page">
            </mat-paginator>

        </div>
        </section>
    </mat-card-content>

</mat-card>

