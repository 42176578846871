<h1>Lista dei cantieri</h1>

<div class="divB">
    <button 
        mat-button 
        class="btn-work"
        disabled="true"
    >
        <h4>Cantieri: &nbsp; {{ activeWorksite }}</h4> 
    </button>
    <button 
        mat-button 
        class="btn-lic"
        routerLinkActive="list-item-active" 
        routerLink="/default/devices"
    >
        <h4>Licord: &nbsp; {{ activeLicord }}</h4>
    </button>
    <button 
        mat-button 
        class="btn-warn"
        routerLinkActive="list-item-active" 
        routerLink="/default/homepage"
    >
        <h4>Warning: &nbsp; {{ warningtot }}</h4>
    </button>
</div>

<div 
    id="mappa" 
    style="
        margin-top: 10px; 
        border: 1px solid rgb(162, 162, 162); 
        border-radius: 5px; 
        margin-bottom: 1em;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);    
    "
>
    <div #map style="width: 100%; height: 450px"></div>
</div>

<div style="display: flex; flex-direction: column">
    <h4 *ngIf="dataL==false &&  filter=='0'" style="text-align: center">Attualmente non ci sono cantieri associati</h4>
    <div *ngIf="dataL==false &&  filter=='1'" style="display: flex; flex-direction: column; justify-content: center"> 
        <h4 style="text-align: center">Nessuna corrispondenza trovata</h4>
        <button mat-raised-button style="text-align: center; margin: 20px; font-size: 20px" (click)="filtra(null)">Nuova ricerca</button> 
    </div>  
</div>

<div [hidden]="dataL==false">
    <div style="float: right ;">
        <mat-icon style="margin: 0px; cursor: pointer" *ngIf="asTable" class="item" (click)="view()">view_module</mat-icon>
        <mat-icon style="margin: 0px; cursor: pointer" *ngIf="asList" class="item" (click)="view()">view_list</mat-icon>
    </div>
    <mat-form-field class="example-form-field" style="width: 100%">
        <button *ngIf="value" matPrefix mat-icon-button aria-label="Clear">
            <img src="/assets/x.png" alt="" width="10" (click)="filtra(null)">
        </button>
        <mat-label>Filtro</mat-label>
        <form 
            (ngSubmit)="filtra(value)"
            style="
                padding: 10px;
                border-radius: 4px;
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)
            "
        >
            <input matInput type="text" [(ngModel)]="value" [ngModelOptions]="{standalone: true}">
        </form>
          <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear">
            <img src="/assets/right-arrow.png" alt="" width="20" (click)="filtra(value)">
        </button>
    </mat-form-field>

    <div class="container" *ngFor="let cantiere of worksites">
        <div *ngIf="asList" class="card ">
            <div class="row g-0">
                <div class="col-md-4">
                    <img class="image" src="{{ cantiere.cn_foto == null ? defaultFoto : cantiere.cn_foto.link }}" alt="Prima_foto_caricata">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <mat-card-content (click)="saveWorksiteData(cantiere)">
                            <h2 class="card-title">
                                {{ cantiere.tab_tipo_cantiere_desc }} <br> 
                                {{ cantiere.tab_comune_desc }}
                                {{ cantiere.cn_indirizzo }}, {{ cantiere.tab_provincia_cod }}
                                {{ cantiere.tab_cap_cod }}
                            </h2>
                            <br>
                            <ul>
                                <li><b>Licord installati: </b>{{ cantiere.cn_numero_licord }}</li>
                                <li><b>Latitudine: </b>{{ cantiere.cn_latitude }}</li>
                                <li><b>Longitudine: </b>{{ cantiere.cn_longitude }}</li>
                                <li><b>Cliente: </b>{{ cantiere.cn_cliente }}</li>
                                <li> 
                                    <a href="#" routerLinkActive="list-item-active" routerLink="/default/documentation"> 
                                        <img src="/assets/pdf.svg" href="#" width="5%">
                                    </a> : Documentazione scaricabile
                                </li>
                            </ul>
                        </mat-card-content>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="example-container mat-elevation-z8" tabindex="0">
        <div class="divTable" [hidden]="asTable ==false">
            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>TIPO</th>
                    <td mat-cell *matCellDef="let row">{{row.tab_tipo_cantiere_desc}}</td>
                </ng-container>

                <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>CITTÀ</th>
                    <td mat-cell *matCellDef="let row">{{ row.tab_comune_desc }}</td>
                </ng-container>

                <ng-container matColumnDef="street">
                    <th mat-header-cell *matHeaderCellDef>VIA</th>
                    <td mat-cell *matCellDef="let row">{{ row.cn_indirizzo }}</td>
                </ng-container>

                <ng-container matColumnDef="prov">
                    <th mat-header-cell *matHeaderCellDef>PROVINCIA</th>
                    <td mat-cell *matCellDef="let row">{{ row.tab_provincia_cod }}</td>
                </ng-container>

                <ng-container matColumnDef="cap">
                    <th mat-header-cell *matHeaderCellDef>CAP</th>
                    <td mat-cell *matCellDef="let row">{{ row.tab_cap_cod }}</td>
                </ng-container>

                <ng-container matColumnDef="licord">
                    <th mat-header-cell *matHeaderCellDef>N°LICORD</th>
                    <td mat-cell *matCellDef="let row">{{ row.cn_numero_licord }}</td>
                </ng-container>

                <ng-container matColumnDef="client">
                    <th mat-header-cell *matHeaderCellDef>CLIENTE</th>
                    <td mat-cell *matCellDef="let row">{{ row.cn_cliente }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;" (click)="saveWorksiteData(row)"></tr>
            </table>

            <mat-paginator [length]="worksites?.length" [pageSize]="25" [pageSizeOptions]="[1, 5, 10, 25, 100]"
                aria-label="Select page">
            </mat-paginator>

        </div>
    </section>

</div>