<div class="containerInserimentoLicord">
    <form [formGroup]="form" id="contact" style="margin-bottom: 8px; width: 100%">
        <h3 style="text-align:center">Inserimento Immagine</h3> 
        <h4 style="text-align:center">Aggiungi una nuova immagine</h4>      
        <div class="containerFormLicord">
            <div 
                style="
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center
                "
            >
                <p style="margin-right:10px">
                    <mat-form-field appearance="outline" class="byclass">
                        <mat-label>Titolo immagine</mat-label>
                        <input [(ngModel)]="titleImg" matInput>
                    </mat-form-field>
                </p>
                <fieldset
                    style="
                        margin-top: 20px;
                        margin-bottom: -40px
                    "
                >
                    <button 
                        class="btn-add" 
                        data-submit="...Sending" 
                        (click)="imgInput.click()"
                        id="contact-submit"
                        name="submit" 
                        type="submit" 
                    >
                        <mat-icon 
                            style="
                                margin-right: 25px; 
                                transform: scale(2.3);
                                position: relative;
                                top: 2px
                            "
                        >add_photo_alternate</mat-icon>
                        <span 
                            style="
                                position: relative; 
                                top: -3px
                            "
                        >AGGIUNGI</span>
                        <input 
                            [(ngModel)]="fileImg"
                            type="file" 
                            #imgInput
                            (change)="onFileInput(imgInput.files, titleImg!,1)"
                            style="display: none"
                            accept=".jpg, .png" />
                    </button>
                    <button 
                        data-submit="...Sending" 
                        (click)="close()"
                        class="btn-annulla"
                        id="contact-submitt"
                        type="submit" 
                        name="submit" 
                    >
                        <mat-icon 
                            style="
                                margin-right: 25px; 
                                transform: scale(2.3);
                                position: relative;
                                top: 2px
                            "
                        >clear</mat-icon>
                        <span 
                            style="
                                position: relative; 
                                top: -3px
                            "
                        >ANNULLA</span>
                    </button>
                </fieldset>
            </div><br>
        </div>
    </form>
</div>