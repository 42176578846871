import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})

export class ConfigurationService {

  sideBarOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  urlServer = 'https://app.licord.it/api/services';
  passwordPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$_@$!%*?&])[A-Za-z\\d$_@$!%*?&]{8,}'

  constructor(private logger: LoggerService) { }

  // Toggle the sidebar's current state.
  toggleSideBar() {
    const currentValue = this.sideBarOpen.getValue();
    this.sideBarOpen.next(!currentValue);
    this.logger.info('Toggle:', !currentValue);
  }

  // Return an observable of the current sidebar state.
  getToggleSideBarValue(): Observable<boolean> {
    return this.sideBarOpen.asObservable();
  }

  deleteStorage() {
    localStorage.clear();
  }

}
