import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../service/auth.service';
import { LoggerService } from '../../service/logger.service';
import { SwalService } from '../../service/swal.service';
import { MatAccordion } from '@angular/material/expansion';
import { PasswordChange, ChangePasswordInApp, PasswordPolicy } from '../../models/password.model';
import { PasswordService } from '../../service/password.service';
import { ErrorService } from '../../service/error.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrl: './password.component.scss'
})

export class PasswordComponent {

  @ViewChildren(MatAccordion) accordions!: QueryList<MatAccordion>;

  form = this.fb.group({
    oldPassword:  ['', { validators: [Validators.required] }],
    newPassword:  ['', { validators: [Validators.required] }],
    repPassword:  ['', { validators: [Validators.required] }]
  })

  token: string = '';
  username: string = '';
  successMessage: string = '';
  errorMessage: string = '';

  oldPassword: string = '';
  newPassword: string = '';
  repPassword: string = '';

  password: string = '';
  isValid: boolean = true;
  isTokenVerified: boolean = false;
  isModalOpen: boolean = true;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialogRef<PasswordComponent>,
    private route: ActivatedRoute,
    private router: Router,
    private authServ: AuthService,
    private passwordService: PasswordService,
    private logger: LoggerService,
    private error: ErrorService,
    private swal: SwalService
  ) { }

  ngOnInit(): void {
    // Otteniamo il token dai parametri dell'URL
    this.route.queryParams.subscribe(params => {
      this.token = params['t'] || '';
      if (this.token) {
        this.checkPasswordReset(this.token);
      }
    });
  }

  // Funzione per cambiare la password
  changePassword(): void {
    if (this.newPassword !== this.repPassword) {
      this.swal.pwd_mismatch();
      this.logger.error('The new password and the confirmation one do not match');
      return;
    }
    let credential = this.form.value
    const payload: PasswordChange = {
      old_password: this.oldPassword,
      new_password: this.newPassword,
      rep_password: this.repPassword
    };
    //this.passwordService.changePassword(payload).subscribe(
    this.passwordService.changePassword(credential).subscribe(
      response => {
        this.swal.pwd_chg();
        this.logger.debug('Password changed successfully: ', response);
      },
      error => {
        this.swal.pwd_err();
        this.logger.error('Error changing password: ', error);
      }
    );
  }

  checkPasswordReset(token: string): void {
    this.passwordService.checkPasswordReset(token).subscribe(
      response => {
        if (response === 'SUCCESS') {
          this.swal.token();
          this.isTokenVerified = true;
          this.logger.debug('Token is valid');
          // Fai qualcosa se il token è valido, ad esempio mostrare un form per reimpostare la password
        } 
        else {
          this.swal.token_exp();
          this.isTokenVerified = false;
          this.logger.error('Invalid or expired token');
        }
      },
      error => {
        this.swal.token_err();
        this.isTokenVerified = false;
        this.logger.error('Error checking password reset token:', error);
      }
    );
  }

  resetPassword(): void {
    if (this.newPassword !== this.repPassword) {
      this.swal.pwd_mismatch();
      this.errorMessage = 'Le password non coincidono';
      return;
    }
    const passwordChange: PasswordChange = {
      old_password: '', 
      new_password: this.newPassword,
      rep_password: this.repPassword
    };
    this.passwordService.resetPassword(this.token, passwordChange).subscribe(
      response => {
        if (response === 'SUCCESS') {
          this.swal.pwd_reset();
          this.logger.debug('Password reset successful');
        } 
        else if (response === 'PASSWORD_MISMATCH') {
          this.swal.pwd_mismatch();
          this.errorMessage = 'Le nuove password non coincidono';
        } 
        else if (response === 'PASSWORD_POLICY_VIOLATION') {
          this.swal.pwd_violation();
          this.errorMessage = 'La nuova password non soddisfa i criteri di sicurezza';
        } 
        else {
          this.swal.pwd_reseterr();
          this.errorMessage = 'Errore nella richiesta di reset della password';
        }
      },
      error => {
        this.swal. pwd_reseterr();
        this.logger.error('Errore durante il reset della password:', error);
        this.errorMessage = 'Si è verificato un errore nel reset della password';
      }
    );
  }

  requestPasswordReset(): void {
    this.passwordService.requestPasswordReset(this.username).subscribe(
      response => {
        if (response === 'SUCCESS') {
          this.swal.pwd_link();
          this.successMessage = 'Se esiste un account associato a questa email, riceverai un link per il reset della password.';
        } 
        else {
          this.swal. pwd_reseterr();
          this.errorMessage = 'Si è verificato un errore durante la richiesta di reset della password.';
        }
      },
      error => {
        this.swal.pwd_reseterr();
        console.error('Errore durante la richiesta di reset della password:', error);
        this.errorMessage = 'Si è verificato un errore durante la richiesta di reset della password.';
      }
    );
  }

  checkPassword() {
    this.isValid = this.passwordService.checkPassword(this.password, PasswordPolicy.Min8AlfaUpper1Number1Special1);
    if (this.isValid) {
      this.logger.debug('La password è valida.');
    } 
    else {
      this.logger.error('La password non soddisfa i requisiti.');
    }
  }

  close() {
    this.dialog.close()
  }

}
